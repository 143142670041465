import { View, Image, Pressable, ScrollView } from "react-native";
import { Font } from "../../components/font";
import { Input } from "../../components/input";
import { useContext, useState } from "react";
import { Card } from "../../components/card";
import RemixIcon from "react-native-remix-icon";
import {
  DEV_API,
  axiosDelete,
  axiosGet,
  axiosPatch,
  axiosPost,
} from "../../services/api";
import { AuthContext } from "../../context/authContext";
import { getFullDate, getFullDateTime } from "../../../utils/date-time-util";
import colors from "../../../colors";
import Toast from "react-native-toast-message";
import { useEffect } from "react";
import { Unautorized } from "./unautorized";
import { Widget } from "../../components/widget";
import { Footer } from "../../components/footer";
import { ActivityIndicator } from "react-native";
import { AntDesign } from "@expo/vector-icons";
import { TouchableOpacity } from "react-native";
import { UserContext } from "../../context/userContext";

export function Comments({ navigation, route }) {
  const { user, userToken, isAuthLoading } = useContext(AuthContext);
  const { userData } = useContext(UserContext);
  const [comment, setComment] = useState(null);
  const [replyToAComment, setReplyToAComment] = useState("");
  const [comments, setComments] = useState([]);
  const [isInOperation, setIsInOperation] = useState(false);
  const [commentsPagination, setCommentsPagination] = useState({
    to: null,
    total: null,
  });

  const [userComments, setUserComments] = useState([]);
  const [toggleUserComments, setToggleUserComments] = useState(false);
  const [isCommentsLoading, setIsCommentsLoading] = useState(false);
  const [isDeleteCommentLoading, setIsDeleteCommentLoading] = useState({
    id: null,
    isLoading: false,
  });
  // const [showCommentId, setShowUserComment] = useState({
  //   id: null,
  //   showReplies: false,
  //   showAddComment: false,
  // });
  const [showUserComment, setShowUserComment] = useState({
    id: null,
    showEdit: false,
    editValue: "",
    showAddComment: false,
    isEditInProgress: false,
    replyId: "",
    showReply: false,
    showReplyInput: false,
    showEditReply: false,
    replyEditValue: "",
    isDeleteReplyInProgress: false,
    isReplyInProgress: false,
  });
  const [showRepliesToACommentId, setShowRepliesToACommentId] = useState(null);
  // const commentId = 101;

  const showToast = (type, line1, line2) => {
    Toast.show({
      type: type,
      text1: line1,
      text2: line2,
    });
  };
  const commentsPerPage = 10;

  const getCommentsForAPost = async () => {
    setIsCommentsLoading(true);
    await axiosGet({
      Url: `/users/${route?.params?.vendor_id}/wall/posts/${route?.params?.post_id}/comments`,
      token: userToken,
      params: {
        offset: commentsPagination.to === null ? 0 : commentsPagination.to + 1,
        limit:
          commentsPagination.total === null
            ? commentsPerPage
            : commentsPagination.to + commentsPerPage <=
              commentsPagination.total
            ? commentsPerPage
            : commentsPagination.total,
      },
    })
      .then((data) => {
        setCommentsPagination({
          to: comments?.length + data.data.comments.length,
          total: data.data.total_count,
        });
        setComments((e) => [...e, ...data.data.comments].reverse());
        setUserComments([...data.data.user_comments].reverse());
        setToggleUserComments(
          data.data?.user_comments?.length > 5 ? true : false
        );
      })
      .catch((err) => {
        console.log("vendor, getCommentsForAPost error: ", err);
      })
      .finally(() => {
        setIsCommentsLoading(false);
      });
  };
  const onDeleteComment = async (comment_id) => {
    setIsDeleteCommentLoading({ id: comment_id, isLoading: true });
    await axiosDelete({
      Url: `/users/${route?.params?.vendor_id}/wall/posts/${route?.params?.post_id}/comments/${comment_id}`,
      token: userToken,
    })
      .then((data) => {
        const array = userComments.filter((e, i) => e.id !== comment_id);
        setUserComments(array);
        const arrayForComment = comments.filter((e, i) => e.id !== comment_id);
        setComments(arrayForComment);
        showToast("success", "Success", "Successful.");
      })
      .catch((err) => {
        showToast("error", "Error", "Something went wrong.");
        console.log("comments, onDeleteComment error: ", err);
      })
      .finally(() => {
        setIsDeleteCommentLoading({ id: null, isLoading: false });
      });
  };
  const onDeleteReply = async (comment_id, reply_id) => {
    setShowUserComment((prev) => ({ ...prev, isDeleteReplyInProgress: true }));
    await axiosDelete({
      Url: `/users/${route?.params?.vendor_id}/wall/posts/${route?.params?.post_id}/comments/${comment_id}/replies/${reply_id}`,
      token: userToken,
    })
      .then((data) => {
        const updatedComments = comments.map((comment) =>
          comment.id === comment_id
            ? {
                ...comment,
                replies: comment.replies.filter(
                  (reply) => reply.id !== reply_id
                ), // Remove reply
              }
            : comment
        );

        setComments(updatedComments);
        // showToast("success", "Success", "Successful.");
      })
      .catch((err) => {
        showToast("error", "Error", "Something went wrong.");
        console.log("comments, onDeleteComment error: ", err);
      })
      .finally(() => {
        setShowUserComment((prev) => ({
          ...prev,
          isDeleteReplyInProgress: false,
        }));
      });
  };
  const onEditComment = async (comment_id) => {
    // setIsDeleteCommentLoading({ id: comment_id, isLoading: true });
    setShowUserComment((prev) => ({
      ...prev,
      id: comment_id,
      isEditInProgress: true,
    }));
    await axiosPatch({
      Url: `/users/${route?.params?.vendor_id}/wall/posts/${route?.params?.post_id}/comments/${comment_id}`,
      token: userToken,
      data: { message: showUserComment.editValue },
    })
      .then((data) => {
        // if (data.status == 200) {
        const updatedUserComments = userComments.map((comment) =>
          comment.id === comment_id
            ? { ...comment, message: showUserComment.editValue }
            : comment
        );
        setUserComments(updatedUserComments);

        // Update comments in the same way
        const updatedComments = comments.map((comment) =>
          comment.id === comment_id
            ? { ...comment, message: showUserComment.editValue }
            : comment
        );
        setComments(updatedComments);
        // setShowUserComment((prev)=>({...prev,showEdit:false}))
        // }
        // showToast("success", "Success", "Successful.");
      })
      .catch((err) => {
        showToast("error","Error",err.response.message);
        console.log("comments, onDeleteComment error: ", err);
      })
      .finally(() => {
        // .0((prev)=>({...prev,showEdit:false}))
        setShowUserComment((prev) => ({
          ...prev,
          id: comment_id,
          isEditInProgress: false,
          showEdit: false,
        }));
        // setIsDeleteCommentLoading({ id: null, isLoading: false });
      });
  };
  const onEditReply = async (comment_id, reply_id) => {
    setShowUserComment((prev) => ({
      ...prev,
      replyId: reply_id,
      isReplyInProgress: true, // Set reply edit progress
    }));

    await axiosPatch({
      Url: `/users/${route?.params?.vendor_id}/wall/posts/${route?.params?.post_id}/comments/${comment_id}/replies/${reply_id}`,
      token: userToken,
      data: { message: showUserComment.replyEditValue }, // Use correct reply edit value
    })
      .then((data) => {
        // if (data.status === 200) {
        const updatedUserComments = userComments.map((comment) =>
          comment.id === comment_id
            ? {
                ...comment,
                replies: comment.replies.map((reply) =>
                  reply.id === reply_id
                    ? { ...reply, message: showUserComment.replyEditValue } // Update specific reply
                    : reply
                ),
              }
            : comment
        );
        setUserComments(updatedUserComments);

        // Update comments in the same way
        const updatedComments = comments.map((comment) =>
          comment.id === comment_id
            ? {
                ...comment,
                replies: comment.replies.map((reply) =>
                  reply.id === reply_id
                    ? { ...reply, message: showUserComment.replyEditValue }
                    : reply
                ),
              }
            : comment
        );
        setComments(updatedComments);
        // }
      })
      .catch((err) => {
        showToast("error","Error",err.response.message);
        console.error("Error editing reply:", err);
      })
      .finally(() => {
        setShowUserComment((prev) => ({
          ...prev,
          isReplyInProgress: false,
          showEditReply: false,
        }));
      });
  };
  useEffect(() => {
    getCommentsForAPost();
  }, []);

  const onSendComment = async () => {
    if (comment && comment !== "") {
      await axiosPost({
        Url: `/users/${route?.params?.vendor_id}/wall/posts/${route?.params?.post_id}/comments`,
        token: userToken,
        data: { message: comment },
      })
        .then((data) => {
          const array = [...comments];
          const commentData = { ...data.data.comment };
          delete commentData.post;
          array.unshift(commentData);
          setComments(array);
          setUserComments([...userComments, data.data.comment]);
          setComment(null);
          // showToast("success", "Success", "Successful.");
        })
        .catch((err) => {
          showToast("error","Error",err.response.message);
          console.log("comments, onSendCommentHandler error: ", err);
        })
        .finally(() => {});
    }
  };
  const onSendReplyToAComment = async (commentId) => {
    if (replyToAComment && replyToAComment !== "") {
      await axiosPost({
        Url: `/users/${route?.params?.vendor_id}/wall/posts/${route?.params?.post_id}/comments/${commentId}/replies`,
        token: userToken,
        data: { message: replyToAComment },
      })
        .then((data) => {
          const newReply = data.data.reply; // Get the new reply

          // Update comments state
          const updatedComments = comments.map((comment) =>
            comment.id === commentId
              ? {
                  ...comment,
                  replies: comment.replies
                    ? [newReply, ...comment.replies]
                    : [newReply], // Ensure replies array exists
                }
              : comment
          );

          // Update userComments state
          const updatedUserComments = userComments.map((comment) =>
            comment.id === commentId
              ? {
                  ...comment,
                  replies: comment.replies
                    ? [newReply, ...comment.replies]
                    : [newReply],
                }
              : comment
          );

          // Set updated states
          setComments(updatedComments);
          setUserComments(updatedUserComments);

          // Reset UI state
          setShowUserComment((prev) => ({
            ...prev,
            id: "",
            showAddComment: false,
            showReplies: false,
          }));

          console.log(updatedComments, "onSendReplyToAComment");
        })
        .catch((err) => {
          showToast("error","Error",err.response.message);
          console.error("comments, onSendReplyToAComment error:", err);
        });
    }
  };

  const onLikeAComment = async ({ id, type }) => {
    setIsInOperation(true);

    await axiosPost({
      Url: `/users/${route?.params?.vendor_id}/wall/posts/${route?.params?.post_id}/comments/${id}/${type}`,
      token: userToken,
      data: {},
    })
      .then((data) => {
        // if (data.status == 200) {
        const index = comments?.findIndex((e) => e.id === id);
        if (index > -1) {
          let post = [...comments];
          post[index].is_liked = type === "like" ? true : false;
          post[index].likes =
            type === "like" ? post[index].likes + 1 : post[index].likes - 1;
          setComments(post);
          setUserComments(post);
          // showToast("success", "Success", "Success.");
        }
        // }
      })
      .catch((err) => {
        showToast("error","Error",err.response.message);
        console.log("comments, onLikeAComment error: ", err);
      })
      .finally(() => {
        setIsInOperation(false);
      });
  };
  const onLikeAReply = async ({ id, replyId, type }) => {
    setIsInOperation(true);

    await axiosPost({
      Url: `/users/${route?.params?.vendor_id}/wall/posts/${route?.params?.post_id}/comments/${id}/replies/${replyId}/${type}`,
      token: userToken,
      data: {},
    })
      .then((data) => {
        // if (data.status == 200) {
        const updatedComments = comments.map((comment) =>
          comment.id === id
            ? {
                ...comment,
                replies: comment.replies.map((reply) =>
                  reply.id === replyId
                    ? {
                        ...reply,
                        is_liked: type === "like" ? true : false,
                        likes:
                          type === "like"
                            ? reply.likes + 1
                            : Math.max(reply.likes - 1, 0), // Prevent negative likes
                      }
                    : reply
                ),
              }
            : comment
        );

        setComments(updatedComments);
      })
      .catch((err) => {
        showToast("error","Error",err.response.message)
        console.log("comments, onLikeAComment error: ", err);
      })
      .finally(() => {
        setIsInOperation(false);
      });
  };

  const onLoadMore = () => {
    getCommentsForAPost();
  };

  return user ? (
    <View className=" bg-app-e lg:bg-gray-50 flex-1">
      <View className="rounded-t-2xl lg:rounded-none overflow-hidden flex-1 bg-gray-50">
        <ScrollView
          className="p-4 rounded-t-2xl"
          // refreshControl={
          //   <RefreshControl
          //     refreshing={refreshing}
          //     onRefresh={() => {
          //       getWishlist();
          //     }}
          //   />
          // }
          keyboardShouldPersistTaps="always"
        >
          <View className="flex-row w-full justify-center items-center">
            <View className="flex-col w-full lg:w-11/12 lg:px-4">
              <View className="my-5 hidden lg:flex">
                <Font className="text-xl font-semibold text-app-d">
                  Comments
                </Font>
              </View>
              <View className="w-full flex-col lg:flex-row">
                <View className="w-full lg:w-4/6 ">
                  {
                    // userComments && userComments.length > 0 ?
                    false ? (
                      <Card title={`Your comments`} className=" w-full">
                        <ScrollView
                          className="flex-1 w-full px-3 py-0"
                          keyboardShouldPersistTaps="always"
                        >
                          {(toggleUserComments
                            ? [...userComments.slice(0, 5)]
                            : userComments
                          ).map((e, i) => (
                            <View
                              className="w-full flex-row justify-between items-center bg-app-b rounded-lg py-2"
                              key={`user_comments${i}`}
                            >
                              <View className="flex-row self-stretch justify-start items-start rounded-full mr-3">
                                <Image
                                  // resizeMode="contain"
                                  source={
                                    e.user_profile_image_url &&
                                    e.user_profile_image_url !== ""
                                      ? {
                                          uri: `${DEV_API}/media/${e.user_profile_image_url}`,
                                        }
                                      : require("../../../assets/user1.jpg")
                                  }
                                  className="w-10 h-10 rounded-full"
                                ></Image>
                              </View>
                              <View className="flex-1 flex-col rounded-md bg-app-c2">
                                <View className="w-full flex-row justify-between px-3 pt-1">
                                  <Font className="py-1 font-bold">
                                    {e.user_name}
                                  </Font>
                                  <View className="flex-row gap-1">
                                    <TouchableOpacity
                                      className="px-2 py-1 rounded-md"
                                      onPress={() => {
                                        setShowUserComment((prev) => ({
                                          ...prev,

                                          id:
                                            prev.id === e.id && prev.showEdit
                                              ? e.id
                                              : e.id,
                                          editValue: e?.message,
                                          showEdit:
                                            prev.id === e.id && prev.showEdit
                                              ? false
                                              : true,
                                        }));
                                      }}
                                    >
                                      {/* <Font className="text-xs text-app-e">
                                    Delete comment
                                  </Font> */}
                                      {showUserComment.id === e.id &&
                                      showUserComment.isEditInProgress ? (
                                        <ActivityIndicator
                                          size={"small"}
                                          color={colors.app.e}
                                        ></ActivityIndicator>
                                      ) : (
                                        <AntDesign
                                          name="edit"
                                          size={20}
                                          color={colors.app.e}
                                        />
                                      )}
                                    </TouchableOpacity>
                                    <TouchableOpacity
                                      className="px-2 py-1 bg-app-e3 rounded-md"
                                      onPress={() => {
                                        onDeleteComment(e.id);
                                      }}
                                    >
                                      {/* <Font className="text-xs text-app-e">
                                    Delete comment
                                  </Font> */}
                                      {isDeleteCommentLoading.id === e.id &&
                                      isDeleteCommentLoading.isLoading ? (
                                        <ActivityIndicator
                                          size={"small"}
                                          color={colors.app.e}
                                        ></ActivityIndicator>
                                      ) : (
                                        <AntDesign
                                          name="delete"
                                          size={20}
                                          color={colors.app.e}
                                        />
                                      )}
                                    </TouchableOpacity>
                                  </View>
                                </View>
                                <View className="w-full pt-1 px-3">
                                  <Font className="text-xs text-app-d1">
                                    {getFullDateTime(e.created_at)}
                                  </Font>
                                </View>
                                <View className="w-full p-3">
                                  <Font>{e.message}</Font>
                                </View>
                                <View className="w-full flex-row items-center px-3 py-1 border-b border-app-c">
                                  <View className="w-1/2 flex-row">
                                    <Font className="text-xs pr-2 text-app-d1">
                                      {e?.likes}
                                    </Font>
                                    <Pressable
                                      disabled={isInOperation}
                                      onPress={() =>
                                        onLikeAComment({
                                          id: e.id,
                                          type: e.is_liked ? "unlike" : "like",
                                        })
                                      }
                                      className="flex-row items-center"
                                    >
                                      <RemixIcon
                                        name={
                                          e?.is_liked
                                            ? "ri-thumb-up-fill"
                                            : "ri-thumb-up-line"
                                        }
                                        size={11}
                                        color={
                                          e?.is_liked
                                            ? colors.app.f
                                            : colors.app.d1
                                        }
                                      />
                                    </Pressable>
                                  </View>
                                  <View className="w-1/2 flex-row">
                                    <Font className="text-xs pr-2 text-app-d1">
                                      {e.replies?.length}
                                    </Font>
                                    <TouchableOpacity
                                      onPress={() => {
                                        setShowUserComment((prev) => ({
                                          ...prev,

                                          showAddComment: false,
                                        }));
                                        setShowUserComment((prev) => ({
                                          ...prev,
                                          id: e.id,
                                          showReply:
                                            prev.id === e.id && prev.showReply
                                              ? false
                                              : prev.id === e.id &&
                                                !prev.showReply
                                              ? true
                                              : true,
                                        }));
                                      }}
                                    >
                                      <Font className="text-xs pr-2 text-app-d">
                                        Replies
                                      </Font>
                                    </TouchableOpacity>
                                  </View>
                                </View>
                                <View className="w-full flex-col ">
                                  {showUserComment.id === e.id &&
                                    showUserComment.showEdit && (
                                      <View className="w-full flex-col py-1 px-3 my-3">
                                        <View className="w-full pb-1">
                                          <Font>Edit this comment</Font>
                                        </View>
                                        <View className="sm:w-full flex-col sm:flex-row justify-between">
                                          <View className="w-full pb-3 sm:pb-0 sm:w-3/4 flex-row items-center justify-center rounded-l-md">
                                            <Input
                                              type="text"
                                              value={showUserComment.editValue}
                                              setValue={(ee) => {
                                                setShowUserComment((prev) => ({
                                                  ...prev,
                                                  editValue: ee,
                                                }));
                                              }}
                                              placeholder="Edit this comment..."
                                            ></Input>
                                            <Pressable
                                              className="ml-2 rounded bg-app-c h-10 px-3 flex justify-center items-center border border-app-c rounded-r-md"
                                              onPress={() =>
                                                onEditComment(e.id)
                                              }
                                            >
                                              <RemixIcon
                                                name="ri-send-plane-2-line"
                                                size={15}
                                              />
                                            </Pressable>
                                          </View>
                                        </View>
                                      </View>
                                    )}
                                  {/* ---------------------- replies to a comment ------------------------ */}
                                  {showUserComment.id === e.id &&
                                    showUserComment.showReply &&
                                    e.replies?.length > 0 &&
                                    e.replies?.reverse()?.map((ee, i) => (
                                      <View className="flex-col w-full">
                                        <View
                                          className="w-full flex-row justify-between items-center p-2 border-b border-app-c"
                                          key={`user_comment_replies${i}`}
                                        >
                                          <View className="flex-row justify-start overflow-hidden rounded-full pb-4 mr-3">
                                            <Image
                                              // resizeMode="contain"
                                              source={
                                                ee?.user_profile_image_url &&
                                                ee?.user_profile_image_url !==
                                                  ""
                                                  ? {
                                                      uri: `${DEV_API}/media/${ee?.user_profile_image_url}`,
                                                    }
                                                  : require("../../../assets/user1.jpg")
                                              }
                                              className="w-10 h-10 rounded-full"
                                            ></Image>
                                          </View>
                                          <View className="w-full flex-1">
                                            <View className="w-full flex-row px-3 pt-1">
                                              <Font className="w-full py-1 font-bold">
                                                {ee.user_name}
                                              </Font>
                                            </View>
                                            <View className="w-full pt-1 px-3">
                                              <Font className="text-xs text-app-d1">
                                                {getFullDateTime(ee.created_at)}
                                              </Font>
                                            </View>
                                            <View className="w-full flex-1">
                                              <View className="w-full flex-row bg-app-c2 p-3 rounded-md">
                                                <Font>{ee.message}</Font>
                                              </View>
                                            </View>
                                          </View>
                                          <View className="flex-row gap-1">
                                            <TouchableOpacity
                                              className="px-2 py-1 rounded-md"
                                              onPress={() => {
                                                setShowUserComment((prev) => ({
                                                  ...prev,

                                                  replyId:
                                                    prev.replyId === ee.id &&
                                                    prev.showEditReply
                                                      ? ee.id
                                                      : ee.id,
                                                  replyEditValue: ee?.message,
                                                  showEditReply:
                                                    prev.replyId === ee.id &&
                                                    prev.showEditReply
                                                      ? false
                                                      : true,
                                                }));
                                              }}
                                            >
                                              {/* <Font className="text-xs text-app-e">
                                    Delete comment
                                  </Font> */}
                                              {showUserComment.replyId ===
                                                ee.id &&
                                              showUserComment.isReplyInProgress ? (
                                                <ActivityIndicator
                                                  size={"small"}
                                                  color={colors.app.e}
                                                ></ActivityIndicator>
                                              ) : (
                                                <AntDesign
                                                  name="edit"
                                                  size={15}
                                                  color={colors.app.e}
                                                />
                                              )}
                                            </TouchableOpacity>
                                            <TouchableOpacity
                                              className="px-1 py-1 bg-app-e3 rounded-md"
                                              disabled={
                                                isDeleteCommentLoading.isLoading
                                              }
                                              onPress={() => {
                                                onDeleteComment(e.id);
                                              }}
                                            >
                                              {/* <Font className="text-xs text-app-e">
                                    Delete comment
                                  </Font> */}
                                              {isDeleteCommentLoading.id ===
                                                e.id &&
                                              isDeleteCommentLoading.isLoading ? (
                                                <ActivityIndicator
                                                  size={"small"}
                                                  color={colors.app.e}
                                                ></ActivityIndicator>
                                              ) : (
                                                <AntDesign
                                                  name="delete"
                                                  size={15}
                                                  color={colors.app.e}
                                                />
                                              )}
                                            </TouchableOpacity>
                                          </View>
                                        </View>
                                        {user?.id == ee?.id &&
                                          showUserComment.replyId === ee.id &&
                                          showUserComment.showEditReply && (
                                            <View className="w-full flex-col py-1 px-3 my-3">
                                              <View className="w-full pb-1">
                                                <Font>Edit this reply</Font>
                                              </View>
                                              <View className="sm:w-full flex-col sm:flex-row justify-between">
                                                <View className="w-full pb-3 sm:pb-0 sm:w-3/4 flex-row items-center justify-center rounded-l-md">
                                                  <Input
                                                    type="text"
                                                    value={
                                                      showUserComment.replyEditValue
                                                    }
                                                    setValue={(ee) => {
                                                      setShowUserComment(
                                                        (prev) => ({
                                                          ...prev,
                                                          replyEditValue: ee,
                                                        })
                                                      );
                                                    }}
                                                    placeholder="Edit this Reply..."
                                                  ></Input>
                                                  <Pressable
                                                    className="bg-app-c h-10 px-3 flex justify-center items-center border border-app-c rounded-r-md"
                                                    onPress={() =>
                                                      onEditReply(e?.id, ee?.id)
                                                    }
                                                  >
                                                    <RemixIcon
                                                      name="ri-send-plane-2-line"
                                                      size={15}
                                                    />
                                                  </Pressable>
                                                </View>
                                              </View>
                                            </View>
                                          )}
                                      </View>
                                    ))}
                                </View>
                              </View>
                            </View>
                          ))}
                        </ScrollView>
                        {userComments.length > 5 && (
                          <View className="w-full flex-row justify-center">
                            <TouchableOpacity
                              onPress={() => setToggleUserComments((e) => !e)}
                              className="bg-app-e w-32 py-2 rounded-lg"
                            >
                              <Font className="text-app-a text-center">
                                {toggleUserComments ? "Show more" : "Show less"}
                              </Font>
                            </TouchableOpacity>
                          </View>
                        )}
                        <View className="w-full flex-col sm:flex-row justify-between items-center px-3 py-2">
                          <View className="flex-row items-center justify-center">
                            <Input
                              type="text"
                              value={comment}
                              setValue={(e) => {
                                setComment(e);
                              }}
                              // label="Email"
                              placeholder="Add comment..."
                              // validation={true}
                              // isValid={(e) => setIsInputValid({ e, type: "email" })}
                            ></Input>
                            <Pressable
                              className="bg-app-c h-10 px-3 flex-row items-center border border-app-c"
                              onPress={onSendComment}
                            >
                              <RemixIcon
                                name="ri-send-plane-2-line"
                                size={15}
                              />
                            </Pressable>
                          </View>
                        </View>
                      </Card>
                    ) : (
                      <></>
                    )
                  }
                  <Card title={`Add comment`} className=" w-full ">
                    <View className="w-full flex-col sm:flex-row justify-between items-center px-3">
                      <View className="flex-row items-center justify-center">
                        <Input
                          type="text"
                          value={comment}
                          setValue={(e) => {
                            setComment(e);
                          }}
                          // label="Email"
                          placeholder="Add comment..."
                          // validation={true}
                          // isValid={(e) => setIsInputValid({ e, type: "email" })}
                        ></Input>
                        <Pressable
                          className="ml-2 bg-app-c h-10 px-3 flex-row items-center border border-app-c rounded"
                          onPress={onSendComment}
                        >
                          <RemixIcon name="ri-send-plane-2-line" size={15} />
                        </Pressable>
                      </View>
                    </View>
                  </Card>

                  <Card title={`All comment`} className=" w-full">
                    {comments.length > 0 && !isCommentsLoading ? (
                      <View className="flex-1 w-full rounded-2xl">
                        {comments.map((e, i) => (
                          <View
                            className="w-full flex-row  justify-between items-center bg-app-b rounded-lg p-2"
                            key={`comment_${i}`}
                          >
                            <View className="flex-row self-stretch justify-start items-start rounded-full mr-3">
                              <Image
                                // resizeMode="contain"
                                source={
                                  e.user_profile_image_url &&
                                  e.user_profile_image_url !== ""
                                    ? {
                                        uri: `${DEV_API}/media/${e.user_profile_image_url}`,
                                      }
                                    : require("../../../assets/user1.jpg")
                                }
                                className="w-10 h-10 rounded-full"
                              ></Image>
                            </View>
                            <View className="flex-1 flex-col rounded-md bg-app-c2 relative">
                              <View className="w-full flex-row px-3 pt-1">
                                <Font className="py-1 font-bold">
                                  {e.user_name}
                                </Font>
                                {e.user_role === "vendor" && (
                                  <Font className="ml-2 p-1 bg-app-d rounded-md text-app-a text-xs">
                                    {e.user_role}
                                  </Font>
                                )}
                              </View>
                              <View className="w-full pt-1 px-3">
                                <Font className="text-xs text-app-d1">
                                  {getFullDateTime(e.created_at)}
                                </Font>
                              </View>
                              <View className="w-full p-3">
                                <Font>{e.message}</Font>
                              </View>
                              <View className="w-full flex-row items-center px-3 py-1 border-b border-app-c">
                                <View className="w-1/3 flex-row">
                                  <Font className="text-xs pr-2 text-app-d1">
                                    {e.likes ?? 10}
                                  </Font>
                                  <TouchableOpacity
                                    disabled={isInOperation}
                                    onPress={() =>
                                      onLikeAComment({
                                        id: e.id,
                                        type: e.is_liked ? "unlike" : "like",
                                      })
                                    }
                                    className="flex-row items-center"
                                  >
                                    <RemixIcon
                                      name={
                                        e.is_liked
                                          ? "ri-thumb-up-fill"
                                          : "ri-thumb-up-line"
                                      }
                                      size={11}
                                      color={
                                        e.is_liked
                                          ? colors.app.f
                                          : colors.app.d1
                                      }
                                    />
                                  </TouchableOpacity>
                                </View>
                                <View className="w-1/3 flex-row items-center">
                                  <RemixIcon
                                    name="reply-line"
                                    size={14}
                                    color={
                                      showUserComment.showReplyInput &&
                                      showUserComment.id == e.id
                                        ? colors.app.d1
                                        : colors.app.f
                                    }
                                  />
                                  <TouchableOpacity
                                    onPress={() =>
                                      setShowUserComment((prev) => ({
                                        ...prev,
                                        id:
                                          prev.id === e.id
                                            ? null
                                            : prev.id === e.id && prev.showReply
                                            ? e.id
                                            : e.id,

                                        showReplyInput:
                                          prev.id === e.id
                                            ? !prev.showReplyInput
                                            : true,
                                        showReply: false,
                                      }))
                                    }
                                  >
                                    <Font
                                      className={`text-xs pr-2 ${
                                        showUserComment.showReplyInput &&
                                        showUserComment.id == e.id
                                          ? "text-app-d"
                                          : "text-app-f"
                                      }`}
                                    >
                                      Reply
                                    </Font>
                                  </TouchableOpacity>
                                </View>
                                <View className="w-1/3 flex-row">
                                  <Font  className={`text-xs pr-2 ${
                                        showUserComment.showReply &&
                                        showUserComment.id == e.id
                                          ? "text-app-d"
                                          : "text-app-f"
                                      }`}>
                                    {e.replies?.length}
                                  </Font>
                                  <TouchableOpacity
                                    onPress={() =>
                                      setShowUserComment((prev) => ({
                                        ...prev,
                                        id:
                                          prev.id === e.id && prev.showReply
                                            ? null
                                            : prev.id === e.id &&
                                              prev.showAddComment
                                            ? e.id
                                            : e.id,
                                        showReply:
                                          prev.id === e.id
                                            ? !prev.showReply
                                            : true, // Toggle replies visibility
                                        showReplyInput: false,
                                      }))
                                    }
                                  >
                                    <Font   className={`text-xs pr-2 ${
                                        showUserComment.showReply &&
                                        showUserComment.id == e.id
                                          ? "text-app-d"
                                          : "text-app-f"
                                      }`}>
                                      Replies
                                    </Font>
                                  </TouchableOpacity>
                                </View>
                              </View>
                              <View className="w-full flex-col ">
                                {/* ---------------------- Input for adding a reply to a Comment ------------------------ */}
                                {showUserComment.id === e.id &&
                                  showUserComment.showReplyInput && (
                                    <View className="w-full flex-col py-1 px-3 my-3">
                                      <View className="w-full pb-1">
                                        <Font>Reply to this comment</Font>
                                      </View>
                                      <View className="sm:w-full flex-col sm:flex-row justify-between">
                                        <View className="w-full pb-3 sm:pb-0 sm:w-3/4 flex-row items-center justify-center rounded-l-md">
                                          <Input
                                            type="text"
                                            value={replyToAComment}
                                            setValue={(ee) => {
                                              setReplyToAComment(ee);
                                            }}
                                            placeholder="Reply to this comment..."
                                          ></Input>
                                          <Pressable
                                            className=" ml-2 bg-app-c h-10 px-3 flex justify-center items-center border border-app-c rounded-r-md rounded"
                                            onPress={() =>
                                              onSendReplyToAComment(e.id)
                                            }
                                          >
                                            <RemixIcon
                                              name="ri-send-plane-2-line"
                                              size={15}
                                            />
                                          </Pressable>
                                        </View>
                                      </View>
                                    </View>
                                  )}
                                <View className="w-full flex-col ">
                                  {/* ---------------------- Input for Editing a Comment ------------------------ */}
                                  {showUserComment.id === e.id &&
                                    showUserComment.showEdit && (
                                      <View className="w-full flex-col py-1 px-3 my-3">
                                        <View className="w-full pb-1">
                                          <Font>Edit this comment</Font>
                                        </View>
                                        <View className="sm:w-full flex-col sm:flex-row justify-between">
                                          <View className="w-full pb-3 sm:pb-0 sm:w-3/4 flex-row items-center justify-center rounded-l-md">
                                            <Input
                                              type="text"
                                              value={showUserComment.editValue}
                                              setValue={(ee) => {
                                                setShowUserComment((prev) => ({
                                                  ...prev,
                                                  editValue: ee,
                                                }));
                                              }}
                                              placeholder="Edit this comment..."
                                            ></Input>
                                            <Pressable
                                              className="ml-2 rounded bg-app-c h-10 px-3 flex justify-center items-center border border-app-c rounded-r-md"
                                              onPress={() =>
                                                onEditComment(e.id)
                                              }
                                            >
                                              <RemixIcon
                                                name="ri-send-plane-2-line"
                                                size={15}
                                              />
                                            </Pressable>
                                          </View>
                                        </View>
                                      </View>
                                    )}
                                  {/* ---------------------- replies to a comment ------------------------ */}
                                  {showUserComment.id === e.id &&
                                    showUserComment.showReply &&
                                    e.replies?.length > 0 &&
                                    e.replies?.reverse()?.map((ee, i) => (
                                      <View className="flex-col w-full" key={i}>
                                        <View
                                          className="w-full flex-row justify-between items-center p-2 border-b border-app-c"
                                          key={`user_comment_replies${i}`}
                                        >
                                          <View className="flex-row justify-start overflow-hidden rounded-full pb-4 mr-3">
                                            <Image
                                              // resizeMode="contain"
                                              source={
                                                ee?.user_profile_image_url &&
                                                ee?.user_profile_image_url !==
                                                  ""
                                                  ? {
                                                      uri: `${DEV_API}/media/${ee?.user_profile_image_url}`,
                                                    }
                                                  : require("../../../assets/user1.jpg")
                                              }
                                              className="w-10 h-10 rounded-full"
                                            ></Image>
                                          </View>
                                          <View className="w-full flex-1">
                                            <View className="w-full flex-row px-3 pt-1">
                                              <Font className="w-full py-1 font-bold">
                                                {ee.user_name}
                                              </Font>
                                            </View>
                                            <View className="w-full pt-1 px-3">
                                              <Font className="text-xs text-app-d1">
                                                {getFullDateTime(ee.created_at)}
                                              </Font>
                                            </View>
                                            <View className="w-full flex-1">
                                              <View className="w-full flex-row bg-app-c2 p-3 rounded-md">
                                                <Font>{ee.message}</Font>
                                              </View>
                                            </View>
                                            <View className="w-1/3 flex-row">
                                              <Font className="text-xs pr-2 text-app-d1">
                                                {ee.likes ?? 10}
                                              </Font>
                                              <TouchableOpacity
                                                disabled={isInOperation}
                                                onPress={() =>
                                                  onLikeAReply({
                                                    id: e.id,
                                                    replyId: ee.id,
                                                    type: ee.is_liked
                                                      ? "unlike"
                                                      : "like",
                                                  })
                                                }
                                                className="flex-row items-center"
                                              >
                                                <RemixIcon
                                                  name={
                                                    ee.is_liked
                                                      ? "ri-thumb-up-fill"
                                                      : "ri-thumb-up-line"
                                                  }
                                                  size={11}
                                                  color={
                                                    ee.is_liked
                                                      ? colors.app.f
                                                      : colors.app.d1
                                                  }
                                                />
                                              </TouchableOpacity>
                                            </View>
                                          </View>

                                          {userData?.id == ee?.user_id && (
                                            <View className="flex-row gap-1">
                                              <TouchableOpacity
                                                className="px-2 py-1 rounded-md"
                                                onPress={() => {
                                                  setShowUserComment(
                                                    (prev) => ({
                                                      ...prev,
                                                      replyId:
                                                        prev.replyId ===
                                                          ee.id &&
                                                        prev.showEditReply
                                                          ? ee.id
                                                          : ee.id,
                                                      replyEditValue:
                                                        ee?.message,
                                                      showEditReply:
                                                        prev.replyId ===
                                                          ee.id &&
                                                        prev.showEditReply
                                                          ? false
                                                          : true,
                                                    })
                                                  );
                                                }}
                                              >
                                                {showUserComment.replyId ===
                                                  ee.id &&
                                                showUserComment.isReplyInProgress ? (
                                                  <ActivityIndicator
                                                    size={"small"}
                                                    color={colors.app.e}
                                                  ></ActivityIndicator>
                                                ) : (
                                                  <AntDesign
                                                    name="edit"
                                                    size={15}
                                                    color={colors.app.e}
                                                  />
                                                )}
                                              </TouchableOpacity>
                                              <TouchableOpacity
                                                className="px-1 py-1 bg-app-e3 rounded-md"
                                                onPress={() => {
                                                  onDeleteReply(e?.id, ee?.id);
                                                }}
                                              >
                                                {showUserComment.replyId ===
                                                  ee.id &&
                                                showUserComment.isDeleteReplyInProgress ? (
                                                  <ActivityIndicator
                                                    size={"small"}
                                                    color={colors.app.e}
                                                  ></ActivityIndicator>
                                                ) : (
                                                  <AntDesign
                                                    name="delete"
                                                    size={15}
                                                    color={colors.app.e}
                                                  />
                                                )}
                                              </TouchableOpacity>
                                            </View>
                                          )}
                                        </View>
                                        {showUserComment.replyId === ee.id &&
                                          showUserComment.showEditReply && (
                                            <View className="w-full flex-col py-1 px-3 my-3">
                                              <View className="w-full pb-1">
                                                <Font>Edit this reply</Font>
                                              </View>
                                              <View className="sm:w-full flex-col sm:flex-row justify-between">
                                                <View className="w-full pb-3 sm:pb-0 sm:w-3/4 flex-row items-center justify-center rounded-l-md">
                                                  <Input
                                                    type="text"
                                                    value={
                                                      showUserComment.replyEditValue
                                                    }
                                                    setValue={(ee) => {
                                                      setShowUserComment(
                                                        (prev) => ({
                                                          ...prev,
                                                          replyEditValue: ee,
                                                        })
                                                      );
                                                    }}
                                                    placeholder="Edit this Reply..."
                                                  ></Input>
                                                  <Pressable
                                                    className="ml-2 bg-app-c h-10 px-3 flex justify-center items-center border border-app-c rounded-r-md rounded"
                                                    onPress={() =>
                                                      onEditReply(e?.id, ee?.id)
                                                    }
                                                  >
                                                    <RemixIcon
                                                      name="ri-send-plane-2-line"
                                                      size={15}
                                                    />
                                                  </Pressable>
                                                </View>
                                              </View>
                                            </View>
                                          )}
                                      </View>
                                    ))}
                                </View>
                              </View>
                              {/* ---------------------- buttons for Comment Delete & edit operation ------------------------ */}
                              {userData?.id == e?.user_id && (
                                <View className="flex-row gap-1 absolute right-2 top-2">
                                  <TouchableOpacity
                                    className="px-2 py-1 rounded-md"
                                    onPress={() => {
                                      setShowUserComment((prev) => ({
                                        ...prev,
                                        id:
                                          prev.id === e.id && prev.showEdit
                                            ? e.id
                                            : e.id,
                                        editValue: e?.message,
                                        showEdit:
                                          prev.id === e.id && prev.showEdit
                                            ? false
                                            : true,
                                      }));
                                    }}
                                  >
                                    {showUserComment.id === e.id &&
                                    showUserComment.isEditInProgress ? (
                                      <ActivityIndicator
                                        size={"small"}
                                        color={colors.app.e}
                                      ></ActivityIndicator>
                                    ) : (
                                      <AntDesign
                                        name="edit"
                                        size={20}
                                        color={colors.app.e}
                                      />
                                    )}
                                  </TouchableOpacity>
                                  <TouchableOpacity
                                    disabled={isDeleteCommentLoading.isLoading}
                                    className="px-1 py-1 bg-app-e3 rounded-md"
                                    onPress={() => {
                                      onDeleteComment(e?.id);
                                    }}
                                  >
                                    {isDeleteCommentLoading.id === e.id &&
                                    isDeleteCommentLoading.isLoading ? (
                                      <ActivityIndicator
                                        size={"small"}
                                        color={colors.app.e}
                                      ></ActivityIndicator>
                                    ) : (
                                      <AntDesign
                                        name="delete"
                                        size={20}
                                        color={colors.app.e}
                                      />
                                    )}
                                  </TouchableOpacity>
                                </View>
                              )}
                            </View>
                          </View>
                        ))}
                        {commentsPagination.total > commentsPerPage &&
                          commentsPagination.to <=
                            commentsPagination.total - commentsPerPage && (
                            <View className="w-full flex-row justify-center">
                              {isCommentsLoading ? (
                                <Pressable className="bg-app-e w-32 py-2 rounded-lg">
                                  <ActivityIndicator
                                    size={"small"}
                                    color={colors.app.a}
                                  ></ActivityIndicator>
                                </Pressable>
                              ) : (
                                <TouchableOpacity
                                  onPress={() => onLoadMore()}
                                  className="bg-app-e w-32 py-2 rounded-lg"
                                >
                                  <Font className="text-app-a text-center">
                                    Load More
                                  </Font>
                                </TouchableOpacity>
                              )}
                            </View>
                          )}
                      </View>
                    ) : comments.length === 0 && !isCommentsLoading ? (
                      <View className="w-full flex-row h-80 justify-center items-center">
                        <Font className="text-app-d1">No comments found.</Font>
                      </View>
                    ) : (
                      <View className="flex-1 w-full justify-center h-40">
                        <ActivityIndicator
                          size={"small"}
                          color={colors.app.e}
                        ></ActivityIndicator>
                      </View>
                    )}
                  </Card>
                </View>
                <View className="flex  lg:items-end w-full lg:w-2/6 p-0 lg:pl-4 pt-5 lg:pt-0">
                  <Widget></Widget>
                </View>
              </View>
            </View>
          </View>
          <View className="flex-row">
            <Footer></Footer>
          </View>
        </ScrollView>
      </View>
    </View>
  ) : (
    !user && !isAuthLoading && <Unautorized />
  );
}
