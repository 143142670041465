import React, { useContext, useEffect, useState } from "react";
import { Pressable, TextInput, TouchableOpacity, View } from "react-native";

import { ScrollView } from "react-native";
import { PleaseWaitModal } from "../../components/modals";
import { ResponseModal } from "../../components/response-modal";
import { Card } from "../../components/card";
import { Font } from "../../components/font";
import { Input } from "../../components/input";
import SVGLoader from "../../../utils/SVGLoader";
import search from "../../../assets/search.svg";
import RemixIcon from "react-native-remix-icon";
import CustomTooltip from "../../components/floatingToolTip";
import { UserContext } from "../../context/userContext";
import { EditGroup, LeaveGroupApi, patchUserData } from "../../services/api";
import { AuthContext } from "../../context/authContext";

export default function GroupSettings({
  navigation,
  route,
  groupLists,
  checkRefreshList,
}) {
  const { userToken } = useContext(AuthContext);
  const { userData } = useContext(UserContext);
  const [modal, setModal] = useState({
    isVisible: false,
    message: "",
    showSecondaryButton: false,
    id: "",
  });

  const [isActiveTab, setIsActiveTab] = useState(
    userData?.group_joining_preference == "no_restriction"
      ? "no_restriction"
      : "invites_only"
  );

  const [groupList, setGroupList] = React.useState();
  const [deleteModal, setIsDeleteModal] = React.useState(false);
  const [isPleaseWaitModal, setIsPleaseWaitModal] = React.useState(false);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [responseModal, setResponseModal] = React.useState({
    visible: false,
    message: "",
    DismissText: "",
  });

  React.useEffect(() => {
    console.log("groupList:", groupList);
    if (!groupList) setGroupList(groupLists?.length ? groupLists : undefined);
  }, [groupLists]);

  const closeDeleteModal = () => {
    setIsDeleteModal(false);
  };
  const closeResponseModal = () => {
    setResponseModal((prev) => ({ ...prev, visible: false }));
  };

  const LeaveGroup = async (id) => {
    try {
      const res = await LeaveGroupApi(userToken, id);
      console.log(res, "left group res");
      console.log(res.status, "left group res status");
      if (res.status == 204) {
        setGroupList((prevList) =>
          prevList.filter((member) => member.id !== modal.id)
        );
        setModal({ ...modal, isVisible: false });
        checkRefreshList();
        console.log("left group");
      } else {
        console.log("left group error");
      }
    } catch (error) {
      console.log(error, " group error left");
    }
  };

  const updateGroupJoiningPreference = async (isActiveTab) => {
    try {
      const formData = new FormData();
      formData.append("group_joining_preference", isActiveTab);
      const res = await patchUserData({
        token: userToken,
        data: { isActiveTab },
        id: userData.id,
      });

      if (res.status == 200) {
        setIsActiveTab(isActiveTab);
      } else {
        if (isModalVisible) {
        }
        console.log("failed", res.data);
        // checkRefreshList();
      }
    } catch (error) {
      console.log("failed error", error);
    }
  };

  return (
    <View className="flex-1 lg:bg-gray-50 w-full">
      <ScrollView
        className="py-4 rounded-t-2xl  "
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View className="w-full p-0 pt-0 ">
          <Card
            className="lg:flex-1 w-full"
            //  key={i}
          >
            <View className="flex-row justify-between w-full px-3">
              <Font className="font-medium ">Group joining preference</Font>
              {/* <Font className="text-app-e">See all</Font> */}
            </View>
            <View className=" border-b border-gray-300 w-[93%] my-3"></View>

            <View className="flex-row justify-center mt-2 mb-8 w-[43%]">
              <CustomTooltip
                align="bottom-right"
                tooltipText="You can be added to a group only if you accept the invite request."
                onPress={() => {
                  if (isActiveTab !== "invites_only")
                    updateGroupJoiningPreference("invites_only");
                }}
              >
                <View
                  className={`min-w-max shadow-sm  border rounded-tl-lg rounded-bl-lg px-4 py-1 pb-2 ${
                    isActiveTab === "invites_only"
                      ? "bg-red-200 border-app-e"
                      : "border-gray-300 "
                  } `}
                >
                  <Font
                    className={`text font-medium self-center justify-self-center ${
                      isActiveTab === "invites_only"
                        ? "text-app-e"
                        : "text-gray-300"
                    }`}
                    weight={600}
                  >
                    Invites only
                  </Font>
                </View>
              </CustomTooltip>
              <View className="" />
              <CustomTooltip
                align="bottom-left"
                tooltipText="You can be added directly into a group."
                onPress={() => {
                  if (isActiveTab !== "no_restriction")
                    updateGroupJoiningPreference("no_restriction");
                }}
              >
                <View
                  className={`min-w-max shadow-sm  rounded-tr-lg rounded-br-lg px-4 py-1  pb-2 border ${
                    isActiveTab === "no_restriction"
                      ? "border-app-e bg-red-200"
                      : "border-gray-300"
                  }`}
                >
                  <Font
                    className={`text font-medium self-center justify-self-center ${
                      isActiveTab === "no_restriction"
                        ? "text-app-e"
                        : "text-gray-300"
                    }`}
                    weight={600}
                  >
                    No Restriction
                  </Font>
                </View>
              </CustomTooltip>
            </View>
          </Card>
        </View>
        <View className="w-full p-0 pt-0 ">
          <Card
            className="lg:flex-1 "
            //  key={i}
          >
            <View className="flex-row justify-between w-full px-3">
              <Font className="font-medium ">Leave group</Font>
              {/* <Font className="text-app-e">See all</Font> */}
            </View>
            <View className=" border-b border-gray-300 w-[93%] my-3"></View>

            {!groupList?.length ? (
              <View className="py-4 flex-col justify-center items-center">
                <Font className="text-app-d1">
                  You haven't joined any group
                </Font>
              </View>
            ) : (
              groupList.map((e, i) => (
                <TouchableOpacity
                  className="px-2 py-0 flex-row justify-between w-full my-0"
                  key={i}
                  // onPress={() => navigation.navigate("add-member")}
                >
                  <View className="bg-white mb-2  py-1 w-full " key={i}>
                    <View className=" flex-row  w-full ">
                      <Pressable className="px-2 flex-col justify-start w-[70%] ">
                        <Font className=" text-base font font-bold flex-row flex">
                          {e?.name}
                          <View className="flex-row items-center">
                            <View>
                              <RemixIcon
                                name="ri-checkbox-blank-circle-fill"
                                size={4}
                                color="#000000"
                                className=" ml-2 mr-1  lg:mt-0  flex-shrink-0"
                              />
                            </View>
                            <View>
                              <RemixIcon
                                name={
                                  e?.is_private
                                    ? `ri-lock-fill`
                                    : `ri-earth-fill`
                                }
                                size={12} // or use Tailwind text-size class
                                color="#000000"
                              />
                            </View>
                          </View>
                        </Font>
                      </Pressable>
                      <View className="-mr-2.5 lg:mr-0 flex-row justify-center items-center w-[30%]">
                        <TouchableOpacity
                          onPress={() =>
                            setModal({
                              id: e?.id,
                              isVisible: true,
                              message: `Are You sure You want to leave ${e?.name} Group ?`,
                            })
                          }
                          // LeaveGroup(e?.id)}
                          className={`p-1 mr-0 lg:mr-2 flex justify-center items-center rounded-lg`}
                        >
                          <Font
                            className="text-gray-300 text-md px-2 "
                            style={{ color: "red" }}
                          >
                            Leave
                          </Font>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>
                </TouchableOpacity>
              ))
            )}
          </Card>
          {modal.isVisible && (
            <View className="flex-1">
              <ResponseModal
                modalContent={modal.message}
                modalVisible={modal.isVisible}
                setVisible={(e) => setModal({ ...modal, isVisible: e })}
                primaryButtonLabel="No"
                onPrimaryButtonPress={() => {
                  setModal({ ...modal, isVisible: false });
                }}
                showSecondaryButton={true}
                secondaryButtonLabel="Yes"
                onSecondaryButtonPress={() => LeaveGroup(modal?.id)}
              ></ResponseModal>
            </View>
          )}
        </View>
      </ScrollView>
    </View>
  );
}
