import React, { useEffect, useState } from "react";
import { ActivityIndicator, Platform, ScrollView, View } from "react-native";
import colors from "../../../colors";
import { Card } from "../../components/card";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import { Widget } from "../../components/widget";
import { DEV_API } from "../../services/api";
import axios from "axios";
import RenderHTML from "../../components/RenderHTML";

export const TermsCondition = ({ navigation, route }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [termsConditionData, setTermsConditionData] = useState("");

  useEffect(() => {
    getTermsAndConditionData();
  }, []);

  const getTermsAndConditionData = async () => {
    try {
      setIsLoading(false);
      const res = await axios.get(
        DEV_API + `/content/customer/terms-and-conditions`
      );
      setTermsConditionData(res.data?.data || "");
    } catch (error) {
      console.log("Error in getting terms and condition data");
    } finally {
      setIsLoading(true);
    }
  };

  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      <ScrollView
        className="p-4 rounded-t-2xl bg-gray-50"
        contentContainerStyle={{
          // flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View className=" max-w-screen-lg w-full mx-auto h-full">
          <View className="my-5 hidden lg:flex">
            <Font className="text-lg font-semibold text-app-d">
              Terms And Conditions
            </Font>
          </View>
          <View className="flex-col lg:flex-row max-w-screen-lg w-full  ">
            <View className=" w-full lg:w-4/6 p-0 lg:pr-4 ">
              <Card
                // title={`Last Updated : ${getFullDateWithWeekday(Date.now())}`}
                className=""
              >
                <View className="w-full p-4 ">
                  {!isLoading ? (
                    <ActivityIndicator
                      animating={true}
                      color={colors.app.e}
                      size={"small"}
                      className="my-8 mx-4"
                    />
                  ) : termsConditionData ? (
                    <View >
                      <RenderHTML htmlContent={termsConditionData} />
                    </View>
                  ) : (
                    <View >
                      <RenderHTML htmlContent={termsConditionData} />
                    </View>
                  )}
                </View>
              </Card>
            </View>

            <View className=" w-full lg:w-2/6 p-0 lg:pl-4 flex-1">
              <Widget></Widget>
            </View>
          </View>
          <Footer></Footer>
        </View>
      </ScrollView>
    </View>
  );
};
