import {
  View,
  Text,
  Platform,
  RefreshControl,
  StyleSheet,
  Alert,
} from "react-native";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Font } from "../../components/font";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { Button, Input } from "../../components/input";
import {
  axiosGet,
  axiosPost,
  initiateStripe,
  validateStripeStatus,
} from "../../services/api";
import { AuthContext } from "../../context/authContext";
import { Card } from "../../components/card";
import StripeCheckout from "../../components/stripe-checkout";
import Toast from "react-native-toast-message";
import { ScrollView } from "react-native";
import { useStripeContext } from "../../context/stripeContext";
import { Modal } from "react-native";
import { SafeAreaView } from "react-native";
import WebView from "react-native-webview";
import { ActivityIndicator } from "react-native";
import { ResponseModal } from "../../components/response-modal";
import { UserContext } from "../../context/userContext";

const TopupWithdrawal = ({ navigation, route }) => {
  const { userToken } = useContext(AuthContext);
  const { setUserData, userWallet, setUserWallet } = useContext(UserContext);
  const [canWithdraw, setCanWithdraw] = useState();
  const [checkoutState, setCheckoutState] = useState({
    customerId: undefined,
    ephemeralKey: undefined,
    paymentIntentId: undefined,
    clientSecret: undefined,
    transactionId: undefined,
    visible: false,
    isReloadedPage: false,
    isLoading: false,
    amount: 0,
  });
  const [amountValue, setAmountValue] = useState(0);
  const [showResponseModal, setShowResponseModal] = useState({
    visible: false,
    onRequestClose: () => null,
    bodyText: "",
  });
  const [topupIsLoading, setTopupIsLoading] = useState();
  const [withdrawIsLoading, setWithdrawIsLoading] = useState();
  const showToast = (type, line1, line2) => {
    Toast.show({
      type: type,
      text1: line1,
      text2: line2,
    });
  };
  const [refreshing, setRefreshing] = useState();
  const {
    stripeStatus,
    setStripeStatus,
    validateStripeUserBankAccountStatus,
    setAllowAddStripeBankAccount,
  } = useStripeContext();

  const getWalletBalance = useCallback(async () => {
    try {
      const res = await axiosGet({
        Url: "/wallets/mine",
        token: userToken,
      });
      if (res.status === 200) {
        setUserWallet({
          amount: res.data?.data?.wallet_balance || 0,
          currency: res.data?.data?.currency || "USD",
          currency_symbol: res.data?.data?.currency_symbol || "$",
        });
        setUserData((u) => ({
          ...(u || {}),
          wallet_balance: {
            amount: res.data?.data?.wallet_balance || u.amount || 0,
            currency: res.data?.data?.currency || u.currency || "USD",
            currency_symbol:
              res.data?.data?.currency_symbol || u.currency_symbol || "$",
          },
        }));
      }
    } catch (e) {
      console.log("getWalletBalance API error", e);
      if (e?.status) {
        console.log("getWalletBalance API error", e.data);
        showToast(
          "error",
          e
            ? e?.data?.message
              ? e.data.message
              : e?.data?.pathParams?.[0]
              ? `${e.data.pathParams[0]?.instancePath} ${e.data.pathParams[0]?.message}`
              : e?.message
            : e?.code === "ERR_NETWORK"
            ? e.message
            : "Unknown error occured"
        );
      } else {
        console.log("getWalletBalance API error", {
          ...e,
        });
      }
    } finally {
      setRefreshing(false);
    }
  });

  useEffect(() => {
    getWalletBalance();
  }, []);

  useEffect(() => {
    if (route.name === "withdraw") {
      validateStripeUserBankAccountStatus(true, userToken)
        .then(() => {
          setCanWithdraw(true);
        })
        .catch((err) => {
          console.log("validateStripeUserBankAccountStatus err:", err);
        });
    }
  }, [route.name]);

  const onWebLoad = React.useCallback(() => {
    setStripeStatus((prev) => ({ ...prev, isLoading: false }));
  }, []);

  const onWebLoadStart = React.useCallback(() => {
    setStripeStatus((prev) => ({ ...prev, isLoading: true }));
  }, []);

  const onNavigationStateChange = React.useCallback((event) => {
    const { url } = event;
    console.log("onNavigationStateChange:", url);
    if (url.endsWith("/return")) {
      setStripeStatus({
        ...stripeStatus,
        isLoading: false,
        isConfirmToValidate: false,
        stripeMessage: "",
      });
      validateStripeStatus({ token: userToken })
        .then((res) => {
          if (
            res.status === 200 &&
            res.data?.data?.account_reference?.onboarding_status == "completed"
          ) {
            setAllowAddStripeBankAccount(false);
            setCanWithdraw(true);
            setShowResponseModal({
              visible: true,
              onRequestClose: () => {
                setStripeStatus({
                  ...stripeStatus,
                  isConfirmToValidate: false,
                  isUserStripeValidated: true,
                  isLoading: false,
                  stripeMessage: undefined,
                });
                setShowResponseModal({
                  visible: false,
                  onRequestClose: () => null,
                  bodyText: "",
                });
              },
              bodyText: "Your Stripe account has been connected successfully.",
              dismissText: "Okay",
            });
            return res.data?.data;
          } else {
            if (Platform.OS === "web") {
              setShowResponseModal({
                visible: true,
                onRequestClose: () => {
                  setStripeStatus({
                    ...stripeStatus,
                    isConfirmToValidate: false,
                    isUserStripeValidated: false,
                    stripeMessage:
                      "Continue where you left off with your Bank Account Setup?",
                  });
                  setShowResponseModal({
                    visible: false,
                    onRequestClose: () => null,
                    bodyText: "",
                  });
                },
                bodyText: `${"Stripe Onboarding Canceled"}\n\n${
                  res?.data?.message
                    ? res?.data?.message +
                      "\n\nPlease register your Stripe Account to receive the payouts!"
                    : "Please provide your Bank Account details to start accepting payouts from us!"
                }`,
                dismissText: "Okay",
              });
            } else {
              Alert.alert(
                "Stripe Onboarding Canceled",
                `${
                  res?.data?.message
                    ? res?.data?.message +
                      "\n\nPlease provide your Bank Account details to start accepting payouts from us!"
                    : "Please provide your Bank Account details to start accepting payouts from us!"
                }`,
                [
                  {
                    onPress: () => {
                      setStripeStatus({
                        ...stripeStatus,
                        isConfirmToValidate: false,
                        isUserStripeValidated: false,
                        stripeMessage:
                          "Continue where you left off with your Bank Account Setup?",
                      });
                    },
                  },
                ]
              );
            }
          }
        })
        .catch((err) => {
          console.log(`${url} err:`, err);
          if (Platform.OS === "web") {
            setShowResponseModal({
              visible: true,
              onRequestClose: () => {
                setStripeStatus({
                  ...stripeStatus,
                  isUserStripeValidated: false,
                  isConfirmToValidate: true,
                  stripeMessage:
                    "Continue where you left off with your Bank Account Setup?",
                });
                setShowResponseModal({
                  visible: false,
                  onRequestClose: () => null,
                  bodyText: "",
                });
              },
              bodyText: `${err?.message || "Error:"}\n\n${err}`,
            });
          } else {
            Alert.alert(err?.message || "Error:", err, [
              {
                onPress: () => {
                  setStripeStatus({
                    ...stripeStatus,
                    isUserStripeValidated: false,
                    isConfirmToValidate: true,
                    stripeMessage:
                      "Continue where you left off with your Bank Account Setup?",
                  });
                },
              },
            ]);
          }
        });
    }
  }, []);

  const onCloseConfirmModal = React.useCallback(() => {
    setStripeStatus((prev) => ({
      ...prev,
      isLoading: false,
      isUserStripeValidated: true,
      isConfirmToValidate: false,
    }));
  }, []);

  const onConfirmPress = React.useCallback(async () => {
    const initStripeRes = await initiateStripe({ token: userToken });
    if (initStripeRes && initStripeRes.status === 200 && initStripeRes.data) {
      const { data } = initStripeRes.data;
      if (
        !data?.account_reference ||
        data?.account_reference?.onboarding_status == "initiated"
      ) {
        if (Platform.OS === "web") {
          return Linking.openURL(data.redirect_link);
        }
        setStripeStatus((prev) => ({
          ...prev,
          isLoading: true,
          isUserStripeValidated: true,
          isConfirmToValidate: true,
          stripeUrl: data.redirect_link,
        }));
      }
    }
  }, []);

  useEffect(
    Platform.OS === "web"
      ? () => {
          const clientSecret = route.params?.payment_intent_client_secret;

          if (!clientSecret) {
            return;
          }
          setCheckoutState((v) => ({
            ...v,
            clientSecret: clientSecret,
            visible: true,
            isReloadedPage: true,
            transactionId: route.params?.transaction_id,
            customerId: route.params?.cid,
            amount: route.params?.amount,
          }));
        }
      : () => {},
    []
  );

  const topUp = useCallback(async () => {
    if (!amountValue || isNaN(amountValue)) {
      return;
    }
    setTopupIsLoading(true);
    if (checkoutState.clientSecret && +checkoutState.amount === +amountValue) {
      if (Platform.OS === "web" || checkoutState.ephemeralKey) {
        if (checkoutState.visible) {
          setCheckoutState((v) => ({
            ...v,
            visible: false,
            isLoading: false,
          }));
        }
        setTimeout(() => {
          setCheckoutState((v) => ({
            ...v,
            visible: true,
            isReloadedPage: true,
            isLoading: true,
          }));
          setTopupIsLoading(false);
        }, 2000);
        return;
      }
    }
    try {
      const res = await axiosPost({
        Url: "/wallets/mine/top-up",
        data: {
          amount: +amountValue * 100,
        },
        token: userToken,
      });
      if (res.status === 201) {
        const clientSecret =
          res.data?.data?.payment_intent ||
          res.data?.data?.subscription?.latest_invoice?.setup_intent
            ?.client_secret;
        const paymentIntentId = undefined;
        // res.data?.data?.subscription?.latest_invoice?.payment_intent?.id;
        const transactionId = res.data.data?.transaction?.id;
        const ephemeralKey = res.data.data?.ephemeral_key;
        if (res.data?.data?.payment_intent) {
          setCheckoutState((v) => ({
            ...v,
            customerId: res.data?.data?.customer_id,
            paymentIntentId: paymentIntentId,
            clientSecret: clientSecret,
            visible: true,
            isReloadedPage: false,
            transactionId: transactionId,
            ephemeralKey: ephemeralKey,
            amount: amountValue,
          }));
          if (Platform.OS === "web") {
            navigation.setParams({
              payment_intent: paymentIntentId || "",
              payment_intent_client_secret: clientSecret,
              redirect_status: "initiated",
              transaction_id: transactionId,
              amount: amountValue,
            });
          }
        } else {
          showToast(
            "success",
            res.data?.message || "Successfully Added Balance to the Wallet"
          );
        }
      }
    } catch (e) {
      console.log("Top Up API error", e);
      if (e?.status) {
        console.log("Top Up API error", e.data);
        showToast(
          "error",
          e
            ? e?.data?.message
              ? e.data.message
              : e?.data?.pathParams?.[0]
              ? `${e.data.pathParams[0]?.instancePath} ${e.data.pathParams[0]?.message}`
              : e?.message
            : e?.code === "ERR_NETWORK"
            ? e.message
            : "Unknown error occured"
        );
      } else {
        console.log("Top Up API error", {
          ...e,
        });
      }
    } finally {
      setTopupIsLoading(false);
    }
  });

  const withdraw = useCallback(async () => {
    if (!amountValue || isNaN(amountValue)) {
      return;
    }
    setWithdrawIsLoading(true);
    try {
      const res = await axiosPost({
        Url: "/wallets/mine/withdraw",
        data: {
          amount: +amountValue * 100,
        },
        token: userToken,
      });
      if (res.status === 200 || res.status === 201) {
        setUserWallet({
          amount: res.data?.data?.wallet_balance || 0,
          currency: res.data?.data?.currency || "USD",
          currency_symbol: res.data?.data?.currency_symbol || "$",
        });
        setUserData((u) => ({
          ...(u || {}),
          wallet_balance: {
            amount: res.data?.data?.wallet_balance || u.amount || 0,
            currency: res.data?.data?.currency || u.currency || "USD",
            currency_symbol:
              res.data?.data?.currency_symbol || u.currency_symbol || "$",
          },
        }));
        showToast(
          "success",
          res.data?.message || "Withdraw request has been sent."
        );
        setAmountValue("");
      }
    } catch (e) {
      console.log("Withdraw API error", e);
      if (e?.status) {
        console.log("Withdraw API error", e.data);
        showToast(
          "error",
          e
            ? e?.data?.message
              ? e.data.message
              : e?.data?.pathParams?.[0]
              ? `${e.data.pathParams[0]?.instancePath} ${e.data.pathParams[0]?.message}`
              : e?.message
            : e?.code === "ERR_NETWORK"
            ? e.message
            : "Unknown error occured"
        );
      } else {
        console.log("Withdraw API error", {
          ...e,
        });
      }
    } finally {
      setWithdrawIsLoading(false);
    }
  });

  const onPaymentSuccess = useCallback(async (data) => {
    try {
      if (Platform.OS === "web") {
        navigation.setParams({
          payment_intent: undefined,
          payment_intent_client_secret: undefined,
          redirect_status: undefined,
          transaction_id: undefined,
          amount: undefined,
        });
      }
      setCheckoutState((v) => ({
        ...v,
        customerId: undefined,
        ephemeralKey: undefined,
        paymentIntentId: undefined,
        clientSecret: undefined,
        transactionId: null,
        visible: false,
        isReloadedPage: false,
        amount: undefined,
      }));
      let res = await axiosPost({
        Url: `/wallets/mine/top-up/${data.transactionId}/confirm`,
        data: {},
        token: userToken,
      });
      if (res.status === 200) {
        setUserWallet({
          amount: res.data?.data?.wallet_balance || 0,
          currency: res.data?.data?.currency || "USD",
          currency_symbol: res.data?.data?.currency_symbol || "$",
        });
        setUserData((u) => ({
          ...(u || {}),
          wallet_balance: {
            amount: res.data?.data?.wallet_balance || u.amount || 0,
            currency: res.data?.data?.currency || u.currency || "USD",
            currency_symbol:
              res.data?.data?.currency_symbol || u.currency_symbol || "$",
          },
        }));
      }
      setAmountValue("");
      showToast(
        "success",
        res.data?.message || "Successfully Added Balance to the Wallet"
      );
    } catch (error) {
      showToast(
        "error",
        error.code,
        `${
          error?.message === error?.localizedMessage
            ? error.localizedMessage
            : `${error.message}\t\n\n${error.localizedMessage || ""}`
        }`
      );
      console.log("Payment confirmation error: ", error);
    }
  }, []);

  const onPaymentError = useCallback((error, paymentIntent) => {
    console.log("onPaymentError:", { ...error }, paymentIntent);
    if (
      (
        error?.payment_intent?.status ||
        paymentIntent?.status ||
        error?.code ||
        ""
      ).toLowerCase() === "canceled"
    ) {
      console.log(
        "cancelled checkoutState: ",
        checkoutState,
        "\ncustom obj: ",
        {
          ...(Platform.OS === "web"
            ? {
                customerId: undefined,
                ephemeralKey: undefined,
                paymentIntentId: undefined,
                clientSecret: undefined,
                transactionId: null,
              }
            : {}),
          visible: false,
          isReloadedPage: false,
        }
      );
      setCheckoutState((v) => ({
        ...v,
        ...(Platform.OS === "web"
          ? {
              customerId: undefined,
              ephemeralKey: undefined,
              paymentIntentId: undefined,
              clientSecret: undefined,
              transactionId: null,
              amount: undefined,
            }
          : {}),
        visible: false,
        isReloadedPage: false,
      }));
    }
    showToast(
      "error",
      error.code,
      `${
        error?.message === error?.localizedMessage
          ? error.localizedMessage
          : `${error.message}\t\n\n${error.localizedMessage || ""}`
      }`
    );
  }, []);

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    getWalletBalance();
  }, [getWalletBalance]);

  return (
    <StripeCheckout
      onSuccess={onPaymentSuccess}
      onError={onPaymentError}
      checkoutData={checkoutState}
      isReloadedPage={checkoutState.isReloadedPage}
      visible={checkoutState.visible}
      onRequestClose={() =>
        setCheckoutState((v) => ({
          ...v,
          visible: false,
        }))
      }
      onCheckoutLoad={() =>
        setCheckoutState((v) => ({ ...v, isLoading: true }))
      }
    >
      <View className="flex-1 bg-app-e lg:bg-gray-50 lg:px-8 lg:pb-4">
        <ScrollView
          className="rounded-t-2xl lg:rounded-none flex-1 bg-gray-50"
          refreshControl={
            Platform.OS === "web" ? null : (
              <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
            )
          }
        >
          <View className="flex-row items-center hidden lg:flex my-2 w-full lg:w-3/12">
            <Font className="text-lg font-semibold text-app-d hidden lg:flex">
              Wallet Balance
            </Font>
          </View>
          <View className="flex-row w-full border-b-2 p-4 border-app-c mb-4">
            <View className="p-4 bg-app-e3 rounded-full mr-4 items-center justify-center border border-app-e">
              <RemixIcon
                name="wallet-fill"
                color={colors.app.e}
                size={30}
              ></RemixIcon>
            </View>
            <View className="flex-col justify-center">
              <Font className="text-app-e text-xl my-1" weight={700}>
                {userWallet?.currency_symbol
                  ? userWallet?.currency_symbol
                  : "$"}
                &nbsp;
                {(+(userWallet?.amount || 0) / 100).toFixed(2)}
              </Font>
              <Font className="text-app-d1 text-xs" weight={500}>
                Collected Wallet Balance
              </Font>
            </View>
          </View>
          <View className="w-full mx-auto flex-1">
            <View className="flex-col h-full max-w-screen w-full relative px-4">
              <Card title={route.name === "withdraw" ? "Withdraw" : "Topup"}>
                <View className="w-full flex-row">
                  <View className="p-4 pt-0 flex-col w-full justify-center items-center">
                    <View className="w-full flex-row lg:w-1/3 pb-4">
                      <Input
                        label={`Amount to ${
                          route.name === "withdraw" ? "Withdraw" : "Topup"
                        }`}
                        type="number"
                        value={amountValue}
                        setValue={(v) => {
                          setAmountValue(v);
                        }}
                      />
                    </View>
                    <View className="w-full flex-row justify-center">
                      <View className="w-full lg:w-1/3">
                        {withdrawIsLoading || topupIsLoading ? (
                          <View className="mt-4 py-1">
                            <ActivityIndicator
                              style={styles.loaderIndicator}
                              color={colors.app.e}
                              size="small"
                            />
                          </View>
                        ) : (
                          <Button
                            type="primary"
                            label={
                              route.name === "withdraw" ? "Withdraw" : "Topup"
                            }
                            onPress={
                              route.name === "withdraw"
                                ? canWithdraw
                                  ? withdraw
                                  : () =>
                                      showToast(
                                        "error",
                                        "Payout Error",
                                        "Bank account is not registered with stripe"
                                      )
                                : topUp
                            }
                          />
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </Card>
            </View>
          </View>
        </ScrollView>
        {!stripeStatus.isUserStripeValidated && stripeStatus.stripeMessage && (
          <ResponseModal
            showSecondaryButton={true}
            onPrimaryButtonPress={onConfirmPress}
            modalContent={stripeStatus.stripeMessage}
            onSecondaryButtonPress={onCloseConfirmModal}
            modalVisible={!stripeStatus.isUserStripeValidated}
            primaryButtonLabel="Proceed"
            secondaryButtonLabel="Not Now"
          />
        )}
        {showResponseModal.visible && (
          <ResponseModal
            onPrimaryButtonPress={showResponseModal.onRequestClose}
            modalContent={showResponseModal.bodyText}
            modalVisible={showResponseModal.visible}
            primaryButtonLabel="Okay"
          />
        )}
        {stripeStatus.isConfirmToValidate && (
          <Modal
            visible={stripeStatus.isConfirmToValidate}
            onRequestClose={() => {
              setStripeStatus((prev) => ({
                ...prev,
                isLoading: false,
                isUserStripeValidated: false,
                isConfirmToValidate: false,
              }));
            }}
          >
            <SafeAreaView style={styles.stripeModalContainer}>
              <WebView
                onLoad={onWebLoad}
                onLoadStart={onWebLoadStart}
                onNavigationStateChange={onNavigationStateChange}
                source={{ uri: stripeStatus.stripeUrl }}
                useWebView2
              />
            </SafeAreaView>
            {stripeStatus.isLoading && (
              <ActivityIndicator
                style={styles.loaderIndicator}
                color={colors.app.e}
                size="large"
              />
            )}
          </Modal>
        )}
      </View>
    </StripeCheckout>
  );
};

export default TopupWithdrawal;

const styles = StyleSheet.create({
  stripeModalContainer: {
    flex: 1,
  },
  loaderIndicator: {
    position: "absolute",
    alignSelf: "center",
    top: 0,
    bottom: 0,
    marginBottom: 14,
  },
});
