import React from "react";
import { validateStripeStatus } from "../services/api";

const INITIAL_STATE = {
  stripeStatus: {
    isUserStripeValidated: false,
    isLoading: false,
    stripeUrl: "",
    stripeMessage: "",
    isConfirmToValidate: false,
  },
  allowAddStripeBankAccount: true,
  setAllowAddStripeBankAccount: () => null,
  validateStripeUserBankAccountStatus: () => null,
  reset: () => null,
};

const StripeReducer = (state, action) => {
  switch (action.type) {
    case "SET_ADD_STRIPE_BANK_ACCOUNT_STATUS":
      return { ...state, allowAddStripeBankAccount: action.payload };
    case "RESET_STRIPE_STATES":
      return INITIAL_STATE;
    default:
      return state;
  }
};

export const StripeContext = React.createContext(INITIAL_STATE);

export const StripeContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(StripeReducer, INITIAL_STATE);
  const [stripeStatus, setStripeStatus] = React.useState(
    INITIAL_STATE.stripeStatus
  );

  const validateStripeUserBankAccountStatus = React.useCallback(
    async (allowAddStripeBankAccount, userToken) => {
      if (
        !(allowAddStripeBankAccount || state.allowAddStripeBankAccount) ||
        state.isUserStripeValidated
      ) {
        return;
      }
      setStripeStatus({
        ...stripeStatus,
        isLoading: false,
        stripeUrl: "",
        isConfirmToValidate: false,
      });
      const checkStripeRes = await validateStripeStatus({
        token: userToken,
      });
      if (checkStripeRes?.status) {
        if (checkStripeRes.status == 200 && checkStripeRes.data) {
          const { data } = checkStripeRes.data;
          if (data && data?.account_reference) {
            const { onboarding_status } = data.account_reference;
            if (onboarding_status == "completed") {
              dispatch({
                type: "SET_ADD_STRIPE_BANK_ACCOUNT_STATUS",
                payload: false,
              });
              setStripeStatus({
                ...stripeStatus,
                isUserStripeValidated: true,
              });
              return Promise.resolve(data);
            } else if (onboarding_status == "initiated") {
              setStripeStatus({
                ...stripeStatus,
                isUserStripeValidated: false,
                isConfirmToValidate: false,
                stripeMessage:
                  "Continue where you left off with your Bank Account Setup?",
              });
              return Promise.reject(checkStripeRes);
            }
          }
        } else {
          if (checkStripeRes.status == 404) {
            setStripeStatus({
              ...stripeStatus,
              isUserStripeValidated: false,
              isConfirmToValidate: false,
              stripeMessage: `${checkStripeRes.data?.message}\nPlease provide your Bank Account details to start accepting payouts from us`,
            });
          }
          return Promise.reject(checkStripeRes);
        }
      }
    },
    [state.allowAddStripeBankAccount]
  );

  return (
    <StripeContext.Provider
      value={{
        stripeStatus,
        setStripeStatus,
        validateStripeUserBankAccountStatus,
        allowAddStripeBankAccount: state.allowAddStripeBankAccount,
        setAllowAddStripeBankAccount: (allow) => {
          dispatch({
            type: "SET_ADD_STRIPE_BANK_ACCOUNT_STATUS",
            payload: allow,
          });
        },
        reset: () => {
          setStripeStatus(INITIAL_STATE.stripeStatus);
          dispatch({ type: "RESET_STRIPE_STATES" });
        },
      }}
    >
      {children}
    </StripeContext.Provider>
  );
};

export const useStripeContext = () => React.useContext(StripeContext);
