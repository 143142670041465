import React from "react";

const RenderHTML = ({ htmlContent }) => {
  return (
    <div style={{ height: "100%", width: "100%", display: "flex", flex: 1 }}>
      <iframe
        srcDoc={htmlContent}
        style={{
          width: "100%",
          minHeight: "500px",
          flexGrow: 1,
          border: "none",
        }}
        sandbox="allow-scripts allow-same-origin"
      />
    </div>
  );
};

export default RenderHTML;
