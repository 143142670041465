import {
  Pressable,
  ScrollView,
  View,
  Share,
  ActivityIndicator,
  Platform,
  RefreshControl,
  Modal,
  TouchableWithoutFeedback,
} from "react-native";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { PleaseWaitModal } from "../../components/modals";
import { ResponseModal } from "../../components/response-modal";
import SVGLoader from "../../../utils/SVGLoader";
import verticalmenu from "../../../assets/white-menu-vertical.svg";
import edit from "../../../assets/pencil.svg";
import profilelock from "../../../assets/Lock.svg";
import { Font } from "../../components/font";
import GroupMembers from "./group-members";
import CreateGroupPost from "./create-group-post";
import moment from "moment";
import colors from "../../../colors";
import { cloneDeep } from "lodash";
import {
  axiosGet,
  deleteAPost,
  getAPost,
  getGroupDetails,
  getGroupMembers,
  GetGroupsApi,
  LeaveGroupApi,
  LikePostApi,
  ShareAPost,
} from "../../services/api";
import { AuthContext } from "../../context/authContext";
import GroupPost from "../../components/group-components/GroupPost";
import EditModal from "../../components/group-components/modals/EditModal";
import GroupNavigations from "../../components/group-components/GroupNavigations";
import { useGroupsContext } from "../../context/groupsContext";
import { UserContext } from "../../context/userContext";
import RemixIcon from "react-native-remix-icon";
import Toast from "react-native-toast-message";

const ManageGroup = ({ route, navigation }) => {
  // const { tabStatus, setTabStatus } = useVendorFeedContext();
  const { userData } = useContext(UserContext);
  const { user, userToken } = useContext(AuthContext);
  const {
    groupTabStatus,
    setGroupTabStatus,
    memberGroups,
    setMemberGroups,
    adminGroups,
    setAdminGroups,
    setGroups,
    currentGroupInfo,
    setCurrentGroupInfo,
  } = useGroupsContext();
  const showToast = (type, line1, line2) => {
    Toast.show({
      type: type,
      text1: line1,
      text2: line2,
    });
  };
  const { id, checkRefreshList } = route.params;

  const [isRefreshList, setRefreshList] = React.useState(true);
  const checkRefreshListforPosts = () => {
    setRefreshList(!isRefreshList);
  };
  const [isGroupHandlingTab, setIsGroupHandlingTab] = useState("home");
  const [isEditModal, setIsEditModal] = useState(false);
  const closeEditModal = () => setIsEditModal(false);
  const [MembersList, setMembersList] = useState([]);
  const [groupPost, setGroupPost] = useState([]);
  const [isPleaseWaitModal, setIsPleaseWaitModal] = useState(false);
  const [responseModal, setResponseModal] = useState({
    visible: false,
    message: "",
    DismissText: "",
  });
  const [leaveGroupModal, setLeaveGroupModal] = useState({
    visible: false,
    message: "",
    DismissText: "",
  });
  const [isReLoading, setIsReLoading] = useState(false);
  const [showLeaveGroupModal, setShowLeaveGroupModal] = useState(false);
  const closeResponseModal = () => {
    setResponseModal((prev) => ({ ...prev, visible: false }));
  };

  const getGroupInfo = async (group_id) => {
    try {
      setIsPleaseWaitModal(true);
      let res = await getGroupDetails(group_id, userToken);
      if (res.status === 200) {
        setCurrentGroupInfo((prev) => ({
          ...prev,
          id: group_id,
          name: res.data?.data?.name,
          description: res.data?.data?.description,
          isPrivate: res.data?.data?.is_private,
          icon_cover_image_url: res.data?.data?.icon_cover_image_url,
        }));
      }
    } catch (error) {
      console.log("Error Getting Stats : ", error);
    } finally {
      setIsPleaseWaitModal(false);
    }
  };

  const timeAgo = (updatedAt) => moment(updatedAt).fromNow();

  const GetMembers = async () => {
    try {
      let res = await getGroupMembers(id, userToken);
      if (res.status === 200) {
        // console.log(res.data.data, "res for get members 200");
        setMembersList(res.data?.data || []);
        // console.log(res.data.data[0].user, "res for get members 200");
      } else console.log(res.data.data.user, "res for get members");
    } catch (error) {
      console.log("Error Getting Stats : ", error.response);
    }
  };

  const getGroupPosts = async () => {
    try {
      setIsPleaseWaitModal(true);
      let res = await getAPost(id, userToken);
      // console.log(res, "res check for get posts");

      if (res.status === 200) {
        setGroupPost(res.data?.posts || []);
        setCurrentGroupInfo((prev) => ({
          ...prev,
          noOfPosts: res.data?.posts?.length || 0,
        }));
        // console.log(res.data, "response check success for get posts");
      } else {
        console.log(res.data, "response check failed for get posts");
      }
    } catch (error) {
      console.log("Error Getting Stats : ", error.response);
    } finally {
      setIsPleaseWaitModal(false);
    }
  };

  React.useEffect(() => {
    if (route?.params?.post_id) {
      geGroupsDetailedPost();
    }
  }, []);
  const [sharedPostDetails, setSharedPostDetails] = useState("");
  const geGroupsDetailedPost = async () => {
    const Url = `/groups/${route?.params?.id}/posts/${route?.params?.post_id}`;
    await axiosGet({
      Url,
      token: userToken,
    })
      .then((data) => {
        console.log(data, "geGroupsDetailedPost");
        setSharedPostDetails(data?.data?.post);
      })
      .catch((error) => {
        showToast("error", "Error :", error?.response?.message);
      })
      .finally(() => {
        // setIsPostFilterLoading(false);
      });
  };
  const sharePostApi = async (data, postId, index) => {
    try {
      const result = await (Platform.OS === "web"
        ? window.navigator.share({
            text: data.message,
            url: data.url,
            title: data.title,
          })
        : Share.share({
            message: data.message,
            url: data.url,
            title: data.title,
          }));

      if (result?.action === Share.sharedAction || Platform.OS === "web") {
        if (result?.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
        }
        try {
          let res = await ShareAPost(id, postId, userToken);
          if (res.status === 201) {
            if (route?.params?.post_id) {
              setSharedPostDetails((prev) => ({
                ...prev,
                shares: prev.shares + 1,
              }));
            }
            setGroupPost((data) => {
              if (index > -1) {
                data[index].shares++;
              }
                return cloneDeep(data);
            });
            // setGroupPost(res.data.posts);
            // console.log(res.data, "response check success for share posts");
          } else {
            console.log(res.data, "response check failed for share posts");
          }
        } catch (error) {
          console.log("Error Getting share post : ", error);
        }
      } else if (result?.action === Share.dismissedAction) {
        // dismissed
        console.log("Share action was dismissed.");
      }
    } catch (error) {
      alert(error.message);
    }
  };
  const LikeDisLikePost = async (postId, isLiked, index) => {
    try {
      let res = await LikePostApi(id, postId, isLiked, userToken);
      console.log(res.data, res.status, "res check for Like posts");

      if (res.status === 201) {
        if (route?.params?.post_id) {
          setSharedPostDetails((prev) => ({
            ...prev,
            is_liked: isLiked ? false : true,
            likes: isLiked ? prev.likes - 1 : prev.likes + 1,
          }));
        }
        setGroupPost((data) => {
          if (index > -1) {
            const is_liked = isLiked;
            data[index].is_liked = is_liked ? false : true;
            if (is_liked) {
              data[index].likes--;
            } else {
              data[index].likes++;
            }
          }
          return cloneDeep(data);
        });
      } else {
        console.log(
          res.data,
          res.status,
          "response check failed for Like posts data"
        );
      }
    } catch (error) {
      console.log("Error Getting share : ", error);
    }
  };

  const deletePost = async (postId) => {
    try {
      setIsPleaseWaitModal(true);
      let res = await deleteAPost(id, postId, userToken);
      console.log(res.data, res.status, "res check for delete posts");

      if (res.status === 204) {
        setGroupPost((prevList) => {
          prevList.filter((post) => post.id !== postId);
          return [...prevList];
        });
        setIsPleaseWaitModal(false);
        setResponseModal({
          visible: true,
          message: "Post Deleted Successfully !!!",
          DismissText: "Dismiss",
        });
      } else {
        setResponseModal({
          visible: true,
          message: res.data?.message
            ? res.data.message
            : "Something went wrong !!!",
          DismissText: "Dismiss",
        });
        setIsPleaseWaitModal(false);
        console.log(
          res.data,
          res.status,
          "response check failed for delete posts"
        );
      }
    } catch (error) {
      console.log("Error Getting for delete post  : ", error);
    }
  };

  const loadJoinedGroups = async () => {
    try {
      // setIsPleaseWaitModal(true);
      const res = await GetGroupsApi({ token: userToken, id: userData.id });
      if (res?.status == 200) {
        const members = [];
        const admins = [];
        res.data?.data?.forEach((group) => {
          group?.members?.forEach((member) => {
            if (group.id === id) {
              setCurrentGroupInfo((prev) => ({
                ...prev,
                isAdmin: member.group_role === "admin" ? true : false,
              }));
            }
            if (member.group_role === "member") {
              members.push(group);
            } else if (member.group_role === "admin") {
              admins.push(group);
            }
          });
        });
        setGroups({ data: res.data?.data || [] });
        setMemberGroups({ data: members });
        setAdminGroups({ data: admins });
      } else if (res.data?.message) {
        setResponseModal((prev) => ({
          ...prev,
          visible: true,
          bodyText: res.data.message,
        }));
      } else {
        console.log("loadJoinedGroups response:", res.status, res.data);
      }
    } catch (error) {
      // setIsPleaseWaitModal(false);
      console.log("error?.code:", error, error);
    } finally {
      setIsPleaseWaitModal(false);
    }
  };

  const leaveGroup = async (group_id) => {
    try {
      const res = await LeaveGroupApi(userToken, group_id);
      console.log("leave group res:", res.status, res.data);
      if (res.status == 204) {
        setLeaveGroupModal((prev) => ({ ...prev, visible: false }));
        checkRefreshList();
        navigation.navigate("groups", { tab: "Your groups" });
      }
    } catch (error) {
      console.log("Group leave error:", error);
    }
  };

  useEffect(() => {
    if (!currentGroupInfo.name) getGroupInfo(id);
    if (!adminGroups || !memberGroups) loadJoinedGroups();
  }, [adminGroups, memberGroups]);

  useEffect(() => {
    currentGroupInfo.isAdmin == true ? null : setIsGroupHandlingTab("home");
    getGroupPosts();
    GetMembers();
  }, [isRefreshList, id]);

  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      {/* --------------------------mobile-screen Group Navbar--------------------------- */}
      {isPleaseWaitModal && <PleaseWaitModal showLoader={true} />}
      <ResponseModal
        visible={responseModal.visible}
        bodyText={responseModal.message}
        onRequestClose={closeResponseModal}
      ></ResponseModal>
      {isPleaseWaitModal && !currentGroupInfo?.id ? (
        <></>
      ) : (
        <View className="px-4 pb-4 pt-0 w-full flex lg:hidden bg-app-e">
          <View className="w-full flex-col">
            {currentGroupInfo.isAdmin == true ? (
              <></>
            ) : (
              <Pressable
                className="w-5 h-5 self-end mb-4"
                onPress={() => {
                  setShowLeaveGroupModal(true);
                }}
              >
                <SVGLoader
                  src={verticalmenu}
                  height={30}
                  width={25}
                  webHeight={"20em"}
                  webWidth={"auto"}
                />
              </Pressable>
            )}
            <View className="w-full justify-center items-center">
              <View
                className="mb-2 flex-col rounded-lg"
                style={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}
              >
                <View className="p-4 flex-col justify-center items-center">
                  <Font className="text-lg text-app-a font-bold">
                    {/* Relax Baby sitting */}
                    {currentGroupInfo.name}
                  </Font>
                  <Font className="text-sm text-app-a">
                    {currentGroupInfo.description}
                  </Font>
                  {/* <Font className="text-sm text-app-a">just relax</Font> */}
                </View>
                {currentGroupInfo.isAdmin == true ? (
                  <Pressable
                    onPress={() => setIsEditModal(true)}
                    className="px-2 py-0.5 flex-row justify-end items-center rounded-full self-end absolute bottom-0 right-0"
                    style={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}
                  >
                    <Font className="text-xs text-app-a mr-2">Edit</Font>
                    <View className="w-3 h-3 flex justify-center items-center">
                      <SVGLoader
                        src={edit}
                        height={10}
                        width={10}
                        webHeight={"20em"}
                        webWidth={"auto"}
                      />
                    </View>
                  </Pressable>
                ) : (
                  <></>
                )}
              </View>
              <View className="flex-row justify-center item-center">
                <View className="mr-2 flex lg:hidden justify-center item-center">
                  <RemixIcon
                    className="flex flex-shrink-0 lg:hidden"
                    name={
                      currentGroupInfo.isPrivate == true
                        ? `ri-lock-fill`
                        : `ri-earth-fill`
                    }
                    size={16}
                    color="#ffffff"
                  />
                </View>
                <Font className="text-xs text-app-a">
                  {currentGroupInfo.isPrivate == true ? "Private" : "Public"}{" "}
                  Group
                </Font>
                <View className="mx-2 flex lg:hidden justify-center item-center">
                  <RemixIcon
                    name="ri-checkbox-blank-circle-fill"
                    size={4}
                    color="#ffffff"
                    className="flex-shrink-0 justify-center items-center flex lg:hidden"
                  />
                </View>
                <Font className="text-xs text-app-a">
                  {MembersList?.length || 0} Members
                </Font>
              </View>
            </View>
          </View>
        </View>
      )}

      <ScrollView
        className="rounded-t-2xl bg-gray-50"
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
        keyboardShouldPersistTaps="always"
        refreshControl={
          Platform.OS === "web" ? null : (
            <RefreshControl
              refreshing={isReLoading}
              onRefresh={() => {
                setIsReLoading(true);
                if (isGroupHandlingTab === "home") {
                  getGroupPosts();
                }
                if (isGroupHandlingTab === "members") {
                  GetMembers();
                }
                setTimeout(() => setIsReLoading(false), 1000);
              }}
            />
          )
        }
      >
        <View className=" max-w-screen-xl w-full lg:py-4 h-full ">
          <View className="flex flex-col lg:flex-row max-w-screen-xl lg:justify-between w-full">
            <View className="hidden lg:flex lg:flex-row max-w-screen-xl w-full lg:w-[23%]">
              <GroupNavigations
                adminList={adminGroups?.slice(0, 4)}
                memberList={memberGroups?.slice(0, 4)}
                groupTab={groupTabStatus.curr}
                setGroupTab={(value) => {
                  setGroupTabStatus({ curr: value, prev: groupTabStatus.curr });
                  navigation.navigate("groups", { tab: value });
                }}
                showTopTabs={false}
                checkRefreshList={checkRefreshList}
              />
            </View>
            {/* second column for web */}
            {isPleaseWaitModal && !currentGroupInfo?.id ? (
              <></>
            ) : (
              <View className="max-w-screen-lg w-full lg:w-[73%] xl:w-[77%] h-full lg:px-4">
                {/* ------------------------------------------------lg-screen header--------------------------------------------- */}
                <View className="p-2 w-full hidden lg:flex bg-app-e rounded-lg">
                  <View className="w-full flex-col">
                    {currentGroupInfo.isAdmin == true ? (
                      <></>
                    ) : (
                      <View className="w-full flex-row justify-end items-center">
                        <SVGLoader
                          src={verticalmenu}
                          height={40}
                          width={40}
                          webHeight={"1.5em"}
                          webWidth={"auto"}
                        />
                        <Pressable
                          onPress={() => {
                            setShowLeaveGroupModal(true);
                          }}
                          className="absolute top-0 left-0 bottom-0 right-0"
                        ></Pressable>
                      </View>
                    )}
                    <View className="w-full justify-center items-center">
                      <View
                        className="m-2 flex-col rounded-lg relative"
                        style={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}
                      >
                        <View className="px-4 py-4 flex-col justify-center items-center max-w-lg">
                          <Font className="text-lg text-app-a font-bold">
                            {currentGroupInfo.name}
                          </Font>
                          <Font className="text-sm text-app-a">
                            {currentGroupInfo.description}
                          </Font>
                        </View>
                        {currentGroupInfo.isAdmin == true ? (
                          <Pressable
                            className="py-0.5 px-2 flex-row justify-end items-center rounded-full self-end absolute bottom-0 right-0"
                            style={{
                              backgroundColor: "rgba(255, 255, 255, 0.5)",
                            }}
                            onPress={() => setIsEditModal(true)}
                          >
                            <Font className="text-xs text-app-a mr-1">
                              Edit
                            </Font>
                            <View className="w-3 h-3">
                              <SVGLoader
                                src={edit}
                                height={40}
                                width={40}
                                webHeight={"20em"}
                                webWidth={"auto"}
                              />
                            </View>
                          </Pressable>
                        ) : (
                          <></>
                        )}
                      </View>
                      <View className="flex-row justify-center item-center">
                        <View className="mr-2 flex justify-center item-center">
                          <RemixIcon
                            className="hidden lg:flex flex-shrink-0"
                            name={
                              currentGroupInfo.isPrivate == true
                                ? `ri-lock-fill`
                                : `ri-earth-fill`
                            }
                            size={16}
                            color="#fff"
                          />
                        </View>
                        <Font className="text-xs text-app-a">
                          {currentGroupInfo.isPrivate == true
                            ? "Private"
                            : "Public"}{" "}
                          Group
                        </Font>
                        <View className="mx-2 hidden lg:flex justify-center item-center">
                          <RemixIcon
                            name="ri-checkbox-blank-circle-fill"
                            size={4}
                            color="#ffffff"
                            className="flex-shrink-0 justify-center items-center hidden lg:flex"
                          />
                        </View>
                        <Font className="text-xs text-app-a">
                          {MembersList?.length || 0} Members
                        </Font>
                      </View>
                    </View>
                  </View>
                </View>

                {/* ------------------------Group-Tabs----------------------------------- */}
                <View className="w-full flex-col rounded-lg">
                  {currentGroupInfo.isAdmin == true ? (
                    <View className="py-4 px-4 w-full flex-row justify-center overflow-auto">
                      <Pressable
                        className={`py-2 px-2 m-1 mr-0.5 lg:m-2 min-w-max  border border-gray-200 rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-lg ${
                          isGroupHandlingTab === "home" ? "bg-app-e" : "bg-none"
                        }`}
                        onPress={() => {
                          setIsGroupHandlingTab("home");
                        }}
                      >
                        <Font
                          className={`text font-medium self-center justify-self-center ${
                            isGroupHandlingTab === "home"
                              ? "text-app-a"
                              : "text-app-d1"
                          }`}
                          weight={600}
                        >
                          Home
                        </Font>
                      </Pressable>
                      <Pressable
                        className={`py-2 px-3 m-1 mx-0.5 lg:m-2 min-w-max flex border border-gray-200  rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-lg ${
                          isGroupHandlingTab === "create-post"
                            ? "bg-app-e"
                            : "bg-none"
                        }`}
                        onPress={() => {
                          setIsGroupHandlingTab("create-post");
                        }}
                      >
                        <Font
                          className={`text font-medium self-center justify-self-center ${
                            isGroupHandlingTab === "create-post"
                              ? "text-app-a "
                              : "text-app-d1"
                          }`}
                          weight={600}
                        >
                          Create a Post
                        </Font>
                      </Pressable>
                      <Pressable
                        className={`py-2 px-3 m-1 mx-0.5 lg:m-2 min-w-max flex border border-gray-200  rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-lg ${
                          isGroupHandlingTab === "members"
                            ? "bg-app-e"
                            : "bg-none"
                        }`}
                        onPress={() => {
                          setIsGroupHandlingTab("members");
                        }}
                      >
                        <Font
                          className={`text font-medium self-center justify-self-center ${
                            isGroupHandlingTab === "members"
                              ? "text-app-a "
                              : "text-app-d1"
                          }`}
                          weight={600}
                        >
                          Members
                        </Font>
                      </Pressable>
                    </View>
                  ) : (
                    <></>
                  )}

                  {/* ------------------------------Home Tab Section------------------------------ */}

                  {isGroupHandlingTab === "home" && (
                    <View
                      className={`w-full max-w-xl self-center ${
                        currentGroupInfo.isAdmin == true ? "" : "mt-4"
                      }`}
                    >
                      {isPleaseWaitModal ? (
                        <ActivityIndicator
                          className="self-center justify-self-center flex-1 w-full h-full"
                          color={colors.app.e}
                          size="small"
                        />
                      ) : !groupPost?.length ? (
                        <View className="py-4 flex-col justify-center items-center">
                          <Font className="text font-medium text-app-d1 text-base">
                            No posts yet
                          </Font>
                        </View>
                      ) : (
                        <>
                          {route?.params?.post_id &&
                          sharedPostDetails !== "" ? (
                            <>
                              <View className="py-4 flex-row justify-between w-full">
                                <Font className="text font-medium text-app-d1 text-base">
                                  Shared Post
                                </Font>
                                <Pressable
                                  onPress={() => setSharedPostDetails("")}
                                >
                                  <RemixIcon
                                    name="close-line"
                                    color="red"
                                    size={20}
                                  ></RemixIcon>
                                </Pressable>
                              </View>
                              <View className="">
                                <GroupPost
                                  attachedFileURL={
                                    sharedPostDetails?.attached_file_url
                                  }
                                  key={sharedPostDetails?.id}
                                  postId={sharedPostDetails?.id}
                                  groupId={id}
                                  postIconURL={
                                    currentGroupInfo.icon_cover_image_url
                                  }
                                  postCoverImageURL={
                                    sharedPostDetails?.cover_image_url
                                  }
                                  postTitle={
                                    sharedPostDetails?.title ||
                                    currentGroupInfo.name
                                  }
                                  postTimestamp={sharedPostDetails?.created_at}
                                  postDescription={
                                    sharedPostDetails?.description
                                  }
                                  externalLinkUrl={
                                    sharedPostDetails?.external_link_url
                                  }
                                  postLikes={sharedPostDetails?.likes}
                                  isPostLiked={sharedPostDetails?.is_liked}
                                  postComments={sharedPostDetails?.comments}
                                  postShares={sharedPostDetails?.shares}
                                  onLikeGroupPost={({ postId, isLiked }) =>
                                    LikeDisLikePost(postId, isLiked)
                                  }
                                  onSharePost={() =>
                                    sharePostApi(
                                      {
                                        message: `https://smbexcustomer.wisdomtooth.tech/group-details?id=${id}&post_id=${sharedPostDetails?.id}`,
                                        title: sharedPostDetails?.description,
                                        url: `https://smbexcustomer.wisdomtooth.tech/group-details?id=${id}&post_id=${sharedPostDetails?.id}`,
                                      },
                                      sharedPostDetails?.id
                                    )
                                  }
                                  allowPostEdit={
                                    currentGroupInfo?.isAdmin == true
                                  }
                                  onDeletePost={deletePost}
                                  onPostCommentSuccess={() => {
                                    sharedPostDetails((posts) => {
                                      posts.comments++;

                                      return [...posts];
                                    });
                                  }}
                                  onDeleteCommentSuccess={() => {
                                    sharedPostDetails((posts) => {
                                      posts.comments--;

                                      return [...posts];
                                    });
                                  }}
                                  onEditPost={(postData) => {
                                    sharedPostDetails((posts) => {
                                      posts = {
                                        ...posts,
                                        ...postData,
                                      };

                                      return [...posts];
                                    });
                                  }}
                                />
                              </View>
                              <View className="border border-gray-200 w-full my-10"></View>
                            </>
                          ) : (
                            <></>
                          )}
                          {groupPost.slice().map((e, i) => (
                            <GroupPost
                              attachedFileURL={e?.attached_file_url}
                              key={e?.id + "" + i}
                              postId={e?.id}
                              groupId={id}
                              postIconURL={
                                currentGroupInfo.icon_cover_image_url
                              }
                              postCoverImageURL={e?.cover_image_url}
                              postTitle={e?.title || currentGroupInfo.name}
                              postTimestamp={e?.created_at}
                              postDescription={e?.description}
                              externalLinkUrl={e?.external_link_url}
                              postLikes={e?.likes}
                              isPostLiked={e?.is_liked}
                              postComments={e?.comments}
                              postShares={e?.shares}
                              onLikeGroupPost={({ postId, isLiked }) =>
                                LikeDisLikePost(postId, isLiked, i)
                              }
                              onSharePost={() =>
                                sharePostApi(
                                  {
                                    message: `https://smbexcustomer.wisdomtooth.tech/group-details?id=${id}&post_id=${e?.id}`,
                                    title: e?.description,
                                    url: `https://smbexcustomer.wisdomtooth.tech/group-details?id=${id}&post_id=${e?.id}`,
                                  },
                                  id,
                                  i
                                )
                              }
                              allowPostEdit={currentGroupInfo.isAdmin == true}
                              onDeletePost={deletePost}
                              onPostCommentSuccess={() => {
                                setGroupPost((posts) => {
                                  const index = posts.findIndex(
                                    (p) => p.id === e.id
                                  );
                                  if (index > -1) {
                                    posts[index].comments++;
                                  }
                                  return [...posts];
                                });
                              }}
                              onDeleteCommentSuccess={() => {
                                setGroupPost((posts) => {
                                  const index = posts.findIndex(
                                    (p) => p.id === e.id
                                  );
                                  if (index > -1) {
                                    posts[index].comments--;
                                  }
                                  return [...posts];
                                });
                              }}
                              onEditPost={(postData) => {
                                setGroupPost((posts) => {
                                  const index = posts.findIndex(
                                    (p) => p.id === postData.id
                                  );
                                  if (index > -1) {
                                    posts[index] = {
                                      ...posts[index],
                                      ...postData,
                                    };
                                  }
                                  return [...posts];
                                });
                              }}
                            />
                          ))}
                        </>
                      )}
                    </View>
                  )}

                  {/* ---------------------Create Post tab Section -------------------------- */}
                  {isGroupHandlingTab === "create-post" && (
                    <CreateGroupPost
                      navigation={navigation}
                      groupId={id}
                      userToken={userToken}
                      groupName={currentGroupInfo.name}
                      checkRefreshListforPosts={checkRefreshListforPosts}
                      onPostCreate={() => {
                        getGroupPosts();
                        setIsGroupHandlingTab("home");
                      }}
                    />
                  )}

                  {/* -----------------------Group-members Tab Section----------------------------- */}
                  {isGroupHandlingTab === "members" && (
                    <GroupMembers
                      navigation={navigation}
                      MembersLists={MembersList}
                      userToken={userToken}
                      id={user.id}
                      groupId={id}
                    />
                  )}

                  {/* -----------------------Group settings tab section------------------------------ */}
                  {/* {isGroupHandlingTab === "settings" && (
                <GroupSettings
                  navigation={navigation}
                  groupId={id}
                  checkRefreshList={checkRefreshList}
                  userId={user.id}
                  isPrivate={isPrivate}
                  groupStatus={groupStatus}
                />
              )} */}
                </View>
              </View>
            )}
            {/* <Footer navigation={navigation} /> */}
          </View>
        </View>
      </ScrollView>
      {isEditModal ? (
        <EditModal
          navigation={navigation}
          visible={isEditModal}
          onRequestClose={closeEditModal}
          userId={user.id}
          groupId={id}
          name={currentGroupInfo.name}
          description={currentGroupInfo.description}
          activetab={currentGroupInfo.isPrivate == true ? "Private" : "Public"}
          checkRefreshList={checkRefreshList}
        ></EditModal>
      ) : (
        <></>
      )}
      {showLeaveGroupModal ? (
        <Modal
          visible={showLeaveGroupModal}
          transparent={true}
          onRequestClose={() => {
            setShowLeaveGroupModal(false);
          }}
        >
          <TouchableWithoutFeedback
            onPress={() => {
              setShowLeaveGroupModal(false);
            }}
          >
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
              className="w-full h-full justify-center items-center bg-black/50"
            >
              <TouchableWithoutFeedback>
                <View
                  style={{
                    // position: "absolute",
                    // left: buttonPosition.left,
                    // top: buttonPosition.top,
                    backgroundColor: "white",
                    paddingVertical: 10,
                    borderRadius: 10,
                    width: 150,
                    shadowColor: "#000",
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.25,
                    shadowRadius: 3.84,
                    elevation: 5,
                  }}
                >
                  <Pressable
                    onPress={() =>
                      setLeaveGroupModal({
                        groupId: id,
                        visible: true,
                        message: `Are you sure, want to leave ${currentGroupInfo.name} group?`,
                      })
                    }
                    className="bg-app-a p-2 rounded-lg shadow-sm"
                  >
                    <Font className="text-app-d leading-4 self-center">
                      Leave Group
                    </Font>
                  </Pressable>
                </View>
              </TouchableWithoutFeedback>
            </View>
          </TouchableWithoutFeedback>
        </Modal>
      ) : (
        <></>
      )}
      {leaveGroupModal.visible ? (
        <ResponseModal
          modalContent={leaveGroupModal.message}
          modalVisible={leaveGroupModal.visible}
          setVisible={(e) =>
            setLeaveGroupModal((prev) => ({
              ...prev,
              visible: !leaveGroupModal.visible,
            }))
          }
          primaryButtonLabel="No"
          onPrimaryButtonPress={() => {
            setLeaveGroupModal((prev) => ({
              ...prev,
              visible: !leaveGroupModal.visible,
            }));
          }}
          showSecondaryButton={true}
          secondaryButtonLabel="Yes"
          onSecondaryButtonPress={() => leaveGroup(leaveGroupModal?.groupId)}
        ></ResponseModal>
      ) : (
        <></>
      )}
    </View>
  );
};

export default ManageGroup;
