import React, { useContext, useEffect, useState } from "react";
import {
  ActivityIndicator,
  Platform,
  Pressable,
  ScrollView,
  View,
} from "react-native";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { Card } from "../../components/card";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import { Button, Input } from "../../components/input";
import { UserContext } from "../../context/userContext";
import {
  deleteUserBillingAddresses,
  deleteUserShippingAddresses,
  getUserBillingAddresses,
  getUserShippingAddresses,
  makeShippingAddressesToBilling,
  postUserShippingAddressesAsDefault,
} from "../../services/api";
import { AntDesign } from "@expo/vector-icons";
import { Feather } from "@expo/vector-icons";
import { AuthContext } from "../../context/authContext";
import { color } from "react-native-reanimated";
import ConfirmModal from "../../components/ConfirmModal";
import Toast from "react-native-toast-message";
import AsyncStorage from "@react-native-async-storage/async-storage";

export const ManageAddresses = ({ navigation, route }) => {
  const { userToken } = useContext(AuthContext);
  const {
    userAddressList,
    userBillingAddress,
    isUserLoading,
    setUserAddressList,
    setUserBillingAddress,
    setIsUserLoading,
  } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [sameAsBillingAddress, setSameAsBillingAddress] = useState(false);
  const goToPost = (slug) => {
    // console.log(slug);
    navigation.navigate({ name: "post", params: { slug: slug } });
  };

  const [deleteModal, setIsDeleteModal] = React.useState({
    visible: false,
    id: "",
    function: "",
    text: "",
    functionParam: "",
    onConfirmText: "",
  });

  const getBillingAddress = async () => {
    try {
      setIsLoading(true);
      const res = await getUserBillingAddresses(userToken);
      if (res.status === 200) {
        setUserBillingAddress(res.data.data);
      } else {
        console.log("manage-address, getBillingAddress res error : ", res.data);
      }
    } catch (error) {
      console.log("manage-address, getBillingAddress error : ", error);
    } finally {
      setIsLoading(false);
    }
  };
  const getShippingAddress = async () => {
    try {
      setIsUserLoading(true);
      const res = await getUserShippingAddresses(userToken);
      if (res.status === 200) {
        setUserAddressList(res.data.data);
      } else {
        console.log(
          "manage-address, getShippingAddress res error : ",
          res.data
        );
      }
    } catch (error) {
      console.log("manage-address, getShippingAddress error : ", error);
    } finally {
      setIsUserLoading(false);
    }
  };
  useEffect(() => {
    if (userToken) {
      getBillingAddress();
      getShippingAddress();
    }
  }, [userToken]);
  const showToast = (type, line1, line2) => {
    Toast.show({
      type: type,
      text1: line1,
      text2: line2,
    });
  };
  const onAddressAction = async (id, type) => {
    try {
      // setIsUserLoading(true);
      switch (type) {
        case "set-default":
          const res = await postUserShippingAddressesAsDefault(userToken, id);
          if (res.status === 200) {
            showToast("success", "Success", "Address set to default !");
            let updateList1 = [...userAddressList];
            const index1 = updateList1.findIndex((e) => e.is_default === true);
            if (index1 > -1) {
              updateList1[index1].is_default = false;
              const index2 = updateList1.findIndex((e) => e.id === id);
              if (index2 > -1) {
                updateList1[index2].is_default = true;
              }
            }
            setUserAddressList(updateList1);
          } else {
            console.log(
              "manage-address, setDefaultAddress res error : ",
              res.data
            );
          }
          break;

        case "delete-shipping":
          const deleteRes = await deleteUserShippingAddresses(userToken, id);
          if (deleteRes.status === 200) {
            setIsDeleteModal((prev) => ({ ...prev, visible: false }));
            showToast("success", "Success", "Address Deleted !");
            // const index1 = userAddressList.findIndex(
            //   (e) => e.is_default === true
            // );
            // let updateList1;
            // if (id === userAddressList[index1].id) {
            //   const index2 = userAddressList.findIndex(
            //     (e) => e.id === deleteRes.data.new_default
            //   );
            //   updateList1 = [...userAddressList];
            //   updateList1[index2].is_default = true;
            // }
            // updateList1 = userAddressList.filter((e) => e.id !== id);
            // setUserAddressList(updateList1);
            setTimeout(() => {
              // getBillingAddress();
              getShippingAddress();
            }, 100);
          } else {
            setIsDeleteModal((prev) => ({ ...prev, visible: false }));
            console.log(
              "manage-address, deleteAddress res error : ",
              deleteRes.data
            );
          }
          break;

        case "make-billing":
          const makeBillingRes = await makeShippingAddressesToBilling(
            userToken,
            id
          );
          if (makeBillingRes.status === 200) {
            setIsDeleteModal((prev) => ({ ...prev, visible: false }));
            showToast("success", "Success", "Billing Address changed !");
            // const index1 = userAddressList.findIndex(
            //   (e) => e.is_default === true
            // );
            // let updateList1;
            // if (id === userAddressList[index1].id) {
            //   const index2 = userAddressList.findIndex(
            //     (e) => e.id === makeBillingRes.data.new_default
            //   );
            //   updateList1 = [...userAddressList];
            //   updateList1[index2].is_default = true;
            // }
            // updateList1 = userAddressList.filter((e) => e.id !== id);
            // setUserAddressList(updateList1);
            setTimeout(() => {
              getBillingAddress();
              getShippingAddress();
            }, 100);
          } else {
            showToast("error", "error", "failed !");
            setIsDeleteModal((prev) => ({ ...prev, visible: false }));
            console.log(
              "manage-address, make-billing res error : ",
              makeBillingRes.data
            );
          }
          break;

        case "delete-billing":
          AsyncStorage.removeItem("savedParams");
          const deleteBillingRes = await deleteUserBillingAddresses(
            userToken,
            id
          );
          if (deleteBillingRes.status === 204) {
            setUserBillingAddress(null);
          } else {
            console.log(
              "manage-address, deleteBillingAddress res error : ",
              deleteBillingRes.data
            );
          }
          break;
        default:
          break;
      }
    } catch (error) {
      console.log("manage-address, setDefaultAddress error : ", error);
    } finally {
      // setIsUserLoading(false);
    }
  };

  const extractContent = (s, space) => {
    var span = document.createElement("span");
    span.innerHTML = s;
    if (space) {
      var children = span.querySelectorAll("*");
      for (var i = 0; i < children.length; i++) {
        if (children[i].textContent) children[i].textContent += " ";
        else children[i].innerText += " ";
      }
    }
    return [span.textContent || span.innerText].toString().replace(/ +/g, " ");
  };
  return (
    <View className="flex-1 bg-app-e lg:bg-app-b">
      <ConfirmModal
        visible={deleteModal.visible}
        onCancel={() =>
          setIsDeleteModal((prev) => ({ ...prev, visible: false }))
        }
        onConfirm={() => {
          onAddressAction(deleteModal.id, deleteModal.functionParam);
          setIsDeleteModal((prev) => ({ ...prev, visible: false }));
        }}
        confirmText={deleteModal.onConfirmText}
        bodyText={deleteModal.text}
        BodyComponent={false}
      ></ConfirmModal>
      <ScrollView className="flex-1 p-4 rounded-t-2xl bg-app-b">
        <View className="max-w-screen-lg w-full mx-auto justify-start mb-5">
          <View className="my-5 hidden lg:flex">
            <Font className="text-lg font-semibold text-app-d">
              Manage Addresses
            </Font>
          </View>
          <View className="flex-col lg:flex-row w-full">
            <View className="flex-col w-full lg:w-4/6 p-0 lg:pr-4">
              <View className=" w-full ">
                <Card title="Billing Address">
                  <View className="px-4 w-full">
                    {userBillingAddress && !isLoading ? (
                      <View className="flex-col w-full">
                        <View className="flex-row w-full items-start">
                          <Pressable
                            className=" pb-3 flex-1"
                            // onPress={() =>
                            //   navigation.navigate("update-address", { id: userBillingAddress.id })
                            // }
                          >
                            <Font className="text-app-d" weight={600}>
                              {/* <Font className="text-app-d" weight={700}>
                                {userBillingAddress?.recepientName}
                              </Font>{" "} */}
                              {userBillingAddress?.address?.title ?? "--"}
                            </Font>
                            <Font className="text-app-d my-2" weight={500}>
                              {userBillingAddress?.address?.phone}
                            </Font>
                            <Font className="text-app-d" weight={500}>
                              {userBillingAddress?.address?.locality}
                              {`, ${userBillingAddress?.address?.city}`}
                              {`, ${userBillingAddress?.address?.state}`}
                              {`, ${userBillingAddress?.address?.country}`}
                            </Font>
                          </Pressable>
                          <View className="py-4 pr-3">
                            <Feather
                              name="edit"
                              size={20}
                              color={colors.app.g}
                              onPress={async () => {
                                await AsyncStorage.setItem(
                                  "savedParams",
                                  JSON.stringify(userBillingAddress?.address)
                                );
                                navigation.navigate("update-address", {
                                  id: userBillingAddress?.id,
                                  is_billing: true,
                                  addressState: "edit",
                                  data: userBillingAddress?.address,
                                })
                              }
                              }
                            />
                          </View>
                          <View className="py-4">
                            <AntDesign
                              name="delete"
                              size={20}
                              color={colors.app.e}
                              onPress={() => {
                                setIsDeleteModal((prev) => ({
                                  ...prev,
                                  visible: true,
                                  text: "Are you sure you want to delete the address ?",
                                  id: userBillingAddress?.id,
                                  functionParam: "delete-billing",
                                  onConfirmText: "Delete",
                                }));
                              }}
                            />
                          </View>
                        </View>
                      </View>
                    ) : !userBillingAddress && !isLoading ? (
                      <View className="flex-col w-full">
                        <View className="flex-row w-full justify-center">
                          <Font className="text-app-d1">
                            No address added yet.
                          </Font>
                        </View>
                        <View className="flex-row w-full pt-4">
                          <Button
                            label="Add Billing Address"
                            type="primary"
                            onPress={() => {
                              navigation.navigate("update-address", {
                                id: null,
                                is_billing: true,
                                addressState: "add",
                              });
                            }}
                          ></Button>
                        </View>
                      </View>
                    ) : (
                      <View className="flex-1 w-full justify-center h-40">
                        <ActivityIndicator
                          size={"small"}
                          color={colors.app.e}
                        ></ActivityIndicator>
                      </View>
                    )}
                  </View>
                </Card>
              </View>
              <View className=" w-full ">
                {console.log(userAddressList, "userAddressList")}
                <Card title="Select Your Shipping Address">
                  <View className="px-4 w-full">
                    {userAddressList?.length > 0 && !isUserLoading ? (
                      userAddressList?.map((e, i) => (
                        <View key={`addresslist_${i}`}>
                          <View className="flex-row w-full items-start border-b border-gray-200">
                            <View
                              className={`pr-4 h-4 ${
                                i === 0 ? "pb-4" : "py-4"
                              }`}
                            >
                              <Input
                                type="checkbox"
                                label=""
                                value={e?.is_default}
                                setValue={() =>
                                  onAddressAction(e.id, "set-default")
                                }
                              ></Input>
                            </View>
                            <Pressable
                              className={`flex-1 ${i === 0 ? "pb-4" : "py-4"}`}
                              // onPress={() =>
                              //   navigation.navigate("update-address", { id: e.id })
                              // }
                            >
                              {/* <Font className="text-app-d" weight={700}>
                                {e.recepientName}
                              </Font>{" "} */}
                              <Font className="text-app-d" weight={600}>
                                {e?.address?.title ?? "--"}
                              </Font>
                              <Font className="text-app-d my-2" weight={500}>
                                {e?.address?.phone}
                              </Font>
                              <Font className="text-app-d" weight={500}>
                                {e?.address?.locality}
                                {`, ${e?.address?.city}`}
                                {`, ${e?.address?.state}`}
                                {`, ${e?.address?.country}`}
                              </Font>

                              <View className="w-full  ">
                                <Pressable
                                  onPress={() => {
                                    setIsDeleteModal((prev) => ({
                                      ...prev,
                                      visible: true,
                                      text: "Set Address as Billing Address ?",
                                      id: e.id,
                                      functionParam: "make-billing",
                                      onConfirmText: "Set",
                                    }));
                                  }}
                                  className={`md:hidden w-[150px] my-1`}
                                >
                                  <Font
                                    className={
                                      " text-[white] bg-red-600 rounded-lg p-1"
                                    }
                                    weight={600}
                                    style={{ color: "white" }}
                                  >
                                    Make Billing Address
                                  </Font>
                                </Pressable>
                              </View>
                            </Pressable>
                            <Pressable
                              onPress={() => {
                                setIsDeleteModal((prev) => ({
                                  ...prev,
                                  visible: true,
                                  text: "Set Address as Billing Address ?",
                                  id: e.id,
                                  functionParam: "make-billing",
                                  onConfirmText: "Set",
                                }));
                              }}
                              className={`hidden md:flex pr-3 ${
                                i === 0 ? "pb-4" : "py-4"
                              }`}
                            >
                              <Font
                                className={
                                  " text-[white] bg-red-600 rounded-lg p-1"
                                }
                                weight={600}
                                style={{ color: "white" }}
                              >
                                Make Billing Address
                              </Font>
                            </Pressable>
                            {/* )} */}
                            <View className="flex-col">
                              <View className="flex-row justify-end">
                                <View
                                  className={`pr-3 ${
                                    i === 0 ? "pb-4" : "py-4"
                                  }`}
                                >
                                  <Feather
                                    name="edit"
                                    size={20}
                                    color={colors.app.g}
                                    onPress={async () => {
                                      await AsyncStorage.setItem(
                                        "shipping",
                                        JSON.stringify(e?.address)
                                      );
                                      navigation.navigate("update-address", {
                                        id: e?.id,
                                        addressState: "edit",
                                        data: e?.address,
                                        is_billing: false
                                      });
                                    }}
                                  />
                                </View>
                                <View className={i === 0 ? "pb-4" : "py-4"}>
                                  <AntDesign
                                    name="delete"
                                    size={20}
                                    color={colors.app.e}
                                    onPress={
                                      () => {
                                        setIsDeleteModal((prev) => ({
                                          ...prev,
                                          visible: true,
                                          text: "Are you sure you want to delete the address ?",
                                          id: e.id,
                                          functionParam: "delete-shipping",
                                          onConfirmText: "Delete",
                                        }));
                                      }
                                      // onAddressAction(e.id, "delete-shipping")
                                    }
                                  />
                                </View>
                              </View>
                            </View>
                          </View>
                        </View>
                      ))
                    ) : userAddressList.length === 0 && !isUserLoading ? (
                      <View className="flex-1 w-full">
                        <Font className="text-app-d1">
                          No address added yet.
                        </Font>
                      </View>
                    ) : (
                      <View className="flex-1 w-full justify-center h-40">
                        <ActivityIndicator
                          size={"small"}
                          color={colors.app.e}
                        ></ActivityIndicator>
                      </View>
                    )}
                  </View>
                </Card>
                <View className="flex-1 w-full pt-4  mb-8 ">
                  <Button
                    label="Add Shipping Address"
                    type="primary"
                    onPress={() => {
                      navigation.navigate("update-address", {
                        id: null,
                        addressState: "add",
                      });
                    }}
                  ></Button>
                </View>
              </View>
            </View>
            <View className="hidden lg:flex w-full lg:w-2/6 p-0 lg:pl-4">
              <Card title="SMBEx">
                <View className="flex-1 w-full p-4 pt-0">
                  <Font>
                    We collaboratively administrate turnkey channels where
                    kingdom businesses can become virtual retailers. We provide
                    scalable metrics, proactive e-services and objective metrics
                    for small businesses to thrive in any economic environment.
                  </Font>
                </View>
              </Card>
              <Card title="Find us there">
                <View className="flex-1 w-full p-4 pt-0">
                  <View className="flex-row items-center">
                    <View className="h-16 w-16 items-center justify-center">
                      <RemixIcon
                        name="phone-fill"
                        size={40}
                        color={colors.app.d1}
                      ></RemixIcon>
                    </View>
                    <View>
                      <Font>Phone number</Font>
                      <Font className="text-app-e">1 877 963-5797</Font>
                    </View>
                  </View>
                  <View className="flex-row items-center">
                    <View className="h-16 w-16 items-center justify-center">
                      <RemixIcon
                        name="global-fill"
                        size={40}
                        color={colors.app.d1}
                      ></RemixIcon>
                    </View>
                    <View>
                      <Font>Website</Font>
                      <Font className="text-app-e">www.SMBEx.com</Font>
                    </View>
                  </View>
                  <View className="flex-row items-center">
                    <View className="h-16 w-16 items-center justify-center">
                      <RemixIcon
                        name="mail-fill"
                        size={40}
                        color={colors.app.d1}
                      ></RemixIcon>
                    </View>
                    <View>
                      <Font>Email Address</Font>
                      <Font className="text-app-e">office@SMBEx.com</Font>
                    </View>
                  </View>
                </View>
              </Card>
            </View>
          </View>
          <Footer></Footer>
        </View>
      </ScrollView>
    </View>
  );
};
