import {
  Image,
  Platform,
  Pressable,
  ScrollView,
  TextInput,
  TouchableOpacity,
  View,
  ActivityIndicator,
  FlatList,
  useWindowDimensions,
} from "react-native";
import React, { useContext, useLayoutEffect } from "react";
import { Card } from "../../components/card";
import { Font } from "../../components/font";
import { ResponseModal } from "../../components/response-modal";
import SVGLoader from "../../../utils/SVGLoader";
import search from "../../../assets/search.svg";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import GroupSettings from "./groups-settings";
import { getRecommendedGroups, joinOrRequestGroup } from "../../services/api";
import { RefreshControl } from "react-native";

import { UserContext } from "../../context/userContext";
import { AuthContext } from "../../context/authContext";
import {
  AcceptOrDeclineInvitationApi,
  GetGroupsApi,
  GroupInvitationApi,
} from "../../services/api";
import { PleaseWaitModal } from "../../components/modals";
import GroupNavigations from "../../components/group-components/GroupNavigations";
import { useGroupsContext } from "../../context/groupsContext";
import { mediaURI } from "../../../constants";
import moment from "moment";

const Groups = ({ navigation, route }) => {
  const { width } = useWindowDimensions();
  const { user, userToken } = useContext(AuthContext);
  const { userData } = useContext(UserContext);
  const {
    groupTabStatus,
    setGroupTabStatus,
    memberGroups,
    setMemberGroups,
    adminGroups,
    setAdminGroups,
    groups,
    setGroups,
    currentGroupInfo,
    setCurrentGroupInfo,
  } = useGroupsContext();

  const [isGroupTabVisible, setIsGroupTabVisible] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isReLoading, setIsReLoading] = React.useState(false);
  const [isRefreshList, setRefreshList] = React.useState(true);
  const [isResponseModal, setIsResponseModal] = React.useState({
    visible: false,
    message: "",
  });
  const timeAgo = (updatedAt) => moment(updatedAt).fromNow();
  const [recommendedGroupsList, setRecommendedGroupsList] = React.useState([]);
  const [InvitesList, setInvitesList] = React.useState([]);
  const [showResponseModal, setShowResponseModal] = React.useState({
    visible: false,
    onRequestClose: () => null,
    bodyText: "",
  });

  useLayoutEffect(() => {
    if (route.params?.tab)
      setGroupTabStatus({
        curr: route.params?.tab,
        prev: groupTabStatus.curr,
      });
  }, [route.params?.tab]);

  const checkRefreshList = () => {
    setRefreshList(!isRefreshList);
  };
  const closeResponseModal = () => {
    setIsResponseModal((prev) => ({ ...prev, visible: false }));
  };

  const loadRecommendedGroups = async ({ searchText } = {}) => {
    try {
      setIsLoading(true);
      const res = await getRecommendedGroups({
        token: userToken,
        user_id: userData.id,
        searchText,
        limit: undefined,
        offset: 0,
      });

      if (res?.status === 200) {
        setRecommendedGroupsList(res.data?.data || []);
      } else if (res.data?.message) {
        setShowResponseModal((prev) => ({
          ...prev,
          visible: true,
          bodyText: res.data.message,
        }));
      } else {
        console.log("loadRecommendedGroups response:", res.status, res.data);
      }
    } catch (error) {
      console.log("error?.code:", error?.code, error);
    } finally {
      setIsLoading(false);
    }
  };
  const loadJoinedGroups = async () => {
    try {
      // setIsLoading(true);
      const res = await GetGroupsApi({ token: userToken, id: userData.id });
      if (res?.status == 200) {
        setGroups({ data: res.data?.data || [] });
        const members = [];
        const admins = [];
        res.data?.data?.forEach((group) => {
          group?.members?.forEach((member) => {
            if (member.group_role === "member") {
              members.push(group);
            } else if (member.group_role === "admin") {
              admins.push(group);
            }
          });
        });
        setMemberGroups({ data: members });
        setAdminGroups({ data: admins });
      } else if (res.data?.message) {
        setShowResponseModal((prev) => ({
          ...prev,
          visible: true,
          bodyText: res.data.message,
        }));
      } else {
        console.log("loadJoinedGroups response:", res.status, res.data);
      }
    } catch (error) {
      // setIsLoading(false);
      console.log("error?.code:", error, error);
    } finally {
      setIsLoading(false);
    }
  };

  const joinGroup = async ({ group_id } = {}) => {
    try {
      setIsLoading(true);
      const res = await joinOrRequestGroup({
        token: userToken,
        group_id,
      });

      if (res?.status === 200 || res?.status === 201) {
        if (res?.status === 200)
          setShowResponseModal((prev) => ({
            ...prev,
            visible: true,
            bodyText: res.data.message,
          }));
      } else if (res.data?.message) {
        setShowResponseModal((prev) => ({
          ...prev,
          visible: true,
          bodyText: res.data.message,
        }));
      } else {
        console.log("joinGroup response:", res.status, res.data);
      }
      if (res.status === 200 || res.status === 201 || res.status === 409) {
        setRecommendedGroupsList((prevData) => {
          const index = prevData.findIndex((i) => i.id === group_id);
          if (index > -1) {
            prevData.splice(index, 1);
          }
          return [...prevData];
        });
      }
    } catch (error) {
      console.log("error?.code:", error?.code, error);
      setShowResponseModal((prev) => ({
        ...prev,
        visible: true,
        bodyText: error.message,
      }));
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    const t = setTimeout(
      () => loadRecommendedGroups({ searchText: searchTerm || undefined }),
      500
    );
    return () => clearTimeout(t);
  }, [searchTerm]);

  const acceptRequestOfUser = (groupId, isPrivate) => {
    joinGroup({ group_id: groupId, is_private: isPrivate });
  };

  const getGroupInvitations = async () => {
    try {
      const res = await GroupInvitationApi(userToken, userData.id);
      if (res.status == 200) {
        setInvitesList(res.data?.data || []);
      } else if (res.data?.message) {
        setShowResponseModal((prev) => ({
          ...prev,
          visible: true,
          bodyText: res.data.message,
        }));
      } else {
        console.log("getGroupInvitations response:", res.status, res.data);
      }
    } catch (error) {
      console.log(error, " getGroupInvitations error catch");
    }
  };
  const AcceptOrDeclineInvitations = async (
    inviteId,
    groupId,
    AcceptOrDecline
  ) => {
    try {
      const res = await AcceptOrDeclineInvitationApi(
        userToken,
        inviteId,
        groupId,
        AcceptOrDecline
      );
      if (res.status == 200) {
        setInvitesList((prevList) =>
          prevList.filter((member) => member.id !== inviteId)
        );
        console.log(res.data, res.status, "AcceptOrDeclineInvitations success");
        loadJoinedGroups();
      } else if (res.data?.message) {
        setShowResponseModal((prev) => ({
          ...prev,
          visible: true,
          bodyText: res.data.message,
        }));
      } else {
        console.log("AcceptOrDeclineInvitations error");
      }
    } catch (error) {
      console.log(error, " AcceptOrDeclineInvitations error catch");
    }
  };

  const [searchTextAdminGroup, setSearchTextAdminGroup] = React.useState("");
  const [filteredAdminGroups, setFilteredAdminGroups] =
    React.useState(adminGroups);

  React.useEffect(() => {
    const filtered = adminGroups?.filter((group) =>
      group?.name
        ?.toLowerCase()
        .includes(String(searchTextAdminGroup).toLowerCase())
    );
    setFilteredAdminGroups(filtered);
  }, [searchTextAdminGroup, adminGroups]);

  console.log(memberGroups, adminGroups, "member groups admin groups");

  const [searchTextMemberGroup, setSearchTextMemberGroup] = React.useState("");
  const [filteredMemberGroups, setFilteredMemberGroups] =
    React.useState(memberGroups);

  React.useEffect(() => {
    const filtered = memberGroups?.filter((group) =>
      group?.name
        ?.toLowerCase()
        .includes(String(searchTextMemberGroup).toLowerCase())
    );
    setFilteredMemberGroups(filtered);
  }, [searchTextMemberGroup, memberGroups]);





  React.useEffect(() => {
    if ((!adminGroups || !memberGroups) && !groups) loadJoinedGroups();
  }, [adminGroups, memberGroups, groups]);

  React.useEffect(() => {
    getGroupInvitations();
  }, [isRefreshList]);

  return (
    <View className="flex-1  lg:bg-gray-50">
      {isLoading && groupTabStatus.curr !== "For you" && (
        <PleaseWaitModal showLoader={true} />
      )}
      <ResponseModal
        visible={isResponseModal.visible}
        onRequestClose={closeResponseModal}
        bodyText={isResponseModal.message}
      ></ResponseModal>

      <ScrollView
        className="py-4 px-0 rounded-t-2xl "
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
        refreshControl={
          Platform.OS === "web" ? null : (
            <RefreshControl
              refreshing={isReLoading}
              onRefresh={() => {
                setIsReLoading(true);
                if (groupTabStatus.curr === "For you") {
                  loadRecommendedGroups({
                    searchText: searchTerm || undefined,
                  });
                }
                if (groupTabStatus.curr === "Your groups") {
                  getGroupInvitations();
                  loadJoinedGroups();
                }
                if (groupTabStatus.curr === "Settings") {
                  loadJoinedGroups();
                }
                setTimeout(() => setIsReLoading(false), 1000);
              }}
            />
          )
        }
      >
        <View className=" max-w-screen-xl w-full py-2 h-full ">
          <View className="flex flex-col lg:flex-row max-w-screen-xl lg:justify-between w-full">
            <View className="flex flex-col lg:flex-row max-w-screen-xl w-full lg:w-[23%]">
              <GroupNavigations
                adminList={adminGroups?.slice(0, 4)}
                memberList={memberGroups?.slice(0, 4)}
                groupTab={groupTabStatus.curr}
                setGroupTab={(value) => {
                  setGroupTabStatus({ curr: value, prev: groupTabStatus.curr });
                  navigation.setParams({ tab: value });
                  if (isGroupTabVisible) setIsGroupTabVisible(false);
                }}
                showTopTabs={true}
                checkRefreshList={checkRefreshList}
              />
            </View>
            {/* second column for web */}

            <View className="flex max-w-screen-xl lg:w-[73%] xl:w-[77%]  py-0 h-full px-4">
              <View className="hidden lg:flex mt-0 max-w-screen-lg px-0 bg-app-e rounded-xl mb-4">
                <View className=" w-full flex  flex-row py-2 mb-8  items-center  h-full">
                  <View className="flex-col justify-center items-center w-[5%] h-full ">
                    <Pressable
                      className="p-3   justify-center items-center  active:bg-app-c2 active:bg-opacity-10  "
                      onPress={() => {
                        if (navigation.canGoBack()) navigation.goBack();
                        else navigation.navigate("home");
                        if (isGroupTabVisible) setIsGroupTabVisible(false);
                      }}
                      style={{ marginTop: Platform.OS === "ios" ? 22 : 0 }}
                    >
                      <RemixIcon
                        name="ri-arrow-left-s-line"
                        size={18}
                        color="white"
                        className="mr-2 lg:mr-4 w-4 h-4 lg:w-6 lg:h-6 flex-shrink-0"
                      />
                    </Pressable>
                  </View>
                  <View className="w-[90%]   justify-center  items-center flex-row px-2  ">
                    <View className="w-full items-center ">
                      <View className="w-20 h-20 rounded-full overflow-hidden border border-app-c3">
                        <Image
                          source={
                            userData?.profile_image_url
                              ? { uri: mediaURI + userData?.profile_image_url }
                              : require("../../../assets/user.jpg")
                          }
                          className="w-full h-full"
                        ></Image>
                      </View>
                      <View className="items-center py-2">
                        <Font className="text-app-a pt-2 pb-1" weight={700}>
                          {(
                            (userData?.first_name || "") +
                            " " +
                            (userData?.last_name || "")
                          ).trim() || "Hello, User"}
                        </Font>
                        <Font
                          className="text-app-a1  lg:text-app-a"
                          weight={500}
                        >
                          {userData?.email}
                        </Font>
                      </View>
                      <Font
                        style={{ marginTop: Platform.OS === "ios" ? 22 : 0 }}
                        className="text-app-a lg:text-[white] font-semibold text-[15px]  px-2"
                      >
                        Groups
                      </Font>
                    </View>
                  </View>
                  {/* <View className="flex-col justify-center items-center w-[5%] h-full ">
                    <Pressable
                      className="p-3   justify-center items-center  active:bg-app-c2 active:bg-opacity-10  "
                      style={{ marginTop: Platform.OS === "ios" ? 22 : 0 }}
                    >
                      <RemixIcon
                        name="ri-settings-5-fill"
                        size={16}
                        color="white"
                        className="mr-2 lg:mr-4 w-4 h-4 lg:w-6 lg:h-6 flex-shrink-0"
                      />
                    </Pressable>
                  </View> */}
                </View>
              </View>
              <View className="mb-5 max-w-screen-lg flex flex-row flex-wrap justify-center items-center">
                {groupTabStatus.curr === "For you" ? (
                  <View className="flex flex-col justify-center items-center py-4 w-full">
                    <View className="px-4 mb-4 w-full max-w-lg flex-row bg-white border border-gray-200 rounded-full shadow-sm">
                      <View className="flex-row justify-between items-center w-full">
                        <TextInput
                          className="w-full pr-5"
                          placeholder="...Search"
                          placeholderTextColor="#94a3b8"
                          style={{ outline: "none" }}
                          type="text"
                          onChangeText={setSearchTerm}
                        />
                        <View className="-ml-2 flex-row justify-center items-center">
                          <SVGLoader
                            src={search}
                            height={20}
                            width={20}
                            webHeight={"2em"}
                            webWidth={"auto"}
                          />
                        </View>
                      </View>
                    </View>
                    <View className="flex flex-col  flex-1 lg:flex-row flex-wrap overflow-visible">
                      {/** For You Native Mobile App */}
                      {isLoading ? (
                        <ActivityIndicator
                          className="self-center justify-self-center w-full"
                          color={colors.app.e}
                          size="small"
                        />
                      ) : !recommendedGroupsList?.length ? (
                        <View className="py-4 flex-col justify-center items-center">
                          <Font className="text-app-d1">
                            No groups recommended
                          </Font>
                        </View>
                      ) : (
                        <FlatList
                          className="w-full h-auto"
                          key={Date.now()}
                          data={recommendedGroupsList}
                          contentContainerStyle={{
                            width: width > 1023 ? "auto" : width - 40,
                          }}
                          numColumns={width > 1439 ? 4 : width > 1023 ? 4 : 1}
                          renderItem={({ item: e, index: i }) => (
                            <View
                              className="bg-white py-2 lg:py-4 border border-gray-200 rounded-xl shadow-lg shadow-gray-300 "
                              key={i}
                              style={{
                                marginEnd: width > 1023 ? 5 : 0,
                                marginBottom: 5,
                              }}
                            >
                              <View className=" flex-row lg:flex-col w-full ">
                                <Pressable
                                  className="px-2 flex-col lg:justify-center lg:items-center flex-1"
                                  onPress={() => {
                                    //   setIsSelected(!isSelected);
                                    navigation.navigate("view-groups", {
                                      id: e?.id,
                                      name: e?.name,
                                      description: e?.description,
                                      icon_cover_image_url:
                                        e?.icon_cover_image_url,
                                      background_cover_image_url:
                                        e?.background_cover_image_url,
                                      isPrivate: e?.is_private,
                                      checkRefreshList: checkRefreshList,
                                      noOfPosts: e?.posts_per_day || 0,
                                      members: e?.member_count,
                                      createdOn: e?.created_at,
                                    });
                                  }}
                                >
                                  {/* <View className="flex justify-center items-center"> */}
                                  <Font className=" text-base font font-bold flex-row flex">
                                    {e?.name}
                                    <View className="items-center flex-row">
                                      <View>
                                        <RemixIcon
                                          name="ri-checkbox-blank-circle-fill"
                                          size={4}
                                          color="#000000"
                                          className=" ml-2 mr-1  lg:mt-0  flex-shrink-0 justify-center items-center"
                                        />
                                      </View>
                                      <View>
                                        <RemixIcon
                                          name={
                                            e?.is_private
                                              ? `ri-lock-fill`
                                              : `ri-earth-fill`
                                          }
                                          size={14} // or use Tailwind text-size class
                                          color="#000000"
                                        />
                                      </View>
                                    </View>
                                  </Font>
                                  <View className="flex-row items-center">
                                    <Font className="text-xs text-gray-400 mb-0">
                                      {e?.member_count || 0} members
                                    </Font>
                                    <RemixIcon
                                      name="ri-checkbox-blank-circle-fill"
                                      size={4}
                                      color="#9ca3af"
                                      className="mx-2 lg:mt-0 flex-shrink-0 justify-center items-center"
                                    />
                                    <Font className="text-xs text-gray-400">
                                      {e?.posts_per_day || 0}+ post a day
                                    </Font>
                                  </View>
                                  {/* </View> */}
                                </Pressable>
                                <View className="-mr-1 lg:mr-0 flex-row justify-center items-center w-[30%] lg:w-full lg:py-2">
                                  <Pressable
                                    onPress={() =>
                                      acceptRequestOfUser(e?.id, e?.is_private)
                                    }
                                    className={`p-1 mr-0 lg:mr-2 flex justify-center items-center border ${
                                      e?.is_private
                                        ? "bg-blue-100 border-blue-600"
                                        : "bg-green-100 border-green-600"
                                    }  rounded-lg`}
                                  >
                                    <Font
                                      className={` text-xs px-2 ${
                                        e?.is_private
                                          ? "text-blue-600 "
                                          : "text-green-600 "
                                      }`}
                                      style={{ fontSize: 10 }}
                                    >
                                      {e?.is_private ? "Ask To Join" : "Join"}
                                    </Font>
                                  </Pressable>
                                </View>
                              </View>
                            </View>
                          )}
                        />
                      )}
                    </View>
                  </View>
                ) : groupTabStatus.curr === "Your groups" ? (
                  <>
                    <View className="w-full p-0 pt-5">
                      <Card className="lg:flex-1">
                        <View className="flex-row justify-between w-full px-3">
                          <Font className="font-medium ">Invites to Join</Font>
                          {InvitesList.length > 3 ? (
                            <Font className="text-app-e">See all</Font>
                          ) : (
                            <></>
                          )}
                        </View>
                        <View className=" border-b border-gray-300 w-[93%] my-2"></View>
                        {!InvitesList.length ? (
                          <View className="py-4 flex-col justify-center items-center">
                            <Font className="text-app-d1">No Invites</Font>
                          </View>
                        ) : (
                          InvitesList.map((e, i) => (
                            <TouchableOpacity
                              className="px-2 py-0 flex-row justify-between w-full my-1"
                              key={"InvitesList" + i}
                              // onPress={() => navigation.navigate("add-member")}
                            >
                              <View className="flex-row justify-start items-center w-[70%] ">
                                <View className="flex-col">
                                  <Font className=" text-base font font-bold">
                                    {e?.group?.name}
                                    <View
                                      style={{
                                        flexDirection: "row",
                                        alignItems: "center",
                                        width: 12,
                                        height: 12,
                                      }}
                                    >
                                      <View>
                                        <RemixIcon
                                          name="ri-checkbox-blank-circle-fill"
                                          size={4}
                                          color="#000000"
                                          className=" ml-2 mr-1  lg:mt-0  flex-shrink-0 justify-center items-center"
                                        />
                                      </View>
                                      <RemixIcon
                                        name={
                                          e?.group?.is_private
                                            ? `ri-lock-fill`
                                            : `ri-earth-fill`
                                        }
                                        size={12} // or use Tailwind text-size class
                                        color="#000000"
                                      />
                                    </View>
                                  </Font>
                                  <Font className="text-xs text-gray-300 mb-0">
                                    {e?.member_count} members{"  "}
                                    <Font className="text-xs">
                                      {e?.posts_per_day}+ Post a day
                                    </Font>
                                    {/* <Font className="text-sm">members</Font> */}
                                  </Font>
                                </View>
                              </View>
                              <View className=" lg:mr-0 flex-row justify-center items-center w-[30%] ">
                                <Pressable
                                  onPress={() =>
                                    AcceptOrDeclineInvitations(
                                      e?.id,
                                      e?.group?.id,
                                      "accept"
                                    )
                                  }
                                  className="px-1 mr-1 lg:mr-2 flex justify-center items-center bg-green-200 border border-green-600 rounded-md"
                                >
                                  <Font
                                    className="text-xs text-green-600"
                                    style={{ fontSize: 10 }}
                                  >
                                    Accept
                                  </Font>
                                </Pressable>
                                <Pressable
                                  onPress={() =>
                                    AcceptOrDeclineInvitations(
                                      e?.id,
                                      e?.group?.id,
                                      "reject"
                                    )
                                  }
                                  className="px-1 flex justify-center items-center bg-red-200 border border-app-e rounded-md"
                                >
                                  <Font
                                    className="text-xs text-app-e"
                                    style={{ fontSize: 10 }}
                                  >
                                    Decline
                                  </Font>
                                </Pressable>
                              </View>
                            </TouchableOpacity>
                          ))
                        )}
                      </Card>
                    </View>
                    <View className="w-full p-0 pt-0">
                      <Card
                        className="lg:flex-1 "
                        //  key={i}
                      >
                        <View className="flex-row justify-between w-full px-3">
                          <Font className="font-medium ">
                            Groups you are admin
                          </Font>
                          {/* {adminGroups?.length > 3 ? (
                            <Font className="text-app-e">See all</Font>
                          ) : (
                            <></>
                          )} */}
                          {/* <Font className="text-app-e">See all</Font> */}
                        </View>
                        <View className=" border-b border-gray-300 w-[93%] my-3"></View>
                        {!adminGroups?.length ? (
                          <View className="py-4 flex-col justify-center items-center">
                            <Font className="text-app-d1">No groups found</Font>
                          </View>
                        ) : (
                          <View className=" mb-4 px-4 w-[93%] flex-row bg-white border border-gray-200 rounded-full shadow-sm">
                            <View className="flex-row justify-between items-center w-full">
                              <TextInput
                                className="w-[90%] pr-5"
                                placeholder="...Search"
                                placeholderTextColor="#94a3b8"
                                type="text"
                                style={{ outlineStyle: "none" }}
                                value={searchTextAdminGroup}
                                onChangeText={(text) =>
                                  setSearchTextAdminGroup(text)
                                }
                              />
                              <View className="-ml-2 flex-row justify-center items-center">
                                <SVGLoader
                                  src={search}
                                  height={20}
                                  width={20}
                                  webHeight={"2em"}
                                  webWidth={"auto"}
                                />
                              </View>
                            </View>
                          </View>
                        )}
                        {filteredAdminGroups?.map((e, i) => (
                          <TouchableOpacity
                            className="px-2 py-0 flex-row justify-between w-full my-0"
                            key={"adminGroups" + i}
                            // onPress={() => navigation.navigate("add-member")}
                          >
                            <View
                              className="bg-white mb-2  py-1 w-full border border-gray-200 rounded-xl shadow-lg shadow-gray-300 "
                              key={i}
                            >
                              <View className=" flex-row  w-full ">
                                <Pressable
                                  className="px-2 flex-col justify-start w-[70%] "
                                  onPress={() => {
                                    //   setIsSelected(!isSelected);
                                    setGroupTabStatus({
                                      curr: undefined,
                                      prev: groupTabStatus.curr,
                                    });
                                    navigation.navigate("group-details", {
                                      tab: undefined,
                                      id: e?.id,
                                      checkRefreshList: checkRefreshList,
                                    });
                                    setCurrentGroupInfo({
                                      id: e?.id,
                                      name: e?.name || "",
                                      description: e?.description || "",
                                      isPrivate: e?.is_private,
                                      noOfPosts: e?.noOfPosts,
                                      members: e?.members,
                                      userToken: userToken,
                                      isAdmin: true,
                                      icon_cover_image_url:
                                        e?.icon_cover_image_url,
                                    });
                                  }}
                                >
                                  {/* <View className="flex justify-center items-center"> */}
                                  <Font className=" text-base font font-bold flex flex-row">
                                    {e?.name}
                                    <View className="flex-row items-center lg:flex">
                                      <View>
                                        <RemixIcon
                                          name="ri-checkbox-blank-circle-fill"
                                          size={4}
                                          color="#000000"
                                          className=" mx-1  lg:mt-0  flex-shrink-0 justify-center items-center flex"
                                        />
                                      </View>
                                      <View>
                                        <RemixIcon
                                          className="flex"
                                          name={
                                            e?.is_private
                                              ? `ri-lock-fill`
                                              : `ri-earth-fill`
                                          }
                                          size={12} // or use Tailwind text-size class
                                          color="#000000"
                                        />
                                      </View>
                                    </View>
                                  </Font>
                                  <Font className="text-xs text-gray-300 mb-0">
                                    {e?.member_count + " |"}
                                    <Font className="text-xs"> Members</Font>
                                    {/* <Font className="text-sm">members</Font> */}
                                  </Font>
                                  {/* </View> */}
                                </Pressable>
                                <View className="-mr-2.5 lg:mr-0 flex-row justify-center items-center w-[30%]">
                                  <Pressable
                                    // onPress={() => acceptRequestOfUser(e?.id)}
                                    className={`p-1 mr-0 lg:mr-2 flex justify-center items-center rounded-lg`}
                                  >
                                    <Font
                                      className="text-gray-300 text-xs px-2 "
                                      style={{ fontSize: 10 }}
                                    >
                                      Created {timeAgo(e?.created_at)}
                                    </Font>
                                  </Pressable>
                                </View>
                              </View>
                            </View>
                          </TouchableOpacity>
                        ))}
                      </Card>
                    </View>
                    <View className="w-full p-0 pt-0">
                      <Card
                        className="lg:flex-1 "
                        //  key={i}
                      >
                        <View className="flex-row justify-between w-full px-3">
                          <Font className="font-medium ">
                            Groups you are member
                          </Font>
                          {/* {memberGroups?.length > 3 ? (
                            <Font className="text-app-e">See all</Font>
                          ) : (
                            <></>
                          )} */}
                          {/* <Font className="text-app-e">See all</Font> */}
                        </View>
                        <View className=" border-b border-gray-300 w-[93%] my-3"></View>
                        {memberGroups?.length == 0 ? (
                          <View className="py-4 flex-col justify-center items-center">
                            <Font className="text-app-d1">No groups found</Font>
                          </View>
                        ) : (
                          <View className=" mb-4 px-4 w-[93%] flex-row bg-white border border-gray-200 rounded-full shadow-sm">
                            <View className="flex-row justify-between items-center w-full">
                              <TextInput
                                className="w-[90%] pr-5"
                                placeholder="...Search"
                                placeholderTextColor="#94a3b8"
                                type="text"
                                style={{ outlineStyle: "none" }}
                                value={searchTextMemberGroup}
                                onChangeText={(text) =>
                                  setSearchTextMemberGroup(text)
                                }
                              />
                              <View className="-ml-2 flex-row justify-center items-center">
                                <SVGLoader
                                  src={search}
                                  height={20}
                                  width={20}
                                  webHeight={"2em"}
                                  webWidth={"auto"}
                                />
                              </View>
                            </View>
                          </View>
                        )}
                        {filteredMemberGroups?.map((e, i) => (
                          <TouchableOpacity
                            className="px-2 py-0 flex-row justify-between w-full my-0"
                            key={"memberGroups" + i}
                            // onPress={() => navigation.navigate("add-member")}
                          >
                            <View
                              className="bg-white mb-2  py-1 w-full border border-gray-200 rounded-xl shadow-lg shadow-gray-300 "
                              key={i}
                            >
                              <View className=" flex-row  w-full ">
                                <Pressable
                                  className="px-2 flex-col justify-start w-[70%] "
                                  onPress={() => {
                                    //   setIsSelected(!isSelected);
                                    setGroupTabStatus({
                                      curr: undefined,
                                      prev: groupTabStatus.curr,
                                    });
                                    navigation.navigate("group-details", {
                                      tab: undefined,
                                      id: e?.id,
                                      checkRefreshList: checkRefreshList,
                                    });
                                    setCurrentGroupInfo({
                                      id: e?.id,
                                      name: e?.name || "",
                                      description: e?.description || "",
                                      isPrivate: e?.is_private,
                                      noOfPosts: e?.noOfPosts,
                                      members: e?.members,
                                      icon_cover_image_url:
                                        e?.icon_cover_image_url,
                                      isAdmin: false,
                                    });
                                  }}
                                >
                                  {/* <View className="flex justify-center items-center"> */}
                                  <Font className=" text-base font font-bold flex flex-row">
                                    {e?.name}
                                    <View className="flex-row items-center lg:flex">
                                      <View>
                                        <RemixIcon
                                          name="ri-checkbox-blank-circle-fill"
                                          size={4}
                                          color="#000000"
                                          className=" mx-1  lg:mt-0  flex-shrink-0 justify-center items-center flex"
                                        />
                                      </View>
                                      <View>
                                        <RemixIcon
                                          className="flex"
                                          name={
                                            e?.is_private
                                              ? `ri-lock-fill`
                                              : `ri-earth-fill`
                                          }
                                          size={12} // or use Tailwind text-size class
                                          color="#000000"
                                        />
                                      </View>
                                    </View>
                                  </Font>
                                  <Font className="text-xs text-gray-300 mb-0">
                                    {e?.member_count + " |"}
                                    <Font className="text-xs"> Members</Font>
                                    {/* <Font className="text-sm">members</Font> */}
                                  </Font>
                                  {/* </View> */}
                                </Pressable>
                                <View className="-mr-2.5 lg:mr-0 flex-row justify-center items-center w-[30%]">
                                  <Pressable
                                    // onPress={() => acceptRequestOfUser(e?.id)}
                                    className={`p-1 mr-0 lg:mr-2 flex justify-center items-center rounded-lg`}
                                  >
                                    <Font
                                      className="text-gray-300 text-xs px-2 "
                                      style={{ fontSize: 10 }}
                                    >
                                      Created {timeAgo(e?.created_at)}
                                    </Font>
                                  </Pressable>
                                </View>
                              </View>
                            </View>
                          </TouchableOpacity>
                        ))}
                      </Card>
                    </View>
                  </>
                ) : groupTabStatus.curr === "Settings" ? (
                  <GroupSettings
                    navigation={navigation}
                    groupLists={groups || []}
                    userToken={userToken}
                    // groupId={id}
                    checkRefreshList={checkRefreshList}
                    id={user.id}
                    // isPrivate={isPrivate}
                    // groupStatus={groupStatus}
                  />
                ) : (
                  <></>
                )}
              </View>
            </View>
          </View>
          {/* <View className="p-4 max-w-screen-xl w-full flex-col lg:flex-row justify-center items-center"> */}
          <View className="hidden lg:flex p-3" />
        </View>
      </ScrollView>
      {showResponseModal.visible && (
        <ResponseModal
          modalVisible={showResponseModal.visible}
          onPrimaryButtonPress={() => {
            setShowResponseModal((prev) => ({ ...prev, visible: false }));
            showResponseModal.onRequestClose();
          }}
          modalContent={showResponseModal.bodyText}
          primaryButtonLabel={"Okay"}
          setVisible={() =>
            setShowResponseModal((prev) => ({ ...prev, visible: false }))
          }
        />
      )}
    </View>
  );
};

export default Groups;
