import React, { useState, useEffect, useContext } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  CheckBox,
  StyleSheet,
} from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { Card } from "../../components/card";
import { color } from "react-native-reanimated";
import { Button, Input } from "../../components/input";
import { Font } from "../../components/font";
import colors from "../../../colors";
import RemixIcon from "react-native-remix-icon";
import { Footer } from "../../components/footer";
import axios from "axios";
import { DEV_API } from "../../services/api";
import { AuthContext } from "../../context/authContext";
import { Pressable } from "react-native";

export const Contacted = ({ navigation }) => {
  const [showAll, setShowAll] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const { user, userToken } = useContext(AuthContext);
  const [pagination, setPagination] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const limitPerPage = 10;
  const fetchData = async (from, limit) => {
    try {
      const response = await axios.get(
        DEV_API + `/invites?limit=${limitPerPage}&offset=${pagination}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (
        contacts.length + response.data["invites"].length ===
        response.data.total_count
      ) {
        setHasMoreData(false);
      }
      setPagination(contacts.length + response.data["invites"].length);
      setContacts([...contacts, ...response.data["invites"]]);
    } catch (error) {
      console.log("contacted invites error:", error);
    }
    setLoaded(true);
  };

  const renderContacts = () => {
    if (!loaded) {
      return (
        <View style={{ paddingVertical: 30, alignItems: "center" }}>
          <Font
          // style={{ fontSize: 16 }}
          >
            Loading...
          </Font>
        </View>
      );
    } else if (contacts.length === 0) {
      return (
        <View style={{ padding: 30, alignItems: "center" }}>
          <Font>No Contacts</Font>
        </View>
      );
    } else {
      return contacts.map((contact) => (
        <View
          key={contact.id + Date.now()}
          className="py-4 border-b border-gray-200 flex-row items-center w-full "
        >
          <View className="flex-1">
            <Font>{contact.name || "Unknown"}</Font>
            <Font>{contact.mobile || "No Number"}</Font>
            <Font>{contact.email || "No Email"}</Font>
          </View>
          {contact.is_registered ? (
            <></>
          ) : (
            <Text style={styles.warning}>Pending Registration</Text>
          )}
          {/* <View className="w-8">
            {contact.is_registered ? (
              <Input
                setValue={() => {
                  console.log("pending registration");
                }}
                type="checkbox"
                value={true}
              />
            ) : (
              <Input
                setValue={() => {
                  console.log("pending registration");
                }}
                type="checkbox"
                value={false}
              />
            )}
          </View> */}
        </View>
      ));
    }
  };

  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      <ScrollView
        className="p-4 rounded-t-2xl bg-gray-50"
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View className=" max-w-screen-lg w-full mx-auto">
          <View className="my-5 hidden lg:flex">
            <Font className="text-lg font-semibold text-app-d">
              Invited Contacts
            </Font>
          </View>
          <View className="flex-col lg:flex-row max-w-screen-lg w-full">
            <View className=" w-full lg:w-4/6 p-0 lg:pr-4">
              <View className={`w-full mb-4 lg:mb-8 justify-center rounded-lg`}>
                <View className="flex-row pb-4 w-full justify-center sm:justify-end">
                  <Button
                    label="My Contacts"
                    type="primary"
                    className="min-w-max px-8 sm:w-60"
                    onPress={() => navigation.replace("invite-contact")}
                  />
                </View>
                <View
                  className={`bg-white w-full items-center border border-gray-200 rounded-lg pb-4 shadow-lg shadow-gray-300`}
                >
                  <View className=" w-full">
                    <View style={styles.content}>
                      {/* <Text>Contacted List</Text> */}
                      <View>{renderContacts()}</View>
                      {hasMoreData && (
                        <TouchableOpacity onPress={fetchData}>
                          {contacts.length === 0 ? null : (
                            <Text style={{ padding: 10, textAlign: "center" }}>
                              {/* {showAll ? "Show Less " : "Show More "} */}
                              Show More
                            </Text>
                          )}
                        </TouchableOpacity>
                      )}
                      {/* <TouchableOpacity onPress={handleInviteContacts}>
                      <Text>Invite Contact</Text>
                    </TouchableOpacity>
                    <Text>
                      Selected Contacts:{" "}
                      {selectedContacts
                        .map((contact) => contact.name)
                        .join(", ")}
                    </Text> */}
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View className=" flex-1 w-full  lg:w-2/6 p-0 lg:pl-4">
              <Card title="SMBEx">
                <View className="w-full flex-1 p-4 pt-0">
                  <Font>
                    We collaboratively administrate turnkey channels where
                    kingdom businesses can become virtual retailers. We provide
                    scalable metrics, proactive e-services and objective metrics
                    for small businesses to thrive in any economic environment.
                  </Font>
                </View>
              </Card>
              <Card title="Find us there">
                <View className="flex-1 w-full p-4 pt-0">
                  <View className=" w-full flex-1 flex-row items-center">
                    <View className="h-16 w-16 items-center justify-center">
                      <RemixIcon
                        name="phone-fill"
                        size={40}
                        color={colors.app.d1}
                      ></RemixIcon>
                    </View>
                    <View>
                      <Font>Phone number</Font>
                      <Font className="text-app-e">1 877 963-5797</Font>
                    </View>
                  </View>
                  <View className="flex-row items-center">
                    <View className="h-16 w-16 items-center justify-center">
                      <RemixIcon
                        name="global-fill"
                        size={40}
                        color={colors.app.d1}
                      ></RemixIcon>
                    </View>
                    <View>
                      <Font>Website</Font>
                      <Font className="text-app-e">www.SMBEx.com</Font>
                    </View>
                  </View>
                  <View className="flex-row items-center">
                    <View className="h-16 w-16 items-center justify-center">
                      <RemixIcon
                        name="mail-fill"
                        size={40}
                        color={colors.app.d1}
                      ></RemixIcon>
                    </View>
                    <View>
                      <Font>Email Address</Font>
                      <Font className="text-app-e">office@SMBEx.com</Font>
                    </View>
                  </View>
                </View>
              </Card>
            </View>
          </View>
        </View>
        <Footer></Footer>
      </ScrollView>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 10,
  },
  contactContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  checkbox: {
    marginLeft: 10,
    display: "block",
    borderColor: "red",
    // flex:1
  },
  contactName: {
    fontSize: 16,
  },
  contactNumber: {
    marginLeft: 10,
  },
  content: {
    marginLeft: 20,
    marginRight: 20,
  },
  warning: {
    color: "orange",
    textAlign: "center",
    marginRight: 20,
    fontSize: 13,
    marginBottom: 6,
  },
});
