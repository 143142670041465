import React, { useContext, useEffect, useState } from "react";
import {
  Pressable,
  ScrollView,
  View,
  Text,
  TouchableOpacity,
  RefreshControl,
} from "react-native";
import RemixIcon from "react-native-remix-icon";
import { AntDesign } from "@expo/vector-icons";
import colors from "../../../colors";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import { Switcher } from "../../components/input";
import { Widget } from "../../components/widget";
import { Unautorized } from "./unautorized";
import { AuthContext } from "../../context/authContext";
import { getOrders, postCancelOrder } from "../../services/api";
import { OrdersContext } from "../../context/ordersContext";
import { ActivityIndicator } from "react-native";
import { Card } from "../../components/card";
import Toast from "react-native-toast-message";
export const Orders = ({ navigation }) => {
  const { user, userToken } = useContext(AuthContext);
  const [refreshing, setRefreshing] = useState(false);
  const [pagination, setPagination] = useState({ offset: 0, total: 0 });
  const [filter, setFilter] = useState("all");
  const {
    isOrdersLoading,
    orders,
    ordersApiError,
    setIsOrdersLoading,
    setOrders,
    setOrdersApiError,
  } = useContext(OrdersContext);

  const onChangeFilter = (e) => {
    setFilter(e);
  };

  const showToast = (type, line1, line2) => {
    Toast.show({
      type: type,
      text1: line1,
      text2: line2,
    });
  };
  const limitPerPage = 10;
  const getUserOrders = async ({
    push = false,
    pagination: localPagination,
  } = {}) => {
    try {
      setRefreshing(true);
      setIsOrdersLoading(true);
      const res = await getOrders({
        token: userToken,
        filter,
        offset: localPagination?.offset ?? pagination.offset,
        limit: limitPerPage,
      });
      if (res.status === 200) {
        setPagination({
          offset: push
            ? orders?.length - 1
            : localPagination?.offset ?? pagination.offset,
          total: res.data["total_count"],
        });
        if (push) setOrders([...orders, ...res.data["data"]]);
        else setOrders(res.data["data"]);
      } else {
        setOrders([]);
        console.log("orders, getUserOrders res error : ", res.data);
        showToast(
          "error",
          "Bookings error",
          res.data?.message || "Something went wrong on our side."
        );
      }
    } catch (error) {
      setOrders([]);
      console.log("orders, getUserOrders error : ", error);
      showToast(
        "error",
        "Bookings error",
        "Something went wrong on our side." + error?.message
          ? `\n${error.message}`
          : ""
      );
    } finally {
      setRefreshing(false);
      setIsOrdersLoading(false);
    }
  };
  useEffect(() => {
    if (user && userToken !== null) {
      getUserOrders();
    }
  }, [user, userToken, filter]);
  const onCancelOrder = async (id) => {
    try {
      // setRefreshing(true);
      setIsOrdersLoading(true);
      const res = await postCancelOrder({ token: userToken, orderId: id });
      if (res.status === 200) {
        // setOrders(res.data.data);
      } else {
        console.log("orders, onCancelOrder res error : ", res.data);
      }
    } catch (error) {
      console.log("orders, onCancelOrder error : ", error);
    } finally {
      // setRefreshing(false);
      setIsOrdersLoading(false);
    }
  };

  const getOrderDetails = async ({ id }) => {
    navigation.navigate("order-details", { id });
  };

  return user ? (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      <View className="rounded-t-2xl lg:rounded-none overflow-hidden flex-1 bg-gray-50">
        <ScrollView
          className="p-4 rounded-t-2xl "
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={() => {
                getUserOrders({ pagination });
              }}
            />
          }
        >
          <View className="flex-1 w-full justify-center items-center">
            <View className="flex-col w-full lg:w-11/12 lg:px-4">
              <View className="my-5 hidden lg:flex flex-row">
                <Font className="text-lg font-semibold text-app-d flex-1">
                  Orders
                </Font>
              </View>
              <View className="w-full flex-col lg:flex-row">
                <View className=" w-full flex-1">
                  <View className="mb-5">
                    <Switcher
                      options={[
                        { label: "All", value: "all" },
                        // { label: "Pending", value: "created" },
                        { label: "Delivered", value: "delivered" },
                        { label: "Cancelled", value: "cancelled" },
                      ]}
                      value={filter}
                      setValue={(e) => onChangeFilter(e)}
                    ></Switcher>
                  </View>

                  <Card title="Orders">
                    {orders?.length > 0 && !isOrdersLoading ? (
                      <View className="px-4 w-full">
                        {orders.map((e, i) => (
                          <View
                            key={`orders_${i}`}
                            className="flex w-full rounded-xl border overflow-hidden border-gray-200 my-1"
                          >
                            <Pressable
                              className="w-full p-2 flex-1 bg-app-a active:bg-app-c1"
                              onPress={() => getOrderDetails({ id: e.id })}
                            >
                              <View className="flex-row w-full justify-between items-center">
                                <View className="flex-row items-center justify-start">
                                  <View
                                    className="flex-row items-center px-2 py-1 rounded bg-app-c"
                                    style={{
                                      marginVertical: 2,
                                    }}
                                  >
                                    <Font
                                      className="text-xs text-app-d"
                                      weight={600}
                                      numberOfLines={1}
                                    >
                                      #{e.id}
                                    </Font>
                                  </View>
                                  <Font className="text-app-d3 text-xl leading-[22px]">
                                    {" "}
                                    |{" "}
                                  </Font>
                                  <Font
                                    className=" text-app-d1"
                                    weight={500}
                                    numberOfLines={1}
                                  >
                                    {new Date(e.created_at).toDateString()}
                                  </Font>
                                </View>
                                {/* <Pressable
                                className="flex-row items-center px-2 py-1 rounded bg-app-e3 "
                                style={{
                                  marginVertical: 2,
                                }}
                                onPress={() => onCancelOrder(e.id)}
                              >
                                <Font
                                  className="text-xs text-app-e pr-2"
                                  weight={600}
                                  numberOfLines={1}
                                >
                                  cancel order
                                </Font>
                                <AntDesign
                                  name="delete"
                                  size={15}
                                  color={colors.app.e}
                                />
                              </Pressable> */}
                              </View>
                              {/* <Font
                            className="text-md lg:text-[15px] text-app-d leading-4 lg:leading-6 my-2"
                            weight={600}
                            numberOfLines={2}
                          >
                            #{e.id}
                          </Font> */}
                              {e?.product_orders?.map((e, i) => (
                                <View
                                  className="flex-row w-full border border-gray-200 p-2 mt-2 bg-app-b rounded-lg"
                                  key={`product_orders_${i}`}
                                >
                                  <View className="flex-row flex-wrap w-full">
                                    <View
                                      className="flex-row w-full items-center justify-start"
                                      style={{
                                        marginTop: 2,
                                        marginBottom: 4,
                                      }}
                                    >
                                      <View
                                        className={`flex-row rounded-full px-4 py-1 ${
                                          e.status === "cancelled_by_vendor" ||
                                          e.status === "cancelled_by_customer"
                                            ? "bg-app-e2"
                                            : e.status === "delivered"
                                            ? "bg-app-g2"
                                            : " bg-app-h2"
                                        }`}
                                      >
                                        <Font
                                          className={`capitalize  ${
                                            e.status ===
                                              "cancelled_by_vendor" ||
                                            e.status === "cancelled_by_customer"
                                              ? "text-app-e"
                                              : e.status === "delivered"
                                              ? "text-app-g"
                                              : " text-app-h"
                                          }`}
                                          weight={500}
                                          numberOfLines={1}
                                        >
                                          {e.status === "cancelled_by_vendor"
                                            ? "Cancelled by vendor"
                                            : e.status ===
                                              "cancelled_by_customer"
                                            ? "Cancelled by customer"
                                            : e.status === "out_for_delivery"
                                            ? "Out for delivery"
                                            : e.status === "ready_for_pickup"
                                            ? "Ready for pickup"
                                            : e.status.split("_").join(" ")}
                                        </Font>
                                      </View>
                                    </View>
                                    <View
                                      className="flex-row w-full items-center"
                                      style={{
                                        marginVertical: 2,
                                      }}
                                    >
                                      <RemixIcon
                                        name="group-fill"
                                        size={16}
                                        color={colors.app.f1}
                                        className="mr-1 flex-shrink-0"
                                      ></RemixIcon>
                                      <Font
                                        className=" text-app-d1"
                                        weight={500}
                                        numberOfLines={1}
                                      >
                                        Sold by:{" "}
                                        {`${e?.vendor?.first_name} ${e?.vendor?.last_name}`}
                                      </Font>
                                    </View>
                                    <View
                                      className="flex-row w-full items-center"
                                      style={{
                                        marginVertical: 2,
                                      }}
                                    >
                                      <RemixIcon
                                        name="store-fill"
                                        size={16}
                                        color={colors.app.f1}
                                        className="mr-1 flex-shrink-0"
                                      ></RemixIcon>
                                      <Font
                                        className=" text-app-d1"
                                        weight={500}
                                        numberOfLines={1}
                                      >
                                        Nos.: {e.product_order_items.length}{" "}
                                        {`${
                                          e.product_order_items.length > 1
                                            ? "Items"
                                            : "Item"
                                        }`}
                                      </Font>
                                      <View className="w-4"></View>
                                    </View>
                                  </View>
                                </View>
                              ))}
                            </Pressable>
                          </View>
                        ))}
                        {limitPerPage < pagination.total ? (
                          <>
                            <View className="pt-4 w-full border-b h-0 border-app-c" />
                            <View className="flex-row w-full px-4">
                              {pagination.offset > 0 ? (
                                <Pressable
                                  className="p-2"
                                  onPress={() => {
                                    if (pagination.offset > 0) {
                                      const from =
                                        pagination.offset === 0
                                          ? 0
                                          : pagination.offset - limitPerPage;
                                      const pagObj = {
                                        offset: from,
                                        limit: limitPerPage,
                                        total: pagination.total,
                                      };
                                      getUserOrders({ pagination: pagObj });
                                    }
                                  }}
                                >
                                  <Font className="text-app-e" weight={600}>
                                    &lt; Newer
                                  </Font>
                                </Pressable>
                              ) : (
                                <></>
                              )}
                              <View className="flex-1"></View>
                              {pagination.offset + limitPerPage <
                              pagination.total ? (
                                <Pressable
                                  className="p-2"
                                  onPress={() => {
                                    if (
                                      pagination.offset + limitPerPage <
                                      pagination.total
                                    ) {
                                      const remList =
                                        pagination.total -
                                        (pagination.offset + limitPerPage);
                                      const from =
                                        pagination.offset +
                                        (remList > 0 ? limitPerPage : 0);
                                      const pagObj = {
                                        offset: from,
                                        limit: limitPerPage,
                                        total: pagination.total,
                                      };
                                      getUserOrders({ pagination: pagObj });
                                    }
                                  }}
                                >
                                  <Font className="text-app-e" weight={600}>
                                    Older &gt;
                                  </Font>
                                </Pressable>
                              ) : (
                                <></>
                              )}
                            </View>
                          </>
                        ) : (
                          <></>
                        )}
                      </View>
                    ) : orders.length === 0 && !isOrdersLoading ? (
                      <View className="w-full flex-row h-80 justify-center items-center">
                        <Font className="text-app-d1">No orders found.</Font>
                      </View>
                    ) : (
                      <View className="flex-1 w-full justify-center h-40">
                        <ActivityIndicator
                          size={"small"}
                          color={colors.app.e}
                        ></ActivityIndicator>
                      </View>
                    )}
                  </Card>
                </View>
                <View className="hidden lg:flex lg:items-end w-full lg:w-2/6 p-0 lg:pl-4 pt-5 lg:pt-0">
                  <View className="w-4/5">
                    <Widget></Widget>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <Footer></Footer>
        </ScrollView>
      </View>
    </View>
  ) : (
    <Unautorized />
  );
};
