import React, { useCallback, useContext, useEffect, useState } from "react";
import { ActivityIndicator, Platform, ScrollView, View } from "react-native";
import colors from "../../../colors";
import { Card } from "../../components/card";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import { Button, Input } from "../../components/input";
import { CartItem } from "../../components/shop";
import { AuthContext } from "../../context/authContext";
import { ProductsContext } from "../../context/productsContext";
import { UserContext } from "../../context/userContext";
import {
  axiosPost,
  getProductCartItems,
  getUserBillingAddresses,
  getUserDefaultShippingAddresses,
  getUserShippingAddresses,
  patchUserBillingAddresses,
  postOrder,
} from "../../services/api";
import { OrdersContext } from "../../context/ordersContext";
import { Widget } from "../../components/widget";
import Toast from "react-native-toast-message";
import { ResponseModal } from "../../components/response-modal";
import StripeCheckout from "../../components/stripe-checkout";
import AsyncStorage from "@react-native-async-storage/async-storage";

export const CheckoutSummary = ({ navigation, route }) => {
  const { userToken } = useContext(AuthContext);
  const {
    userAddressList,
    isUserLoading,
    userBillingAddress,
    setUserBillingAddress,
    setIsUserLoading,
    userData,
    setUserWallet,
  } = useContext(UserContext);
  const {
    cart,
    isProductsLoading,
    setIsProductsLoading,
    setCart,
    selectedCartItem,
    setSelectedCartItems,
  } = useContext(ProductsContext);

  const [totalSummary, setTotalSummary] = useState({
    subTotal: 0,
    coupons: { totalAmount: 0, couponDetails: {} },
    rewardDiscount: 0,
    total: 0,
    deductedWalletAmount: 0,
  });

  const getSelectedCartItems = async () => {
    try {
      const res = await getCartItems();
      console.log(res.data.data, "getSelectedCartItems");
      const updatedCartItems = res.data.data;
      const itemsString = await AsyncStorage.getItem("selectedItemStorage");
      const parsedItemString = JSON.parse(itemsString);
      console.log(parsedItemString, "parsedItemString");

      const mergedItems = parsedItemString.map((item) => {
        const updatedItem = updatedCartItems.find(
          (updated) => updated.id === item.id
        );
        return updatedItem ? updatedItem : item;
      });
      setSelectedCartItems(mergedItems);
    } catch (error) {
      console.error("Error retrieving selected items:", error);
      return [];
    }
  };
  const [isLoading, setIsLoading] = useState(false);
  const [isCheckoutLoading, setIsCheckoutLoading] = useState(false);
  const [addressIsDefault, setAddressIsDefault] = useState(false);
  const [tempAddressId, setTempAddressId] = useState();
  const [address, setAddress] = useState(null);
  const [useWallet, setUseWallet] = useState(false);

  const showToast = (type, line1, line2) => {
    Toast.show({
      type: type,
      text1: line1,
      text2: line2,
    });
  };
  const [orderCheckoutState, setOrderCheckoutState] = useState({
    customerId: undefined,
    ephemeralKey: undefined,
    paymentIntentId: undefined,
    clientSecret: undefined,
    orderId: undefined,
    visible: false,
    isReloadedPage: false,
  });
  const [modal, setModal] = useState({
    isVisible: false,
    message: "Booked Successfully",
    showSecondaryButton: true,
    dismiss: true,
  });

  useEffect(
    Platform.OS === "web"
      ? () => {
          const clientSecret = route.params?.payment_intent_client_secret;

          if (!clientSecret) {
            return;
          }
          setOrderCheckoutState((v) => ({
            ...v,
            clientSecret: clientSecret,
            visible: true,
            isReloadedPage: true,
            orderId: route.params?.oid,
            customerId: route.params?.cid,
          }));
        }
      : () => {},
    []
  );

  const dismiss = () => {
    navigation.replace("app");
  };
  const getBillingAddress = async () => {
    try {
      setIsLoading(true);
      const res = await getUserBillingAddresses(userToken);
      if (res.status === 200) {
        setUserBillingAddress(res.data.data);
      } else {
        console.log("checkout, getBillingAddress res error : ", res.data);
      }
    } catch (error) {
      console.log("checkout, getBillingAddress error : ", error);
    } finally {
      setIsLoading(false);
    }
  };
  const getDefaultShippingAddress = async () => {
    try {
      setIsUserLoading(true);
      const res = await getUserDefaultShippingAddresses(userToken);
      if (res.status === 200) {
        console.log("checkout, getShippingAddress res.data : ", res.data);
        setAddress(res.data.data);
      } else {
        console.log("checkout, getShippingAddress res error : ", res.data);
      }
    } catch (error) {
      console.log("checkout, getShippingAddress error : ", error);
    } finally {
      setIsUserLoading(false);
    }
  };
  const getCartItems = async () => {
    try {
      setIsProductsLoading(true);
      const res = await getProductCartItems(userToken);
      if (res.status === 200) {
        // const cartItems = res.data?.data || [];
        // setCart(cartItems);
        // if (selectedCartItem?.length)
        //   setSelectedCartItems(
        //     selectedCartItem.filter((sci) => {
        //       const match = cartItems.find((c) => c.id == sci.id);
        //       return !!match;
        //     })
        //   );
        return res;
      } else {
        console.log("checkout, getCartItems api res error : ", res.data);
      }
    } catch (error) {
      console.log("checkout, getCartItems error : ", error);
    } finally {
      setIsProductsLoading(false);
    }
  };
  console.log(selectedCartItem, "selectedCartItem");

  const validateCoupon = async (index, itemId, code) => {
    let coupon = {
      id: null,
      isValid: false,
      message: null,
      code: null,
      amount: 0,
      isValidating: true,
    };

    const res = await axiosPost({
      Url: "/coupons/check",
      token: userToken,
      data: {
        code: code,
        check_for: "product_order",
        product_id: itemId,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          let couponAmount = 0;
          if (res.data.data.coupon.flat_discount) {
            couponAmount = +(res.data.data.coupon.flat_discount || 0);
          } else if (res.data.data.coupon.discount_percentage_multiplier) {
            couponAmount =
              totalSummary.subTotal *
              +(res.data.data.coupon.discount_percentage_multiplier || 0);
            if (couponAmount > +(res.data.data.coupon.max_discount || 0)) {
              couponAmount = +(res.data.data.coupon.max_discount || 0);
            }
          }
          if (totalSummary.subTotal < couponAmount) {
            couponAmount = totalSummary.subTotal;
          }
          coupon = {
            ...coupon,
            code: code,
            id: res.data.data.coupon.id,
            isValid: true,
            message: "Coupon applied!",
            amount: +couponAmount,
            isValidating: false,
          };
          showToast(
            "success",
            "Coupon Applied !",
            res?.data?.data?.message || "Coupon Applied Successfully"
          );
          setSelectedCartItems((prev) => {
            if (
              +(prev[index]?.item?.sale_price || 0) *
                +(prev[index]?.quantity || 0) <
              +(res?.data?.data?.coupon?.minimum_order_amount_required || 0)
            ) {
              coupon.isValid = false;
              coupon.message =
                "Minimum amount $" +
                +(res?.data?.data?.coupon?.minimum_order_amount_required || 0) /
                  100 +
                " required";
            }
            prev[index].coupon = coupon;

            return [...prev];
          });
          setTotalSummary((e) => {
            const prevCouponAmount = e?.coupons?.couponDetails?.[itemId] || 0;
            return {
              ...e,
              coupons: {
                totalAmount:
                  e?.coupons?.totalAmount + +coupon.amount - prevCouponAmount,
                couponDetails: {
                  ...e?.coupons.couponDetails,
                  [itemId]: coupon.amount,
                },
              },
              total:
                e?.total -
                (e?.coupons?.totalAmount + +coupon.amount - prevCouponAmount),
            };
          });
        } else throw { response: res };
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          console.log(
            "checkout, validateCoupon  error : ",
            error.response.data
          );
          // setCoupon((e) => ({
          //   ...e,
          //   id: null,
          //   isValid: false,
          //   message: error?.response?.data?.message || "Something went wrong!",
          //   amount: 0,
          //   isValidating: false,
          // }));
        } else {
          console.log("checkout, validateCoupon error 2 : ", error.data);
        }
        showToast(
          "error",
          "Apply Coupon Error",
          error?.response?.data?.message ||
            error?.message ||
            "Something went wrong!"
        );
      })
      .finally(() => {
        // setCoupon((e) => ({
        //   ...e,
        //   isValidating: false,
        // }));
      });
  };
  console.log(selectedCartItem, "selectedCartItem");

  const createOrder = async () => {
    setIsCheckoutLoading(true);
    try {
      if (orderCheckoutState.clientSecret) {
        if (Platform.OS === "web" || orderCheckoutState.ephemeralKey) {
          if (orderCheckoutState.visible) {
            setOrderCheckoutState((v) => ({
              ...v,
              visible: false,
            }));
          }
          setTimeout(() => {
            setOrderCheckoutState((v) => ({
              ...v,
              visible: true,
              isReloadedPage: true,
            }));
          }, 2000);
          return;
        }
      }
      let data = {};
      let items = [];

      if ((!address && !addressIsDefault) || !userBillingAddress) {
        return showToast("error", "Error", "Please select an address.");
      }
      [...((selectedCartItem?.length ? selectedCartItem : cart) || [])].forEach(
        (e) => {
          let eachItem = {
            product_id: e.item.product_details.id,
            variant_id: e.item.id,
            quantity: e.quantity,
            apply_coupon:
              e?.coupon?.isValid && e?.coupon?.id
                ? { coupon_id: e?.coupon?.id }
                : undefined,
          };
          items.push(eachItem);
        }
      );
      data.product_order = {};
      data.product_order.items = items;
      data.product_order.billing_address_id = userBillingAddress.id;
      data.product_order.shipping_address_id = addressIsDefault
        ? tempAddressId
        : address.id;
      data.currency = "USD";
      data.total_amount = Number(+(totalSummary.subTotal || 0));
      data.net_order_amount = Number(totalSummary.total || 0);

      if (useWallet) {
        data.use_wallet = useWallet;
      }
      try {
        const res = await postOrder({ token: userToken, data });
        if (res.status === 200) {
          // const initPayment = await initializePaymentSheet({
          //   customer: res.data.data.customer_id,
          //   ephemeralKey: res.data.data.ephemeral_key,
          //   paymentIntent: res.data.data.payment_intent,
          //   orderId: res.data.data.master_order.id,
          // });
          // const res = {"data": {"customer_id": "cus_ORrDOm9gDHdGzG", "ephemeral_key": "ek_test_YWNjdF8xTmEwaDRLUjBieG0yYWlJLGhkZmh5QURFcWxORk90MktJNGZTVG9VeUJXZjN0Ymk_001e7ECYNE", "master_order": {"billing_address_id": 2, "created_at": "2024-02-29T19:01:49.508Z", "currency": "USD", "currency_symbol": "$", "id": 18, "net_master_order_amount": 698, "payment_status": "initiated", "product_orders": [Array], "redeemed_rewards": null, "shipping_address_id": 6, "total_master_order_amount": 698, "updated_at": "2024-02-29T19:01:50.000Z", "user_id": 3}, "payment_intent": "pi_3OpEraKR0bxm2aiI1tEGWU1g_secret_aif4Ve7D7p9XA8BwTlcscRYqP"}};
          if (
            res.data?.data?.master_order?.payment_status === "not_applicable" ||
            +res.data?.data?.master_order?.net_payable === 0
          ) {
            setModal((e) => ({
              isVisible: true,
              message:
                res.data?.message ||
                "Order successfully placed! You can check the status in 'My Orders'.",
              showSecondaryButton: true,
              dismiss: true,
            }));
            return;
          } else {
            const clientSecret =
              res.data?.data?.payment_intent ||
              res.data?.data?.subscription?.latest_invoice?.setup_intent
                ?.client_secret;
            const paymentIntentId =
              res.data?.data?.subscription?.latest_invoice?.payment_intent?.id;
            if (clientSecret) {
              setOrderCheckoutState((v) => ({
                ...v,
                customerId: res.data.data.customer_id,
                ephemeralKey: res.data.data.ephemeral_key,
                paymentIntentId: paymentIntentId,
                clientSecret: clientSecret,
                orderId: res.data.data.master_order.id,
                visible: true,
                isReloadedPage: false,
              }));
              if (Platform.OS === "web") {
                navigation.setParams({
                  payment_intent: paymentIntentId || "",
                  payment_intent_client_secret: clientSecret,
                  redirect_status: "initiated",
                  oid: res.data.data.master_order.id,
                  cid: res.data.data.customer_id,
                });
              }
            }
          }
        } else {
          showToast(
            "error",
            "Error",
            res.data?.message ?? "Failed to create order."
          );
          console.log("checkout, createOrder api res error : ", res.data);
        }
      } catch (error) {
        console.log("checkout, createOrder error : ", error);
      }
    } catch (error) {
      console.log("error:", error);
    } finally {
      setIsCheckoutLoading(false);
    }
  };
  useEffect(() => {
    if (userToken) {
      if (!userBillingAddress) {
        getBillingAddress();
      }
      if (userAddressList.length === 0) {
        getDefaultShippingAddress();
      } else {
        const index = userAddressList.findIndex((e) => e.is_default === true);
        if (index > -1) {
          setAddress(userAddressList[index]);
        }
      }
      getCartItems();
      getSelectedCartItems();
    }
  }, [userToken]);

  useEffect(() => {
    let subTotal = 0;
    [...((selectedCartItem?.length ? selectedCartItem : cart) || [])].forEach(
      (e) => {
        const amount = (e.quantity || 0) * (e.item.sale_price || 0);
        subTotal += amount;
      }
    );

    setTotalSummary((e) => {
      return {
        ...e,
        subTotal: subTotal,
        total:
          subTotal -
          (e?.coupons?.totalAmount || 0) -
          (e?.deductedWalletAmount || 0),
      };
    });
  }, [selectedCartItem, cart]);

  // -------------------------- initialize payment intent ---------------------------

  // const initializePaymentSheet = async ({
  //   paymentIntent,
  //   ephemeralKey,
  //   customer,
  //   orderId,
  // }) => {
  //   const { error } = await initPaymentSheet({
  //     applePay: true,
  //     googlePay: true,
  //     merchantDisplayName: "Smbex",
  //     customerId: customer,
  //     customerEphemeralKeySecret: ephemeralKey,
  //     paymentIntentClientSecret: paymentIntent,
  //     // Set `allowsDelayedPaymentMethods` to true if your business can handle payment
  //     //methods that complete payment after a delay, like SEPA Debit and Sofort.
  //     allowsDelayedPaymentMethods: false,
  //     defaultBillingDetails: {
  //       name: "Jane Doe",
  //     },
  //   });
  //   if (!error) {
  //     await new Promise((resolve) =>
  //       setTimeout(() => {
  //         resolve();
  //       }, 2000)
  //     );
  //     const checkoutError = await onCheckout();
  //     if (!checkoutError) {
  //       try {
  //         setCart([]);
  //         let res = await axiosPost({
  //           Url: `/orders/${orderId}/payment/confirm`,
  //           token: userToken,
  //         });
  //         setModal((e) => ({
  //           isVisible: true,
  //           message: res.data.message,
  //           showSecondaryButton: true,
  //           dismiss: true,
  //         }));
  //       } catch (error) {
  //         showToast("error", "Error", error);
  //         setModal((e) => ({
  //           isVisible: true,
  //           message:
  //             "Order confirmed, payment confirmation failed. Please check my orders.",
  //           showSecondaryButton: true,
  //           dismiss: true,
  //         }));
  //         console.log("orders, onCheckout error: ", error);
  //       }
  //     } else {
  //       setIsUserLoading(false);
  //       console.log("orders, onCheckout elseeeee check-------: ");
  //     }
  //     setLoading(true);
  //   } else {
  //     console.log("orders, initializePaymentSheet error : ", error);
  //   }
  // };

  // -------------------------- present payment sheet ---------------------------
  // const onCheckout = async () => {
  //   const presentSheet = await presentPaymentSheet();
  //   if (presentSheet.error) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  const onPaymentSuccess = useCallback(async (data) => {
    try {
      if (Platform.OS === "web") {
        navigation.setParams({
          payment_intent: undefined,
          payment_intent_client_secret: undefined,
          redirect_status: undefined,
          oid: undefined,
          cid: undefined,
        });
      }
      setOrderCheckoutState((v) => ({
        ...v,
        customerId: undefined,
        ephemeralKey: undefined,
        paymentIntentId: undefined,
        clientSecret: undefined,
        orderId: null,
        visible: false,
        isReloadedPage: false,
      }));
      let res = await axiosPost({
        Url: `/orders/${data.orderId}/payment/confirm`,
        token: userToken,
      });
      if (selectedCartItem?.length) {
        setCart(
          cart.filter((c) => {
            const match = selectedCartItem.find((sci) => c.id == sci.id);
            return !match;
          })
        );
        setSelectedCartItems([]);
      } else setCart([]);
      setUserWallet((prev) => ({
        ...prev,
        amount: prev.amount - totalSummary.deductedWalletAmount,
      }));
      setModal((e) => ({
        isVisible: true,
        message: res.data.message,
        showSecondaryButton: true,
        dismiss: true,
      }));
    } catch (error) {
      showToast("error", "Error", error);
      setModal((e) => ({
        isVisible: true,
        message:
          "Order confirmed, Something went wrong with your payment. You can check the status in 'My Orders'.",
        showSecondaryButton: true,
        dismiss: true,
      }));
      console.log("orders, onCheckout error: ", error);
    }
  }, []);

  const onPaymentError = useCallback((error, paymentIntent) => {
    console.log("onPaymentError:", { ...error }, paymentIntent);
    if (
      (
        error?.payment_intent?.status ||
        paymentIntent?.status ||
        error?.code ||
        ""
      ).toLowerCase() === "canceled"
    ) {
      console.log(
        "cancelled orderCheckoutState: ",
        orderCheckoutState,
        "\ncustom obj: ",
        {
          ...(Platform.OS === "web"
            ? {
                customerId: undefined,
                ephemeralKey: undefined,
                paymentIntentId: undefined,
                clientSecret: undefined,
                orderId: null,
              }
            : {}),
          visible: false,
          isReloadedPage: false,
        }
      );
      setOrderCheckoutState((v) => ({
        ...v,
        ...(Platform.OS === "web"
          ? {
              customerId: undefined,
              ephemeralKey: undefined,
              paymentIntentId: undefined,
              clientSecret: undefined,
              orderId: null,
            }
          : {}),
        visible: false,
        isReloadedPage: false,
      }));
    }
    showToast(
      "error",
      error.code,
      error?.message === error?.localizedMessage
        ? error.localizedMessage
        : `${error.message}\t\n\n${error.localizedMessage || ""}`
    );
  }, []);

  return (
    <StripeCheckout
      onSuccess={onPaymentSuccess}
      onError={onPaymentError}
      checkoutData={orderCheckoutState}
      isReloadedPage={orderCheckoutState.isReloadedPage}
      visible={orderCheckoutState.visible}
      onRequestClose={() =>
        setOrderCheckoutState((v) => ({
          ...v,
          visible: false,
        }))
      }
      onCheckoutLoad={() => setIsCheckoutLoading(false)}
    >
      <View className="flex-1 bg-app-e lg:bg-gray-50">
        <ScrollView
          className="p-4 rounded-t-2xl bg-gray-50"
          keyboardShouldPersistTaps="always"
        >
          <View className="flex-1 w-full justify-start items-center">
            <View className="flex-col w-full lg:w-11/12 lg:px-4">
              <View className="my-5 hidden lg:flex">
                <Font className="text-lg font-semibold text-app-d">
                  Checkout Summary
                </Font>
              </View>
              <View className="w-full flex-col lg:flex-row">
                <View className="flex-1 w-full lg:mr-4">
                  <View className="w-full">
                    <Card title="Order Item">
                      <View className="flex-row w-full">
                        {[
                          ...((selectedCartItem?.length
                            ? selectedCartItem
                            : cart) || []),
                        ]?.length > 0 && !isProductsLoading ? (
                          <View className="flex-1 w-full">
                            <View className="flex-col w-full bg-white rounded-2xl lg:rounded-none pt-0">
                              {/* <View className="pb-4 px-4 border-b border-gray-200 flex-row items-center">
                                <View>
                                  <Font className="text-app-d" weight={600}>
                                    Store name
                                  </Font>
                                  <Font
                                    className="text-app-d1 text-xs -mt-1"
                                    weight={600}
                                  >
                                    Region
                                  </Font>
                                </View>
                                <View className="flex-1"></View>
                              </View> */}
                              {[
                                ...((selectedCartItem?.length
                                  ? selectedCartItem
                                  : cart) || []),
                              ].map((e, i) => (
                                <View
                                  key={`cartitem_${i}`}
                                  className="flex-row w-full"
                                >
                                  {console.log(e, "e?.coupon check")}

                                  <CartItem
                                    setTotalSummary={setTotalSummary}
                                    coupon={e?.coupon}
                                    setCoupon={(v) => {
                                      let valueVariable;
                                      console.log(
                                        e?.coupon,
                                        "e?.coupon?.isValid"
                                      );

                                      if (e?.coupon?.isValid) {
                                        setTotalSummary((prev) => {
                                          console.log(
                                            prev,
                                            "previous log of coupon"
                                          );

                                          let prevCouponValue =
                                            prev?.coupons?.couponDetails?.[
                                              e?.item?.id
                                            ] || 0;
                                          if (prevCouponValue) {
                                            delete prev?.coupons
                                              ?.couponDetails?.[e?.item?.id];
                                          }
                                          console.log(
                                            prevCouponValue,
                                            prev?.total - prevCouponValue,
                                            "prevCouponValue"
                                          );
                                          return {
                                            ...prev,
                                            coupons: {
                                              ...prev.coupons,
                                              totalAmount:
                                                prev?.coupons?.totalAmount -
                                                prevCouponValue,
                                            },
                                            total:
                                              prev?.total - prevCouponValue,
                                          };
                                        });
                                      }
                                      if (typeof v === "function") {
                                        valueVariable = v(
                                          e?.coupon
                                            ? e?.coupon
                                            : {
                                                id: null,
                                                isValid: false,
                                                message: null,
                                                code: null,
                                                amount: 0,
                                                isValidating: false,
                                              }
                                        );
                                        // console.log(valueVariable,"valueVariable");
                                        setSelectedCartItems((prev) => {
                                          prev[i].coupon = valueVariable;

                                          return [...prev];
                                        });
                                      } else {
                                        setSelectedCartItems((prev) => {
                                          if (!prev[i].coupon) {
                                            prev[i].coupon = {
                                              id: null,
                                              isValid: false,
                                              message: null,
                                              code: null,
                                              amount: 0,
                                              isValidating: false,
                                            };
                                          }
                                          prev[i].coupon.code = v;
                                          return [...prev];
                                        });
                                      }
                                    }}
                                    validateCoupon={() =>
                                      validateCoupon(
                                        i,
                                        e?.item?.id,
                                        e?.coupon?.code
                                      )
                                    }
                                    couponCode={true}
                                    data={e}
                                    input={false}
                                    showQty={true}
                                    showTotalPrice={true}
                                    navigation={navigation}
                                  ></CartItem>
                                </View>
                              ))}
                              <View className="flex-row px-4 pt-3 w-full">
                                <Font className="flex-1 text-base" weight={600}>
                                  Total
                                </Font>
                                <View className="flex-1 items-end">
                                  <Font className="text-lg" weight={600}>
                                    $ {(totalSummary.total / 100).toFixed(2)}
                                  </Font>
                                </View>
                              </View>
                            </View>
                          </View>
                        ) : [
                            ...((selectedCartItem?.length
                              ? selectedCartItem
                              : cart) || []),
                          ].length === 0 && !isProductsLoading ? (
                          <View className="flex-1 w-full justify-center items-center">
                            <Font>No items in your cart.</Font>
                          </View>
                        ) : (
                          <View className="flex-1 w-full justify-center h-40">
                            <ActivityIndicator
                              size={"small"}
                              color={colors.app.e}
                            ></ActivityIndicator>
                          </View>
                        )}
                      </View>
                    </Card>
                  </View>
                  <View className="lg:flex-row w-full h-full flex-1 items-start justify-start">
                    <View className="flex-row flex-auto w-full lg:w-1/2 items-start justify-center m-0 lg:mr-4">
                      <Card title="Shipping details">
                        {!addressIsDefault && (isUserLoading || isLoading) ? (
                          <View className="flex-1 w-full justify-center h-40">
                            <ActivityIndicator
                              size={"small"}
                              color={colors.app.e}
                            ></ActivityIndicator>
                          </View>
                        ) : (
                          <>
                            {userBillingAddress?.address ? (
                              <View className="flex-1 w-full h-full px-4 pt-0 pb-3">
                                <Font className="text-app-d pb-1" weight={500}>
                                  Billing address :
                                </Font>
                                {/* <Font className="text-app-d pb-1" weight={500}>
                              <Font className="text-app-d" weight={700}>
                                {userBillingAddress?.address?.recepientName}
                              </Font>{" "}
                              (Home)
                            </Font> */}
                                {userBillingAddress?.address?.phone ? (
                                  <View>
                                    <Font
                                      className="text-app-d pb-1"
                                      weight={600}
                                    >
                                      Phone:{" "}
                                    </Font>
                                    <Font
                                      className="text-app-d pb-1"
                                      weight={500}
                                    >
                                      {userBillingAddress?.address?.phone}
                                    </Font>
                                  </View>
                                ) : null}

                                <View className="flex-row w-full pb-2">
                                  {/* <View className="">
                            <Input
                              type="checkbox"
                              label="a"
                              value={address.id === e.id}
                              setValue={(ee) => {
                                if (ee) {
                                  setAddress(e);
                                }
                              }}
                            ></Input>
                          </View> */}
                                  <View className="flex-1">
                                    <Font
                                      className="text-app-d pb-1"
                                      weight={600}
                                    >
                                      Address:{" "}
                                    </Font>
                                    {!!userBillingAddress?.address?.locality ||
                                    !!userBillingAddress?.address?.city ||
                                    !!userBillingAddress?.address?.state ||
                                    !!userBillingAddress?.address?.country ? (
                                      <Font className="text-app-d" weight={500}>
                                        {userBillingAddress?.address?.locality}
                                        {`, ${userBillingAddress?.address?.city}`}
                                        {`, ${userBillingAddress?.address?.state}`}
                                        {`, ${userBillingAddress?.address?.country}`}
                                      </Font>
                                    ) : (
                                      <Font>No address found.</Font>
                                    )}
                                  </View>
                                </View>
                                {userBillingAddress?.address &&
                                  !address?.address && (
                                    <View className="flex-row justify-between w-full pt-2">
                                      <Font
                                        className="flex-1 text-app-d1"
                                        weight={600}
                                      >
                                        Use billing address for shipping
                                      </Font>
                                      <View className="">
                                        <Input
                                          type="toggle"
                                          value={addressIsDefault}
                                          setValue={async (val) => {
                                            if (val) {
                                              const resForUseDefaultAddress =
                                                await patchUserBillingAddresses(
                                                  {
                                                    token: userToken,
                                                    data: {
                                                      use_for_shipping: true,
                                                    },
                                                    billing_address_id:
                                                      userBillingAddress.id,
                                                  }
                                                );
                                              console.log(
                                                resForUseDefaultAddress,
                                                "resForUseDefaultAddress"
                                              );
                                              if (
                                                resForUseDefaultAddress.status ==
                                                201
                                              ) {
                                                setAddressIsDefault(val);
                                                setTempAddressId(
                                                  resForUseDefaultAddress.data
                                                    .data.shipping.id
                                                );
                                              } else {
                                                showToast(
                                                  "error",
                                                  "Billing Address Error",
                                                  resForUseDefaultAddress?.data
                                                    ?.message
                                                );
                                              }
                                            } else {
                                              setAddressIsDefault(val);
                                            }
                                          }}
                                        ></Input>
                                      </View>
                                    </View>
                                  )}
                              </View>
                            ) : (
                              <></>
                            )}
                            {!address && !userBillingAddress ? (
                              <View className="flex-1 w-full justify-between items-start">
                                {/* <Font className="px-4 ">No address found.</Font> */}
                                <View className="pt-2 pb-0 px-2 w-full flex-row">
                                  <Button
                                    onPress={() =>
                                      navigation.navigate("manage-addresses")
                                    }
                                    label="Add Address"
                                    className="w-full"
                                  ></Button>
                                </View>
                              </View>
                            ) : !addressIsDefault &&
                              address &&
                              !isUserLoading ? (
                              <View className="flex-1 w-full border-t border-gray-200">
                                <View className="px-4 pt-2">
                                  <Font
                                    className="text-app-d pb-1"
                                    weight={500}
                                  >
                                    Shipping address :
                                  </Font>
                                  <Font
                                    className="text-app-d pb-1"
                                    weight={500}
                                  >
                                    {/* <Font className="text-app-d" weight={700}>
                          {address.address.recepientName}
                        </Font>{" "} */}
                                    (Home)
                                  </Font>
                                  <Font
                                    className="text-app-d pb-1"
                                    weight={600}
                                  >
                                    {address?.address.phone}
                                  </Font>
                                  <View className="flex-row w-full pb-2">
                                    {/* <View className="">
                            <Input
                              type="checkbox"
                              label="a"
                              value={address.id === e.id}
                              setValue={(ee) => {
                                if (ee) {
                                  setAddress(e);
                                }
                              }}
                            ></Input>
                          </View> */}
                                    <View className="flex-1">
                                      <Font className="text-app-d" weight={500}>
                                        {address?.address.locality}
                                        {`, ${address?.address.city}`}
                                        {`, ${address?.address.state}`}
                                        {`, ${address?.address.country}`}
                                      </Font>
                                    </View>
                                  </View>
                                </View>
                              </View>
                            ) : !address && !isUserLoading ? (
                              <View className="flex-1 w-full justify-between items-start h-20">
                                <Font className="px-4 ">No address found.</Font>
                                <View className="pt-2 pb-0 px-2 w-full flex-row">
                                  <Button
                                    onPress={() =>
                                      navigation.navigate("manage-addresses")
                                    }
                                    label="Switch Address"
                                    className="w-full"
                                  ></Button>
                                </View>
                              </View>
                            ) : (
                              <></>
                            )}
                            {address && userBillingAddress && (
                              <View className="p-2 w-full flex-row">
                                <Button
                                  onPress={() =>
                                    navigation.navigate("manage-addresses")
                                  }
                                  label="Change Address"
                                  className="w-1/2"
                                ></Button>
                                <View className="w-1/2"></View>
                              </View>
                            )}
                          </>
                        )}
                      </Card>
                    </View>
                    <View className="flex-row flex-auto w-full lg:w-1/2 items-start justify-center m-0 lg:ml-4">
                      <Card title="Payment">
                        <View className="flex-col w-full px-4 pt-0">
                          <View className="flex-row w-full py-1">
                            <Font className="flex-1">Subtotal</Font>
                            <Font>$ {totalSummary.subTotal / 100}</Font>
                          </View>

                          {/* <View className="flex-row justify-between w-full py-1">
                            <Font className="flex-1 leading-4">Shipping</Font>
                            <View className="flex-row">
                              {shippingAmount.isShippingFree && (
                                <Font className="text-app-g pr-3 leading-4">
                                  FREE
                                </Font>
                              )}
                              <Font
                                className={`${
                                  shippingAmount.isShippingFree &&
                                  "line-through"
                                } leading-4`}
                              >
                                $ {shippingAmount.amount / 100}
                              </Font>
                            </View>
                          </View> */}
                          <View className="w-full border-b border-app-d2 py-2" />

                          <>
                            <View className="flex-row w-full py-1">
                              <Font className="flex-1 text-app-d">
                                Available Wallet Balance
                              </Font>
                              <Font>
                                $ {userData?.wallet_balance?.amount / 100}
                              </Font>
                            </View>
                            <View className="w-full flex-row py-1">
                              {useWallet ||
                              (!useWallet && totalSummary.total / 100 >= 20) ? (
                                <Input
                                  type="checkbox"
                                  label="Use wallet balance"
                                  value={useWallet}
                                  setValue={(value) => {
                                    if (!userData?.wallet_balance?.amount) {
                                      showToast(
                                        "error",
                                        "No Balance !",
                                        "There is no balance in the wallet"
                                      );
                                      return;
                                    } else if (
                                      +(userData?.wallet_balance?.amount || 0) <
                                      50
                                    ) {
                                      showToast(
                                        "error",
                                        "Insufficient Amount!",
                                        "Minimum $50 can redeem from the wallet"
                                      );
                                      return;
                                    }
                                    console.log(value, "value check box");

                                    if (value) {
                                      let wallet_balance = +(
                                        userData?.wallet_balance?.amount || 0
                                      );
                                      const total = +totalSummary.total;
                                      let walletAmount =
                                        wallet_balance > total
                                          ? total
                                          : wallet_balance;
                                      setTotalSummary((e) => ({
                                        ...e,
                                        deductedWalletAmount: walletAmount,
                                        total: (e.total || 0) - walletAmount,
                                      }));
                                    } else {
                                      setTotalSummary((e) => ({
                                        ...e,
                                        deductedWalletAmount: 0,
                                        total:
                                          (e.total || 0) +
                                          +(e.deductedWalletAmount || 0),
                                      }));
                                    }
                                    setUseWallet(!!value);
                                    navigation.setParams({
                                      payment_intent: undefined,
                                      payment_intent_client_secret: undefined,
                                      redirect_status: undefined,
                                      oid: undefined,
                                      cid: undefined,
                                    });
                                    setOrderCheckoutState({
                                      customerId: undefined,
                                      orderId: undefined,
                                      ephemeralKey: undefined,
                                      paymentIntentId: undefined,
                                      clientSecret: undefined,
                                      visible: false,
                                      isReloadedPage: false,
                                    });
                                  }}
                                ></Input>
                              ) : (
                                <Font className="flex-1 text-app-e text-xs mb-2">
                                  Total amount need to be $20 or above to use
                                  wallet balance.
                                </Font>
                              )}
                            </View>
                            {useWallet && (
                              <View className="flex-row w-full py-1">
                                <Font className="flex-1 text-app-d">
                                  Pay with wallet balance
                                </Font>

                                <Font className="text-app-g">
                                  — $ {totalSummary.deductedWalletAmount / 100}
                                </Font>
                              </View>
                            )}
                          </>

                          <View className="flex-row w-full py-4">
                            <Font
                              weight={700}
                              className="flex-1 text-base text-app-d font-bold"
                            >
                              Total
                            </Font>
                            <Font
                              weight={600}
                              className="text-base text-app-d font-semibold"
                            >
                              $ {(totalSummary.total / 100).toFixed(2)}
                            </Font>
                          </View>
                          {/* <View className="w-full flex-1 mt-4">
                          <Button
                            label="Select Payment Method"
                            className="w-1/2"
                          ></Button>
                        </View> */}
                          <View className="w-full flex-row py-2">
                            {isCheckoutLoading ? (
                              <View className="flex-1 bg-app-e h-10 rounded-lg justify-center">
                                <ActivityIndicator
                                  size={"small"}
                                  color={colors.app.a}
                                ></ActivityIndicator>
                              </View>
                            ) : (
                              <Button
                                type="primary"
                                label="Pay"
                                onPress={createOrder}
                              ></Button>
                            )}
                          </View>
                        </View>
                      </Card>
                    </View>
                  </View>
                </View>
                <View className="flex lg:items-end w-full lg:w-1/3 p-0 pt-5 lg:pt-0 lg:ml-4">
                  <View className="flex-col w-full">
                    <View className="hidden lg:flex lg:items-end w-full">
                      <Widget></Widget>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <Footer></Footer>
          </View>
        </ScrollView>
        {modal.isVisible && (
          <View className="flex-1">
            <ResponseModal
              modalContent={modal.message}
              modalVisible={modal.isVisible}
              setVisible={(e) => setModal({ ...modal, isVisible: e })}
              primaryButtonLabel="My Orders"
              showSecondaryButton={modal.showSecondaryButton}
              secondaryButtonLabel={"Dismiss"}
              onSecondaryButtonPress={
                modal.dismiss
                  ? () => {
                      setModal({ ...modal, isVisible: false });
                      dismiss();
                    }
                  : () => setModal({ ...modal, isVisible: false })
              }
              onPrimaryButtonPress={() => {
                setModal({ ...modal, isVisible: false });
                navigation.replace("orders");
              }}
            ></ResponseModal>
          </View>
        )}
      </View>
    </StripeCheckout>
  );
};
