import React, { createContext, useContext, useEffect, useState } from "react";

export const ProductsContext = createContext({ reset: () => null });

export const ProductsProvider = ({ children }) => {
  const [isProductsLoading, setIsProductsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState([]);
  const [selectedCartItem, setSelectedCartItems] = useState([]);
  const [productDetails, setProductDetails] = useState([]);
  const [productsCategories, setProductsCategories] = useState([]);
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [productOutOfStock, setProductOutOfStock] = useState(false);
  const [initialStock, setInitialStock] = useState(0);

  const [pagination, setPagination] = useState({
    offset: null,
    total_records: null,
  });

  return (
    <ProductsContext.Provider
      value={{
        isProductsLoading,
        products,
        productsCategories,
        featuredProducts,
        reviews,
        productDetails,
        cart,
        setCart,
        setIsProductsLoading,
        setProducts,
        setProductsCategories,
        setFeaturedProducts,
        setReviews,
        setProductDetails,
        productOutOfStock,
        setProductOutOfStock,
        initialStock,
        setInitialStock,
        selectedCartItem,
        setSelectedCartItems,
        pagination,
        setPagination,
        reset: () => {
          setIsProductsLoading(false);
          setProducts([]);
          setProductsCategories([]);
          setFeaturedProducts([]);
          setReviews([]);
          setProductDetails([]);
          setProductOutOfStock(false);
          setInitialStock(0);
          setCart([]);
          setSelectedCartItems([]);
        },
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};

export const useProductsContext = () => useContext(ProductsContext);
