import React, { useState, useEffect } from "react";
import { Image, Platform, ScrollView, View } from "react-native";
import { Card } from "../../components/card";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import { DEV_API } from "../../services/api";
import { Widget } from "../../components/widget";
import axios from "axios";
import RenderHTML from "../../components/RenderHTML";

export const Post = ({ route }) => {
  const [blogsDetails, setBlogsDetails] = useState();

  useEffect(() => {
    const getBlogsDetails = async () => {
      try {
        const res = await axios.get(
          DEV_API + `/content/blogs/${route.params.slug}`
        );
        setBlogsDetails(res.data.data);
      } catch (error) {
        console.log("new error", error);
      }
    };
    if (route.params.slug) {
      getBlogsDetails();
    }
  }, [route.params.slug]);

  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      <ScrollView
        className="p-4 rounded-t-2xl bg-gray-50"
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View className=" max-w-screen-lg w-full mx-auto">
          <View className="flex-col lg:flex-row max-w-screen-lg w-full">
            <View className=" w-full lg:w-4/6 p-0 lg:pr-4">
              <View className="w-full h-64 lg:h-96 rounded-lg overflow-hidden">
                <Image
                  source={
                    blogsDetails?.cover_image_url
                      ? {
                          uri: `${DEV_API}/media/${blogsDetails?.cover_image_url}`,
                        }
                      : ""
                  }
                  className="w-full lg:w-160 h-full rounded-lg bg-slate-200"
                ></Image>
              </View>
              <View className="my-4">
                <Font className="text-lg font-bold text-app-d leading-6 mb-1">
                  {blogsDetails?.title}
                </Font>
                <Font className="font-medium text-app-sm text-app-d1 my-1">
                  {blogsDetails?.published_on}
                </Font>
              </View>
              <Card>
                <View className="px-4 w-full overflow-hidden pb-4">
                  <RenderHTML htmlContent={blogsDetails?.content} />
                </View>
              </Card>
            </View>
            <View className=" w-full lg:w-2/6 p-0 lg:pl-4 flex-1">
              <Widget></Widget>
            </View>
          </View>
          <Footer></Footer>
        </View>
      </ScrollView>
    </View>
  );
};
