import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React, { useContext, useEffect } from "react";
import { Image, Platform, Pressable, View } from "react-native";
import * as Notifications from "expo-notifications";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { Font } from "../../components/font";
import { Navbar } from "../../components/navbar";
import {
  DEV_API,
  getProductCartItems,
  getUserData,
  isUSerLoggedin,
  validateStripeStatus,
} from "../../services/api";
import { AboutUs } from "./about-us";
import { Blog } from "./blog";
import { Bookings } from "./bookings";
import { ContactUs } from "./contact-us";
import { Faq } from "./faq";
import { Home } from "./home";
import { Search } from "./search";
import { TermsCondition } from "./termscondition";
import { PrivacyPolicy } from "./privacypolicy";
import { Messages } from "./messages";
import { Orders } from "./orders";
import { Post } from "./post";
import { Wishlist } from "./wishlist";
import { SearchModal } from "./search-modal";
import { SafeAreaView } from "react-native-safe-area-context";
import { CustomerNotifications } from "./notification";
import { Cart } from "./cart";
import { Account } from "./account";
import { ListingDetails } from "./listing-details";
import { ProductDetails } from "./product-details";
import { CheckoutSummary } from "./checkout";
import { BookingDetails } from "./booking-details";
import { UpdateProfile } from "./update-profile";
import { ManageAddresses } from "./manage-addresses";
import { UpateAddress } from "./update-address";
import { InviteContact } from "./invite-contact";
import { Contacted } from "./contacted";
import { OrderDetails } from "./order-details";
import { Vendor } from "./vendor";
import { Reviews } from "./review";
import { ProductReviews } from "./product-reviews";
import { AuthContext } from "../../context/authContext";
import { BookingModal } from "./booking-modal";
import { ConfirmBooking } from "./confirm-booking";
import { Booking } from "./booking";
import { UserContext } from "../../context/userContext";
import { Transactions } from "./transactions";
import { Invoice } from "./invoice";
import { Comments } from "./comments";
import { useNotifications } from "../../../utils/useNotifications";
import { useMessagesContext } from "../../context/messagesContext";
import * as Linking from "expo-linking";
import TopupWithdrawal from "./topup-withdrawal";
import Toast from "react-native-toast-message";
import { ResponseModal } from "../../components/response-modal";
import { useStripeContext } from "../../context/stripeContext";
import UserFeed from "./user-feed";
import Groups from "./groups";
import ViewGroup from "./ViewGroup";
import ManageGroup from "./manageGroup";
import GroupJoinRequests from "./group-join-requests";
import AddInviteMember from "./add-member";
import { catchAPIErrorText } from "../../../utils";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { ProductsContext } from "../../context/productsContext";
import { useNotificationContext } from "../../context/notificationContext";
import axios from "axios";
import { SharedContext } from "../../context/sharedContext";
import { osName } from "expo-device";
import Constants from "expo-constants";

const Stack = createNativeStackNavigator();

export const Root = ({ navigation }) => {
  const {
    handleNotificationResponse,
    handleNotification,
    registerForPushNotificationsAsync,
  } = useNotifications();
  const { setRedirectChatUserID } = useMessagesContext();

  const { user, isAuthLoading, setUser, setUserToken, userToken } =
    useContext(AuthContext);
  const {
    userData,
    setUserData,
    getUserLoading,
    setGetUserLoading,
    setUserWallet,
  } = useContext(UserContext);
  const { shared, setShared } = useContext(SharedContext);
  const { stripeStatus, setStripeStatus } = useStripeContext();
  const { setCart, cart } = useContext(ProductsContext);
  const { setNotifications, setUnreadCount } = useNotificationContext();
  const [showResponseModal, setShowResponseModal] = React.useState({
    visible: false,
    onRequestClose: () => null,
    bodyText: "",
  });
  const showToast = (type, line1, line2) => {
    Toast.show({
      type: type,
      text1: line1,
      text2: line2,
    });
  };
  const tabs = [
    {
      title: "Wishlist",
      icon: "bookmark",
      url: "wishlist",
    },
    {
      title: "Bookings",
      icon: "calendar",
      url: "bookings",
    },
    {
      title: "Home",
      icon: "image",
      url: "home",
    },
    {
      title: "Orders",
      icon: "shopping-bag",
      url: "orders",
    },
    {
      title: "Messages",
      icon: "message",
      url: "messages",
    },
  ];
  const tabsGuest = [
    {
      title: "Blog",
      icon: "article",
      url: "blog",
    },
    // {
    //   title: "Terms & Condition",
    //   icon: "file-info",
    //   url: "termscondition",
    // },

    // {
    //   title: "Privacy Policy",
    //   icon: "file-info",
    //   url: "privacy-policy",
    // },
    {
      title: "FAQ",
      icon: "file-info",
      url: "faq",
    },
    {
      title: "Home",
      icon: "image",
      url: "home",
    },
    {
      title: "About Us",
      icon: "profile",
      url: "about-us",
    },
    {
      title: "Contact Us",
      icon: "mail",
      url: "contact-us",
    },
  ];

  React.useLayoutEffect(() => {
    (async () => {
      try {
        if (!userToken) return;
        setGetUserLoading(true);
        const res = await getUserData(userToken);
        if (res?.status) {
          if (res.status === 200) {
            if (!res.data?.data) {
              Toast.show({
                type: "error",
                text1: "Profile Not Found",
                text2: "Profile info not received",
              });
              return;
            }
            setUserData(res.data.data);
            if (!res.data.data?.rewards) {
              Toast.show({
                type: "error",
                text1: "Rewards Error",
                text2: "Rewards amount not found",
              });
            }
            if (!res.data.data?.wallet_balance) {
              Toast.show({
                type: "error",
                text1: "Wallet Balance Error",
                text2: "Wallet Balance not found",
              });
            } else
              setUserWallet({
                amount: res.data.data.wallet_balance?.amount,
                currency: res.data.data.wallet_balance?.currency,
                currency_symbol: res.data.data.wallet_balance?.currency_symbol,
              });
          } else if (res.status !== 200) {
            let bodyText = catchAPIErrorText(
              res.data?.message,
              "Unknown Error while getting user profile"
            );
            setShowResponseModal({
              visible: true,
              onRequestClose: () => {
                setShowResponseModal({
                  visible: false,
                  onRequestClose: () => null,
                  bodyText: "",
                });
                if (res.status === 401) {
                  console.log("navbar, getuser res error 401 clearing token");
                  AsyncStorage.removeItem("user");
                  setUser(null);
                  setUserData(null);
                  setUserToken(null);
                  navigation.replace("signin");
                }
              },
              bodyText: bodyText,
            });
          }
        }
      } catch (error) {
        console.log("get profile error?.code:", error?.code, error);
        if (error?.code) {
          setShowResponseModal({
            visible: true,
            onRequestClose: () => {
              setShowResponseModal({
                visible: false,
                onRequestClose: () => null,
                bodyText: "",
              });
            },
            bodyText: error?.message,
          });
        }
      } finally {
        setGetUserLoading(false);
      }
    })();
  }, [userToken]);

  useEffect(() => {
    if (Platform.OS === "web") {
      try {
        // Deep linking event listener
        const handleUrl = ({ url }) => {
          if (!url) {
            return;
          }
          // Process the incoming deep link URL here
          // console.log("Received deep link 2:", url);
          let urlStub = url.split("//")[1];
          if (urlStub) {
            let urlStubArray = urlStub.split("/");
            if (Platform.OS === "web") {
              urlStubArray.shift();
            }
            // console.log("Received url urlStubArray 2:", urlStubArray);
            let urlHash = urlStubArray[1];
            const hostOS = (osName + "")?.toLocaleLowerCase();

            if (urlStubArray[0] === "set-new-password" && urlHash) {
              navigation.navigate("update-password", { urlHash });
            } else if (urlStubArray[0] === "verify-email" && urlHash) {
              navigation.navigate("confirm-email-verify", { urlHash });
            } else if (urlStubArray[0] === "chat") {
              setRedirectChatUserID({
                uid: parseInt(urlStubArray[1].toString()),
                callback: () => navigation.navigate("messages"),
              });
            } else if (urlStubArray[0] === "booking_details") {
              navigation.navigate("booking-details", {
                booking_id: parseInt(urlStubArray[1].toString()),
              });
            } else if (urlStubArray[0] === "order_details") {
              navigation.navigate("order-details", {
                id: parseInt(urlStubArray[1].toString()),
              });
            } else if (urlStubArray.includes("stripe")) {
              if (urlStubArray.includes("onboard")) {
                if (urlStubArray.includes("return")) {
                  validateStripeStatus()
                    .then((res) => {
                      console.log("res.data:", res.data);
                      if (
                        res.status === 200 &&
                        res.data?.data?.account_reference?.onboarding_status ==
                          "completed"
                      ) {
                        showToast(
                          "success",
                          "Stripe - Add Bank Account",
                          "Your Stripe account has been connected successfully."
                        );
                        return res.data?.data;
                      } else {
                        showToast(
                          "error",
                          "Stripe Onboarding Canceled",
                          res?.data?.message
                            ? res?.data?.message +
                                "\n\nPlease register your Stripe Account to receive the payouts!"
                            : "Please provide your Bank Account details to start accepting payouts from us!"
                        );
                      }
                    })
                    .catch((err) => {
                      setShowResponseModal({
                        visible: true,
                        onRequestClose: () => {
                          setStripeStatus({
                            ...stripeStatus,
                            isUserStripeValidated: true,
                            isConfirmToValidate: true,
                            stripeMessage:
                              "Continue where you left off with your Bank Account Setup?",
                          });
                          setShowResponseModal({
                            visible: false,
                            onRequestClose: () => null,
                            bodyText: "",
                          });
                        },
                        bodyText: `${err?.message || "Error:"}\n\n${err}`,
                      });
                    });
                }
              }
            } else if (
              url.includes("/user-feed/timeline") &&
              Platform.OS === "web" &&
              ["android", "ios"].includes(hostOS)
            ) {
              const linking_url =
                Constants.manifest.scheme + ":/" + urlStubArray.join("/");
              Linking.canOpenURL(linking_url)
                .then(Linking.openURL.bind(this, linking_url))
                .catch(console.log);
            }
          }
        };

        let subscription = Linking.addEventListener("url", handleUrl);
        // Handle initial deep link when the app is opened from a deep link
        Linking.getInitialURL().then((url) => {
          if (url) {
            // Process the initial deep link URL here
            // console.log("Received initial deep link 2:", url);
            handleUrl({ url });
          }
        });
        return () => {
          subscription.remove();
        };
      } catch (err) {
        console.log("error in deep link setup : ", err);
      }
    }
  }, []);

  React.useEffect(() => {
    Notifications.setNotificationHandler({
      handleNotification,
    });
    registerForPushNotificationsAsync().catch((e) => {
      console.log("e:", e);
      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: e?.message || "Unknown Error while setting up notifications!",
        dismissText: "Dismiss",
      });
    });
    const responseListener =
      Notifications.addNotificationResponseReceivedListener(
        handleNotificationResponse
      );
    return () => {
      if (responseListener)
        Notifications.removeNotificationSubscription(responseListener);
    };
  }, []);

  React.useEffect(() => {
    const getCartItems = async () => {
      if (!userToken) return;
      try {
        const res = await getProductCartItems(userToken);
        if (res.status === 200) {
          setCart(res.data?.data || []);
        } else {
          console.log("root getCartItems res error : ", res.data);
        }
      } catch (error) {
        console.log("product-details, getCartItems error1 : ", error);
      }
    };
    getCartItems();
  }, [userToken]);

  React.useEffect(() => {
    const getNotifications = async () => {
      if (!userToken) return;
      axios
        .get(DEV_API + "/notifications", {
          params: {
            limit: 20,
          },
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        })
        .then((response) => {
          const nots = response.data?.["notifications"];
          let unread = 0;
          nots?.forEach((n) => {
            if (!n?.is_read) unread += 1;
          });
          setUnreadCount(unread);
          setNotifications({
            data: nots || [],
          });
        })
        .catch((err) => {
          console.log("notifications error: ", err);
        });
    };
    getNotifications();
  }, [userToken]);

  if (isAuthLoading || (getUserLoading && !userData)) {
    return (
      <View className="flex-1 justify-center items-center">
        <Font>Loading...</Font>
      </View>
    );
  }

  return (
    <SafeAreaView className="w-full h-full bg-red-600 lg:bg-gray-50">
      <Stack.Navigator screenOptions={{ animation: "none" }}>
        <Stack.Group>
          <Stack.Screen
            name="home"
            component={Home}
            options={{
              title: "Home",
              header: (props) => (
                <Navbar {...props} user={user} userData={userData}></Navbar>
              ),
            }}
          />
          <Stack.Screen
            name="wishlist"
            component={Wishlist}
            options={{
              title: "Wishlist",
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="bookings"
            component={Bookings}
            options={{
              title: "Bookings",
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="orders"
            component={Orders}
            options={{
              title: "Orders",
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="messages"
            component={Messages}
            options={{
              title: "Messages",
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="termscondition"
            component={TermsCondition}
            options={{
              title: "Terms And Condition",
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="privacy-policy"
            component={PrivacyPolicy}
            options={{
              title: "Privacy Policy",
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="faq"
            component={Faq}
            options={{
              title: "Frequently Asked Questions",
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="about-us"
            component={AboutUs}
            options={{
              title: "About Us",
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="contact-us"
            component={ContactUs}
            options={{
              title: "Contact Us",
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="blog"
            component={Blog}
            options={{
              title: "Blog",
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="post"
            component={Post}
            options={{
              title: "Post",
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="search"
            component={Search}
            options={{
              title: "Search",
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="listing"
            component={ListingDetails}
            options={{
              title: "Listing",
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="product"
            component={ProductDetails}
            options={{
              title: "Product",
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="checkout"
            component={CheckoutSummary}
            options={{
              title: "Checkout Summary",
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="booking-details"
            component={BookingDetails}
            options={{
              title: "Booking Details",
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="booking"
            component={Booking}
            options={{
              title: "Booking",
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="order-details"
            component={OrderDetails}
            options={{
              title: "Order Details",
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="vendor"
            component={Vendor}
            options={{
              title: "Vendor Details",
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="comments"
            component={Comments}
            options={{
              title: "Comments",
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="review"
            component={Reviews}
            options={{
              title: "Review",
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="user-feed"
            component={UserFeed}
            options={{
              title: "User Feed",
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="product-reviews"
            component={ProductReviews}
            options={{
              title: "Product Review",
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="update-profile"
            component={UpdateProfile}
            options={{
              title: "Update Profile",
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="manage-addresses"
            component={ManageAddresses}
            options={{
              title: "Manage Addresses",
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="update-address"
            component={UpateAddress}
            options={({ route }) => ({
              title: route.params.id ? "Update Address" : "Add address",
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            })}
          />
          <Stack.Screen
            name="invite-contact"
            component={InviteContact}
            options={{
              title: "Invite Contact",
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="groups"
            component={Groups}
            options={{
              title: "Groups",
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="view-groups"
            component={ViewGroup}
            options={{
              title: "View Groups",
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="group-details"
            component={ManageGroup}
            options={{
              title: "Manage Group",
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />

          <Stack.Screen
            name="group-join-requests"
            component={GroupJoinRequests}
            options={{
              title: "Group Join Requests",
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="add-member"
            component={AddInviteMember}
            options={{
              title: "Add Members",
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="contacted"
            component={Contacted}
            options={{
              title: "Invited Contacts",
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="transactions"
            component={Transactions}
            options={{
              title: "Transactions",
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="withdraw"
            component={TopupWithdrawal}
            options={{
              animation: "slide_from_bottom",
              title: "Withdraw",
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="top-up"
            component={TopupWithdrawal}
            options={{
              animation: "slide_from_bottom",
              title: "Top Up",
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="invoice"
            component={Invoice}
            options={{
              title: "Invoice",
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
        </Stack.Group>
        <Stack.Group screenOptions={{ presentation: "transparentModal" }}>
          <Stack.Screen
            name="search-modal"
            component={SearchModal}
            options={{ title: "Search", headerShown: false }}
          />
          <Stack.Screen
            name="booking-modal"
            component={BookingModal}
            options={{ title: "Booking", headerShown: false }}
          />
          <Stack.Screen
            name="confirm-booking"
            component={ConfirmBooking}
            options={{ title: "Booking Successful", headerShown: false }}
          />
          <Stack.Screen
            name="notifications"
            component={CustomerNotifications}
            options={{ title: "Notification", headerShown: false }}
          />
          <Stack.Screen
            name="cart"
            component={Cart}
            options={{ title: "Cart", headerShown: false }}
          />
          <Stack.Screen
            name="account"
            component={Account}
            options={{ title: "Account", headerShown: false }}
          />
        </Stack.Group>
      </Stack.Navigator>
      <View className="bg-gray-50 flex lg:hidden">
        <View className="bg-app-d rounded-t-2xl h-20 w-full py-2">
          <View className="w-full h-full flex-row">
            {(user ? tabs : tabsGuest)?.map((e, i) =>
              e.icon === "image" ? (
                <Pressable
                  className="w-1/5 items-center justify-center active:bg-app-c3 rounded-lg"
                  key={i}
                  onPress={() => navigation.replace(e.url)}
                >
                  <Image
                    source={require("../../../assets/smbex-logoa.png")}
                    className={`w-10 h-10 -mt-1  ${
                      shared?.route === e.url ? "" : "opacity-80"
                    }`}
                  ></Image>
                  <Font
                    className={`font-medium text-xs text-app-a1 mt-0 ${
                      shared?.route === e.url ? "text-app-a" : "text-app-a1"
                    }`}
                  >
                    {e.title}
                  </Font>
                </Pressable>
              ) : (
                <Pressable
                  className="w-1/5 items-center justify-center  active:bg-app-c3 rounded-lg"
                  key={i}
                  onPress={() => navigation.replace(e.url)}
                >
                  <RemixIcon
                    className="self-center"
                    name={`${e.icon}-${
                      shared?.route === e.url ? "fill" : "line"
                    }`}
                    color={
                      shared?.route === e.url ? colors.app.a : colors.app.a1
                    }
                    size="24"
                  ></RemixIcon>
                  <Font
                    className={`font-medium text-xs mt-2 w-max ${
                      shared?.route === e.url ? "text-app-a" : "text-app-a1"
                    }`}
                  >
                    {e.title}
                  </Font>
                </Pressable>
              )
            )}
          </View>
        </View>
        {showResponseModal.visible && (
          <ResponseModal
            onPrimaryButtonPress={showResponseModal.onRequestClose}
            modalContent={showResponseModal.bodyText}
            modalVisible={showResponseModal.visible}
            primaryButtonLabel={showResponseModal.dismissText || "Okay"}
          />
        )}
      </View>
    </SafeAreaView>
  );
};
