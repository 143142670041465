import { Image, View, Pressable, ImageBackground } from "react-native";
import { Font, A } from "../../components/font";
import colors from "../../../colors";
import { ScrollView } from "react-native-gesture-handler";
import { AntDesign } from "@expo/vector-icons";
import { ResponseModal } from "../../components/response-modal";

export const ConfirmEmailSent = ({ navigation }) => {
  return (
    <View className="w-full h-full bg-gray-50 flex-row">
      <View className="h-full w-full lg:w-1/2 items-center justify-center">
        <ScrollView
          className="w-full"
          contentContainerStyle={{
            // flexGrow: 1,
            justifyContent: "flex-start",
            alignItems: "center",
          }}
          keyboardShouldPersistTaps="always"
        >
          <View className="w-80 h-full items-center mt-16">
            <View
            // onPress={() =>
            // navigation.replace("app")}
            >
              <Image
                source={require("../../../assets/smbex-lo.png")}
                className="w-36 h-12 mb-8"
              ></Image>
            </View>
            <View className="flex-col justify-center items-center h-full">
              <View className="flex-col justify-center items-center">
                <View className="rounded-full w-10 h-10 bg-app-g items-center justify-center my-4">
                  <AntDesign
                    name="checkcircle"
                    size={24}
                    color={colors.app.a}
                  />
                </View>
                <Font className="text-lg  text-center">
                  Registration Successful. An email has been sent with a
                  Verification link to verify your email.
                </Font>
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
      <View className="h-full w-1/2 hidden lg:flex bg-app-d">
        <ImageBackground
          className="flex-1"
          source={require("../../../assets/bg.jpg")}
          resizeMode="stretch"
          alt="side-background-home"
        />
      </View>
    </View>
  );
};
