import React, { useContext, useState } from "react";
import { Image, View, Pressable, ActivityIndicator, ImageBackground } from "react-native";
import { Font, A } from "../../components/font";
import { Button, Input, Switcher } from "../../components/input";
import colors from "../../../colors";
import { ScrollView } from "react-native-gesture-handler";
import { postUserSignUp } from "../../services/api";
import { AuthContext } from "../../context/authContext";
import { UserContext } from "../../context/userContext";
import { ResponseModal } from "../../components/response-modal";
import { StyleSheet } from "react-native";
import { TextInput } from "react-native";
import { Platform } from "react-native";
import { GoogleOAuthProvider } from "@react-oauth/google";
import GoogleSigninFunction from "./googleSignin";
import Toast from "react-native-toast-message";
import AsyncStorage from "@react-native-async-storage/async-storage";
import PhoneInput from "react-native-phone-input";

export const Signup = ({ navigation }) => {
  const { isAuthLoading, setIsAuthLoading, setAuthApiError } =
    useContext(AuthContext);
  const [modal, setModal] = useState({
    isVisible: false,
    message: "Test Modal",
  });
  const [signUpData, setSignUpData] = useState({
    isNonProfit: false,
    email: "",
    phoneNumber: "",
    referralCode: "",
    newPassword: "",
    confirmPassword: "",
    // role: "customer",
  });
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [phoneValidationError, setPhoneValidationError] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);
  const [isValid, setIsValid] = useState({
    email: false,
    referralCode: false,
    phoneNumber: false,
    newPassword: false,
    formIsValid: false,
  });
  const setIsInputValid = ({ e, type }) => {
    switch (type) {
      case "email":
        if (isValid.newPassword === true && e === true) {
          setIsValid({ ...isValid, email: e, formIsValid: true });
        } else {
          setIsValid({ ...isValid, email: e, formIsValid: false });
        }
        break;
      case "newPassword":
        if (isValid.email === true && e === true) {
          setIsValid({ ...isValid, newPassword: e, formIsValid: true });
        } else {
          setIsValid({ ...isValid, newPassword: e, formIsValid: false });
        }
        break;

      default:
        break;
    }
  };
  const matchPasswords = ({ password, type }) => {
    switch (type) {
      case "newPassword":
        if (signUpData.confirmPassword !== "") {
          if (password !== signUpData.confirmPassword) {
            setErrorMsg("Passwords doesn't match.");
          } else {
            setErrorMsg(null);
          }
        } else {
          setErrorMsg(null);
        }
        break;
      case "confirmPassword":
        if (signUpData.newPassword !== "") {
          if (password !== signUpData.newPassword) {
            setErrorMsg("Passwords doesn't match.");
          } else {
            setErrorMsg(null);
          }
        } else {
          setErrorMsg(null);
        }
        break;

      default:
        break;
    }
  };

  const validatePhoneNumber = () => {
    const phoneRegex = /^[2-9]\d{9}$/; // Match 10 digits starting from 2 to 9
    const cleanedPhoneNumber = signUpData.phoneNumber.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    console.log(cleanedPhoneNumber, "cleanedPhoneNumber");
    if (cleanedPhoneNumber.length < 9 && !phoneRegex.test(cleanedPhoneNumber)) {
      setPhoneValidationError("Please enter a valid 10-digit phone number");
      setIsValid({ ...isValid, phoneNumber: false, formIsValid: false });
    } else {
      setPhoneValidationError(""); // Clear the error message
      setIsValid({ ...isValid, phoneNumber: true, formIsValid: true });
    }
  };

  const onSignUp = async () => {
    if (signUpData.newPassword === signUpData.confirmPassword) {
      if (signUpData.referralCode != "") {
        try {
          setIsAuthLoading(true);
          if (
            signUpData.newPassword !== "" &&
            signUpData.email !== "" &&
            isValid.formIsValid
          ) {
            const res = await postUserSignUp({
              isNonProfit: signUpData.isNonProfit,
              email: signUpData.email,
              phoneNumber: phoneNumber,
              referralCode: signUpData.referralCode,
              password: signUpData.newPassword,
              role: "customer",
            });
            AsyncStorage.setItem("userNumber", signUpData.phoneNumber);
            console.log("signup res:", res);
            if (res.status === 201) {
              navigation.navigate("confirm-email-sent");
              setModal({
                ...modal,
                isVisible: false,
                navigateTo: "confirm-email-sent",
              });
            } else if (res.status == 400) {
              setAuthApiError(res.data.message);
              setModal({
                ...modal,
                isVisible: true,
                message: res.data.message,
                navigateTo: "signup",
              });
              setIsValid({ ...isValid, referralCode: true });
            } else if (res.status == 409) {
              setAuthApiError(res.data.message);
              setModal({
                ...modal,
                isVisible: true,
                message: res.data.message,
                navigateTo: "signin",
              });
              // navigation.navigate("signin");
            } else {
              setAuthApiError(res.data.message);
              setModal({
                ...modal,
                isVisible: true,
                message: res.data.message,
                navigateTo: "",
              });
            }
          }
        } catch (err) {
          console.log("sign-up, onsignup err : ", err);
        } finally {
          setIsAuthLoading(false);
        }
      } else {
        setIsValid({ ...isValid, referralCode: true });
      }
    }
  };

  const showToast = (type, line1, line2) => {
    Toast.show({
      type: type,
      text1: line1,
      text2: line2,
    });
  };

  return (
    <View className="w-full h-full bg-gray-50 flex-row">
      <View className="h-full w-full lg:w-1/2 items-center justify-center">
        <ScrollView
          className="w-full"
          contentContainerStyle={{
            flexGrow: 1,
            justifyContent: "flex-start",
            alignItems: "center",
          }}
          keyboardShouldPersistTaps="always"
        >
          <View
            className={
              "w-80 items-center py-16 " +
              (Platform.OS === "web"
                ? "py-8 px-10 md:px-0 w-full md:w-80"
                : "basis-0")
            }
          >
            <Pressable
              onPress={() => navigation.replace("app")}
              className="mb-8 items-center"
            >
              <Image
                source={require("../../../assets/smbex-lo.png")}
                className="w-36 h-12 my-2"
              />
              <Font className="text-app-e">Customer</Font>
            </Pressable>
            <View className="w-full my-2">
              <Font className="text-xl text-app-d pb-2" weight={500}>
                Sign up
              </Font>
            
            </View>
            <View className="w-full my-2">
            <Switcher
                value={signUpData.isNonProfit}
                setValue={(e) => {
                  setSignUpData({ ...signUpData, isNonProfit: e });
                }}
                options={[
                  { label: "Regular", value: false },
                  { label: "Non-Profit", value: true },
                ]}
              ></Switcher>
            </View>
            <View className="w-full h-[1px] bg-gray-200 my-2"></View>

          {Platform.OS == "web" ? (
              <GoogleOAuthProvider clientId="1049029176568-8vkue4crnqh3qqontpnqs8jso1majv0u.apps.googleusercontent.com">
                <GoogleSigninFunction navigation={navigation}   isNonProfit={signUpData.isNonProfit} />
              </GoogleOAuthProvider>
            ) : (
              <GoogleSigninFunction
              isNonProfit={signUpData.isNonProfit} 
                showToast={showToast}
                setAuthApiError={setAuthApiError}
                navigation={navigation}
              />
            )}

            <View className="w-full h-4 items-center justify-center">
              <View className="w-full h-[1px] bg-gray-200 my-2"></View>
              <View className="absolute bg-gray-50 py-1 px-2">
                <Font>or</Font>
              </View>
            </View>
            <View className="w-full pb-2 flex-1">
            <Input
                type="email"
                value={signUpData.email}
                setValue={(e) => {
                  setSignUpData({ ...signUpData, email: e });
                }}
                label="Email"
                placeholder="user@example.com"
                validation={true}
                isValid={(e) => setIsInputValid({ e, type: "email" })}
              ></Input>

            </View>
            <View className="w-full pb-2 flex-1 ">
              <View className="w-full h-8 items-center flex-row">
                <Font className="flex-1  text-app-d1" weight={600}>
                  Phone Number
                </Font>
              </View>
              <View className="rounded border border-gray-200 overflow-hidden h-10 bg-app-a px-2">

              <PhoneInput
                className="  flex-row "
                onPressFlag={() => null}
                initialCountry="us"
                initialValue="1"
                value={phoneNumber}
                onChangePhoneNumber={(text) => {
                  setPhoneNumber(text);
                  // Example validation logic
                  // setIsValid(text.length >= 10);
                  setIsValid({ ...isValid, phoneNumber: text.length >= 10 });
                }}
                textStyle={styles.textInput}
                textProps={{
                  placeholder: "Enter a phone number...",
                }}
                style={styles.phoneInput}
              />

              </View>
            </View>
            {phoneValidationError != "" && (
              <View className="w-full flex-row">
                <Font className="text-app-e">{phoneValidationError}</Font>
              </View>
            )}
            {isValid.phoneNumber && (
              <View className="w-full flex-row mb-2">
                <Input
                  type="text"
                  value={signUpData.referralCode}
                  setValue={(e) => {
                    setSignUpData({ ...signUpData, referralCode: e });
                  }}
                  label="Referral Code"
                ></Input>
              </View>
            )}
            {isValid.referralCode == true ? (
              <View className="w-full flex-row">
                <Font className="text-app-e">Invalid Referral Code</Font>
              </View>
            ) : (
              <></>
            )}

            <View className="w-full pb-2 flex-1">
            <Input
                type="password"
                value={signUpData.newPassword}
                setValue={(e) => {
                  setSignUpData({ ...signUpData, newPassword: e });
                  matchPasswords({ password: e, type: "newPassword" });
                }}
                label="New Password"
                placeholder="************"
                validation={true}
                isValid={(e) => setIsInputValid({ e, type: "newPassword" })}
              ></Input>

            </View>
            <View className="w-full pb-2 flex-1">
            <Input
                type="password"
                value={signUpData.confirmPassword}
                setValue={(e) => {
                  setSignUpData({ ...signUpData, confirmPassword: e });
                  matchPasswords({ password: e, type: "confirmPassword" });
                }}
                label="Confirm Password"
                placeholder="************"
              ></Input>

            </View>
            <View className="w-full flex-row">
              <Font className="text-app-e">{errorMsg}</Font>
            </View>

            {isAuthLoading ? (
              <View className="flex-1 w-full items-center justify-center mt-4 h-10 bg-app-e rounded-lg py-2">
                <ActivityIndicator
                  size={"small"}
                  color={colors.app.a}
                ></ActivityIndicator>
              </View>
            ) : (
              <View className="w-full mt-4 mb-2">
                <Button
                  type={`${
                    signUpData.email !== "" &&
                    signUpData.newPassword !== "" &&
                    signUpData.newPassword === signUpData.confirmPassword &&
                    isValid.formIsValid
                      ? "primary"
                      : "disabled"
                  }`}
                  label="Sign up"
                  onPress={onSignUp}
                ></Button>
              </View>
            )}

            <View className="w-full my-2 items-center justify-center flex-row flex-wrap">
              <Font className="text-xs text-app-d1 font-medium">
                Sign up for a SMBEx account means you agree to the
              </Font>
              <A
                href="/text"
                label=" Privacy Policy"
                className="font-medium text-app-e text-sm text-center text-xs "
              />
              <Font className="text-xs text-app-d1 font-medium"> and </Font>
              <A
                href="/text"
                label="Terms of Service"
                className="font-medium text-app-e text-sm text-center text-xs "
              />
            </View>
            <View className="w-full h-[1px] bg-gray-200 my-2"></View>
            <View className="w-full my-2">
              <Font className="text-center mb-2">Already have an account?</Font>
              <A
                href="signin"
                label="Sign in"
                className="font-semibold text-app-e text-sm text-center"
              />
            </View>
          </View>
        </ScrollView>
      </View>
      <View className="h-full w-1/2 hidden lg:flex bg-app-d">
        <ImageBackground
          className="flex-1"
          source={require("../../../assets/bg.jpg")}
          resizeMode="stretch"
          alt="side-background-home"
        />
      </View>
      {modal.isVisible && (
          <View>
            <ResponseModal
              modalContent={modal.message}
              modalVisible={modal.isVisible}
              setVisible={(e) => setModal({ ...modal, isVisible: e })}
              primaryButtonLabel="Dismiss"
              onPrimaryButtonPress={() => {
                setModal({ ...modal, isVisible: false });
                navigation.navigate(modal.navigateTo);
              }}
            ></ResponseModal>
          </View>
        )}

    </View>
  );
};

const styles = StyleSheet.create({
  inputError: {
    borderColor: "red",
    color: "red",
  },
  inputContainer: {
    borderRadius: 5,
    borderWidth: 1,
    height: 40, // Equivalent to "h-10"
    backgroundColor: "#f9f9f9", // bg-app-a
    overflow: "hidden",
    paddingHorizontal: 10, // "px-3"
  },
  borderGray: {
    borderColor: "#e5e7eb", // Equivalent to "border-gray-200"
  },
  borderRed: {
    borderColor: "#f87171", // Equivalent to "border-red-500"
  },
  phoneInput: {
    flex: 1,
    justifyContent: "center",
  },
  textInput: {
    padding: 10,
    flex: 1,
    height: 40, // Align with parent container
    fontSize: 16,
    color: "#000", // Default text color
    ...(Platform.OS === "web" && {
      outlineColor: "transparent",
    }),
  },
});
