import {
  View,
  Text,
  Pressable,
  Image,
  Modal,
  TouchableWithoutFeedback,
  ScrollView,
} from "react-native";
import React from "react";
import moment from "moment";
import { Font } from "../font";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import CreateGroupPostModal from "../../screens/customer/create-post-modal";
import CommentSection from "../commentSection";
import SVGLoader from "../../../utils/SVGLoader";
import horizontalMenu from "../../../assets/horizontal-menu.svg";
import ConfirmModal from "../ConfirmModal";
import { deleteAPost } from "../../services/api";
import * as Linking from "expo-linking";
import OptionsModal from "../OptionsModal";

const GroupPost = ({
  navigation,
  postTitle,
  groupName,
  postIconURL,
  postDescription,
  postTimestamp,
  postCoverImageURL,
  externalLinkUrl,
  postLikes,
  postComments,
  postShares,
  isPostLiked,
  postId,
  groupId,
  attachedFileURL,
  disableGroupPostComment = false,
  disableGroupPostShare = false,
  allowPostEdit = false,
  onSharePost = () => null,
  onCloseEditPostModal = () => null,
  onLikeGroupPost = () => null,
  onEditPost = () => null,
  onPostCommentSuccess = () => null,
  onDeleteCommentSuccess = () => null,
  onDeletePost = () => null,
} = {}) => {
  const [isCommentSectionVisible, setIsCommentSectionVisible] =
    React.useState();
  const [isEditPostModal, setIsEditPostModal] = React.useState();
  const [isMenuVisible, setIsMenuVisible] = React.useState();
  const [isDeleteModal, setIsDeleteModal] = React.useState();
  const [showMoreDesc, setShowMoreDesc] = React.useState(false);

  const openMenu = () => {
    // if (buttonRef.current) {
    //   buttonRef.current.measure((x, y, width, height, pageX, pageY) => {
    //     setButtonPosition({ left: pageX - 100, top: pageY + height - 30 });
    //   });
    // }
    setIsMenuVisible(true);
  };
  const closeMenu = () => {
    setIsMenuVisible(false);
  };
  const closeDeleteModal = () => {
    setIsDeleteModal(false);
  };

  return (
    <View className="p-2 w-full flex justify-center items-center bg-app-a border-y border-t-gray-300">
      <View className="w-full px-4 flex-row justify-between items-center">
        <View className="flex-row justify-start items-center py-1">
          <View className="mr-2">
            <Image
              source={
                postIconURL
                  ? {
                      uri:
                        "https://smbexapi.wisdomtooth.tech/api/v2/media/" +
                        postIconURL,
                    }
                  : require("../../../assets/user.jpg")
              }
              className="w-10 h-10 rounded-full"
            />
          </View>
          <View className="flex justify-start item-center">
            <Font className="text-md text-app-d font-bold">
              {postTitle || groupName}
            </Font>
            <View className="flex-row justify-start items-center">
              {/* <Font className="text-xs text-gray-500">
                              Owner
                            </Font> */}
              {/* <Font className="mr-2 ml-2 flex items-center text-xs font-extrabold self-center">
                              .
                            </Font> */}
              <Font className="text-xs text-gray-500">
                {moment(postTimestamp).fromNow()}
              </Font>
            </View>
          </View>
        </View>
        {allowPostEdit ? (
          <>
            <Pressable
              onPress={() => {
                openMenu();
              }}
              className="mr-2 w-4 h-4 lg:w-auto lg:h-10 self-center"
            >
              <RemixIcon
                name="ri-more-fill"
                className="-mt-1"
                color={colors.app.d}
                size={18}
              />
            </Pressable>
            {isMenuVisible ? (
                <OptionsModal
                onPressForFirstOption={() => {
                  setIsEditPostModal(true);
                  closeMenu();
                }}
                firstOptionSymbol={"ri-pencil-fill"}
                firstOptionLabel={"Edit"}
                secondOptionSymbol={"delete-bin-line"}
                secondOptionLabel={"Remove"}
                onPressForSecondOption={() => {
                  setIsDeleteModal(true);
                  closeMenu();
                }}
                isMenuVisible={isMenuVisible}
                closeMenu={closeMenu}
                // group_role={member.group_role}
                setIsDeleteModal={setIsDeleteModal}
              />
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </View>
      <View className="px-4 w-full">
        {postDescription ? (
          <>
            <View className="py-2 w-full">
              <Font
                className="text-app-d text-sm"
                numberOfLines={showMoreDesc ? 0 : 2}
              >
                {postDescription}
              </Font>
            </View>
            {postDescription?.length > 1350 && (
              <Pressable onPress={() => setShowMoreDesc(!showMoreDesc)}>
                <Font className="text-app-e">
                  {showMoreDesc ? "See less" : "See more"}
                </Font>
              </Pressable>
            )}
          </>
        ) : (
          <></>
        )}
        {postCoverImageURL ? (
          <View className="w-full h-56 py-1">
            <Image
              source={{
                uri: postCoverImageURL.includes("file:/")
                  ? postCoverImageURL
                  : "https://smbexapi.wisdomtooth.tech/api/v2/media/" +
                    postCoverImageURL,
              }}
              className="w-full h-full rounded-lg"
              // style={{ resizeMode: "contain" }}
            />
          </View>
        ) : (
          <></>
        )}
        {externalLinkUrl ? (
          <Pressable
            className="p-2 mb-0 w-full flex-row item-center justify-between"
            onPress={() =>
              externalLinkUrl
                ? Linking.openURL(
                    `${externalLinkUrl}`.replace(/^(?!https?:\/\/)/, "https://")
                  ).catch((e) => console.log("External URL error:", e))
                : null
            }
          >
            <View className="flex justify-center items-center">
              <View className="flex item-center justify-between">
                <Font className="text-lg text-app-d">{externalLinkUrl}</Font>
                <Font className="text-sm text-app-d">
                  {/* Headline copy here */}Click on this link to visit
                </Font>
              </View>
            </View>
            <View className="flex justify-center items-center">
              <View className="p-2 py-1 flex justify-center items-center bg-app-c border-2 border-gray-300 rounded-lg">
                <Font className="text-xs">Learn More</Font>
              </View>
            </View>
          </Pressable>
        ) : (
          <></>
        )}
        {attachedFileURL ? (
          <Pressable
            className="p-2 mb-5 w-full flex-row item-center justify-between"
            onPress={() =>
              attachedFileURL
                ? Linking.openURL(
                    "https://smbexapi.wisdomtooth.tech/api/v2/media/" +
                      attachedFileURL
                  )
                : null
            }
          >
            <View className="flex justify-center items-center">
              <View className="flex item-center justify-between">
                <Font className="text-lg text-app-d">
                  {/* {item?.external_link_url} */}
                  Attached File
                </Font>
                <Font className="text-sm text-app-d">
                  {/* Headline copy here */}Click to Download file
                </Font>
              </View>
            </View>
            <View className="flex justify-center items-center">
              <View className="p-2 py-1 flex justify-center items-center bg-app-c border-2 border-gray-300 rounded-lg">
                <Font className="text-xs">Download</Font>
              </View>
            </View>
          </Pressable>
        ) : (
          <></>
        )}
      </View>
      <View className="px-4 py-2 w-full flex-row justify-between item-center border-b border-t border-y-gray-300 mt-1">
        <View className="flex-row justify-center item-center">
          <View className="z-10 h-8 w-8 bg-indigo-600 border-4 border-gray-300 rounded-full items-center justify-center">
            <RemixIcon name="ri-thumb-up-fill" color={colors.app.a} size="16" />
          </View>
          {/* <View className="-ml-2 h-8 w-8 bg-red-400 border-4 border-gray-300 rounded-full items-center justify-center">
            <RemixIcon name="ri-heart-fill" color={colors.app.a} size="18" />
          </View> */}
          <View className="flex justify-center items-center px-2">
            <Font className="text-base text-gray-500">{postLikes}</Font>
          </View>
        </View>
        <View className="flex-row justify-around item-center">
          <Pressable
            onPress={() => {
              setIsCommentSectionVisible((prev) => !prev);
            }}
            className="mr-3 self-center"
          >
            <Font className="text-base text-gray-500">
              {postComments} Comments
            </Font>
          </Pressable>
          <Font className="text-base text-gray-500 self-center">
            {postShares} Shares
          </Font>
        </View>
      </View>
      <View className="w-full flex-row justify-around item-center py-2">
        <Pressable
          onPress={() =>
            onLikeGroupPost({
              postId: postId,
              isLiked: isPostLiked,
            })
          }
          className="flex-row items-center self-center p-1"
        >
          <RemixIcon
            name="ri-thumb-up-line"
            color={isPostLiked ? colors.app.f : ""}
            size="22"
          />
          <Font className="text-lg px-2 text-gray-600 leading-5">Like</Font>
        </Pressable>
        {disableGroupPostComment ? (
          <></>
        ) : (
          <Pressable
            onPress={() => {
              setIsCommentSectionVisible((prev) => !prev);
            }}
            className={`flex-row items-center self-center p-1 ${
              isCommentSectionVisible
                ? "bg-app-e3 rounded-md border-app-e border"
                : ""
            }`}
          >
            <RemixIcon name="ri-chat-4-line" color={colors.app.f4} size="22" />
            <Font className="text-lg px-2 text-gray-600 leading-5">
              Comment
            </Font>
          </Pressable>
        )}
        {disableGroupPostShare ? (
          <></>
        ) : (
          <Pressable
            onPress={() => onSharePost(postId)}
            className="flex-row items-center self-center p-1"
          >
            <RemixIcon
              name="ri-share-forward-line"
              color={colors.app.f4}
              size="22"
            />
            <Font className="text-lg px-2 text-gray-600 leading-5">Share</Font>
          </Pressable>
        )}
        {isEditPostModal ? (
          <CreateGroupPostModal
            attachedFileURL={attachedFileURL}
            visible={isEditPostModal}
            onRequestClose={() => {
              setIsEditPostModal(false);
              onCloseEditPostModal();
            }}
            postImage={
              postCoverImageURL
                ? "https://smbexapi.wisdomtooth.tech/api/v2/media/" +
                  postCoverImageURL
                : null
            }
            Description={postDescription}
            externalLinkURL={externalLinkUrl}
            id={postId}
            groupId={groupId}
            onEditSuccess={({
              cover_image_url,
              external_link_url,
              description,
              attached_file_url,
            }) =>
              onEditPost({
                id: postId,
                cover_image_url,
                external_link_url,
                description,
                attached_file_url,
              })
            }
            // getGroupPosts={getGroupPosts}
            // selectedImgObj={selectedImageObj}
          />
        ) : (
          <></>
        )}
      </View>
      {isCommentSectionVisible ? (
        <Modal
          visible={isCommentSectionVisible}
          transparent={true}
          onRequestClose={() => setIsCommentSectionVisible((prev) => !prev)}
        >
          <TouchableWithoutFeedback
            onPress={() => setIsCommentSectionVisible((prev) => !prev)}
          >
            <View className="w-full h-full bg-black/20 flex-col justify-center items-center px-4">
              <TouchableWithoutFeedback>
                <View className="w-full lg:max-w-[35%] h-auto shrink-0 basis-auto flex-row bg-transparent max-h-[70%] lg:max-h-[90%] lg:-mb-8">
                  <CommentSection
                    // route={{ params: { slug: id } }}
                    id={postId}
                    groupId={groupId}
                    useId={true}
                    navigation={navigation}
                    onPostCommentSuccess={onPostCommentSuccess}
                    onDeleteCommentSuccess={onDeleteCommentSuccess}
                  />
                </View>
              </TouchableWithoutFeedback>
            </View>
          </TouchableWithoutFeedback>
        </Modal>
      ) : (
        <></>
      )}
      {isDeleteModal && (
        <ConfirmModal
          visible={isDeleteModal}
          onCancel={closeDeleteModal}
          onConfirm={() => {
            closeDeleteModal();
            onDeletePost(postId);
          }}
          confirmText={"Delete"}
          bodyText={"Are you sure you want to delete Post ?"}
          BodyComponent={false}
        ></ConfirmModal>
      )}
    </View>
  );
};

export default GroupPost;
