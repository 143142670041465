import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  ActivityIndicator,
  Image,
  Pressable,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { Card } from "../../components/card";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import { Widget } from "../../components/widget";
import { DEV_API } from "../../services/api";
import axios from "axios";
import { useContext } from "react";
import BlogsContext from "../../context/blogsContext";

export const Blog = ({ navigation, route }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pagination, setPagination] = useState({ offset: 0, total: 0 });
  const [hasMoreData, setHasMoreData] = useState(true);
  const { blogs, setBlogs } = useContext(BlogsContext);

  const limitPerPage = 10;

  const getBlogs = async ({
    push = false,
    pagination: localPagination,
  } = {}) => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        DEV_API +
          `/content/blogs?limit=${limitPerPage}&offset=${
            localPagination?.offset ?? pagination.offset
          }&reader=customer`
      );
      if (res.status === 200) {
        setPagination({
          offset:
            (push
              ? blogs.length
              : localPagination?.offset ?? pagination.offset) +
            res.data["data"].length -
            limitPerPage,
          total: res.data["total_count"],
        });
        if (push) setBlogs([...blogs, ...res.data["data"]]);
        else setBlogs(res.data["data"]);
      }
    } catch (error) {
      console.log("error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getBlogs();
  }, []);

  const goToPost = (slug) => {
    navigation.navigate({ name: "post", params: { slug: slug } });
  };

  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      <View className="flex-1 rounded-t-2xl lg:rounded-none bg-gray-50">
        <ScrollView>
          <View className="max-w-screen-lg w-full p-4 mx-auto">
            <View className="my-5 hidden lg:flex">
              <Font className="text-lg font-semibold text-app-d">Blog</Font>
            </View>
            <View className="flex-col lg:flex-row max-w-screen-lg w-full">
              <View
                className=" w-full lg:w-4/6 p-0 lg:pr-4"
                style={{ height: "auto" }}
              >
                <Card title="Latest posts ">
                  {isLoading ? (
                    <ActivityIndicator
                      animating={true}
                      color={colors.app.e}
                      size={"small"}
                      className="my-8 mx-4"
                    />
                  ) : blogs?.length ? (
                    blogs.map((content, i) => (
                      <Pressable
                        className="w-full px-4 py-2 border-b border-gray-200 flex-col lg:flex-row"
                        key={content.id + i}
                        onPress={(e) => goToPost(content.id)}
                      >
                        <Image
                          source={
                            content?.cover_image_url
                              ? {
                                  uri: `${DEV_API}/media/${content.cover_image_url}`,
                                }
                              : ""
                          }
                          className="w-full lg:w-60 h-32 rounded-lg bg-slate-200"
                        ></Image>
                        <View className="flex-1 mt-4 lg:ml-4">
                          <Font
                            className="font-semibold text-[15px] text-app-d"
                            numberOfLines={3}
                          >
                            {content.title}
                          </Font>
                          <Font className="text-app-d1 my-2" weight={500}>
                            {content.published_on}
                          </Font>
                          <View>
                            <Font
                              className="text-app-d"
                              weight={500}
                              numberOfLines={3}
                            >
                              {content.content}
                            </Font>
                          </View>
                        </View>
                      </Pressable>
                    ))
                  ) : (
                    <View className="w-full flex flex-row justify-center items-center py-8 px-4">
                      <Font className="text-app-d1">No Blogs here</Font>
                    </View>
                  )}
                  {limitPerPage < pagination.total ? (
                    <>
                      <View className="pt-4 w-full border-b h-0 border-app-c" />
                      <View className="flex-row w-full px-4">
                        {pagination.offset > 0 ? (
                          <Pressable
                            className="p-2"
                            onPress={() => {
                              if (pagination.offset > 0) {
                                const from =
                                  pagination.offset === 0
                                    ? 0
                                    : pagination.offset - limitPerPage;
                                const pagObj = {
                                  offset: from,
                                  limit: limitPerPage,
                                  total: pagination.total,
                                };
                                getBlogs({ pagination: pagObj });
                              }
                            }}
                          >
                            <Font className="text-app-e" weight={600}>
                              &lt; Newer
                            </Font>
                          </Pressable>
                        ) : (
                          <></>
                        )}
                        <View className="flex-1"></View>
                        {pagination.offset + limitPerPage < pagination.total ? (
                          <Pressable
                            className="p-2"
                            onPress={() => {
                              if (
                                pagination.offset + limitPerPage <
                                pagination.total
                              ) {
                                const remList =
                                  pagination.total -
                                  (pagination.offset + limitPerPage);
                                const from =
                                  pagination.offset +
                                  (remList > 0 ? limitPerPage : 0);
                                const pagObj = {
                                  offset: from,
                                  limit: limitPerPage,
                                  total: pagination.total,
                                };
                                getBlogs({ pagination: pagObj });
                              }
                            }}
                          >
                            <Font className="text-app-e" weight={600}>
                              Older &gt;
                            </Font>
                          </Pressable>
                        ) : (
                          <></>
                        )}
                      </View>
                    </>
                  ) : (
                    <></>
                  )}
                </Card>
              </View>
              <View className=" w-full lg:w-2/6 p-0 lg:pl-4 flex-1">
                <Widget></Widget>
              </View>
            </View>
            <Footer></Footer>
          </View>
        </ScrollView>
      </View>
    </View>
  );
};
