import React, { useContext } from "react";
import { Image, View, Pressable, ImageBackground } from "react-native";
import { Font, A } from "../../components/font";
import colors from "../../../colors";
import { ScrollView } from "react-native-gesture-handler";
import { AntDesign } from "@expo/vector-icons";

export const EmailSentSuccessfullyPage = ({ navigation }) => {
  return (
    <View className="w-full h-full bg-gray-50 flex-row">
      <View className="h-full w-full lg:w-1/2 items-center justify-center">
        <ScrollView
          className="w-full"
          contentContainerStyle={{
            // flexGrow: 1,
            justifyContent: "flex-start",
            alignItems: "center",
          }}
          keyboardShouldPersistTaps="always"
        >
          <View className="w-80 items-center mt-16">
            <Pressable onPress={() => navigation.replace("app")}>
              <Image
                source={require("../../../assets/smbex-lo.png")}
                className="w-36 h-12 mb-8"
              ></Image>
            </Pressable>
            <View className="h-[500px] justify-center items-center">
              <View className="rounded-full w-10 h-10 bg-app-g items-center justify-center">
                <AntDesign name="checkcircle" size={24} color={colors.app.a} />
              </View>
              <Font className="text-lg my-4 text-center">
                Rest Password Link Sent Successfully to Your Email Address!
              </Font>
              {/* <View className="w-full my-2">
                <A
                  href="signin"
                  label="Sign in"
                  className="font-semibold text-app-e text-sm text-center"
                />
              </View> */}
            </View>
          </View>
        </ScrollView>
      </View>
          <View className="h-full w-1/2 hidden lg:flex bg-app-d">
            <ImageBackground
              className="flex-1"
              source={require("../../../assets/bg.jpg")}
              resizeMode="stretch"
              alt="side-background-home"
            />
          </View>
    </View>
  );
};
