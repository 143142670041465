import React, { useEffect, useState } from "react";
import {
  ActivityIndicator,
  Pressable,
  ScrollView,
  Text,
  View,
} from "react-native";
import colors from "../../../colors";
import { Card } from "../../components/card";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import { Widget } from "../../components/widget";
import { DEV_API } from "../../services/api";
import axios from "axios";

export const PrivacyPolicy = ({ navigation, route }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [privacyPolicyData, setPrivacyPolicyData] = useState("");

  useEffect(() => {
    getPrivacyPolicyData();
  }, []);

  const getPrivacyPolicyData = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(DEV_API + `/content/customer/privacy-policy`);
      setPrivacyPolicyData(res.data?.data || "");
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      <ScrollView
        className="p-4 rounded-t-2xl bg-gray-50"
        contentContainerStyle={{
          // flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View className=" max-w-screen-lg w-full mx-auto">
          <View className="my-5 hidden lg:flex">
            <Font className="text-lg font-semibold text-app-d">
              Privacy Policy
            </Font>
          </View>
          <View className="flex-col lg:flex-row max-w-screen-lg w-full">
            <View className=" w-full lg:w-4/6 p-0 lg:pr-4">
              <Card
              // title="What does this policy cover"
              >
                <View className="w-full p-4">
                  {/* <Font className="text-app-d1 my-2" weight={500}>
                    Effective Date : {getFullDateWithWeekday(Date.now())}
                  </Font> */}
                  {isLoading ? (
                    <ActivityIndicator
                      animating={true}
                      color={colors.app.e}
                      size={"small"}
                      className="my-8 mx-4"
                    />
                  ) : privacyPolicyData ? (
                    <Font className="text-app-d">{privacyPolicyData}</Font>
                  ) : (
                    <View className="w-full flex flex-row justify-center items-center bg-app-a rounded-lg py-8 px-4 border border-gray-200 mb-4">
                      <Font className="text-app-d1">No Content Found</Font>
                    </View>
                  )}
                </View>
              </Card>
            </View>

            <View className=" w-full lg:w-2/6 p-0 lg:pl-4 flex-1">
              <Widget></Widget>
            </View>
          </View>
          <Footer></Footer>
        </View>
      </ScrollView>
    </View>
  );
};
